<template>
  <div>
    <InformationToast
      :show="informationMessage.show"
      :message="informationMessage.content"
      :fullscreen="true"
      :error="informationMessage.error"
      :reload="informationMessage.reload"
      :critical="informationMessage.critical"
    />

    <v-card>
      <v-card-title class="headline grey lighten-2">
        Privacy Policy
      </v-card-title>

      <v-card-text>
        <v-stepper class="elevation-0" flat v-model="e1">
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="e1 > 1" step="1">
              Projektdaten
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Kameras
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">Nutzer </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              step="1"
              style="
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              "
            >
              <ProjectInformation
                v-on:create="createProjectEvent"
                v-on:close="$emit('close', true)"
                v-on:error="errorEvent"
              />
            </v-stepper-content>

            <v-stepper-content
              step="2"
              style="
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              "
            >
              <CameraInformation
                :projectIdentifier="projectIdentifier"
                v-on:create="e1 = 3"
                v-on:close="$emit('close', true)"
                v-on:error="errorEvent"
              />
            </v-stepper-content>

            <v-stepper-content step="3" 
              style="
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              ">
              
			  <InviteProjectUser 
			  	:projectIdentifier="projectIdentifier"
                v-on:close="$emit('close', true)"
                v-on:error="errorEvent"
			/>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import InformationToast from "../InformationToast.vue";
import ProjectInformation from "./creation/ProjectInformation";
import CameraInformation from "./creation/CameraInformation";
import InviteProjectUser from "./creation/InviteProjectUser";

export default {
  components: {
    InformationToast,
    ProjectInformation,
    CameraInformation,
	InviteProjectUser
  },

  data() {
    return {
      projectIdentifier: false,
      informationMessage: {
        content: "",
        show: false,
        error: false,
        reload: false,
        critical: false,
      },
      e1: 1,
    };
  },

  methods: {
    errorEvent(errorMessage) {
      this.showToast(errorMessage, true);
    },

    createProjectEvent(projectIdentifier) {
      if (!projectIdentifier) {
        this.showToast(
          "Ein unerwarteter Fehler ist beim Verarbeiten des neuen Projektes aufgetreten.",
          true
        );
        return;
      }

      this.projectIdentifier = projectIdentifier;
      this.e1 = 2;
    },

    /**
     * With this function an information or error toast can be displayed
     * @param message Toast content message
     * @param error (optional, default: false) Boolean whether it is an error toast
     * @param reloadSuggestion (optional, default: false) Boolean whether a reload suggestion shall be made
     * @param critical (optional, default: false) Boolean whether error is critical (disable menu)
     */
    showToast(
      message,
      error = false,
      reloadSuggestion = false,
      critical = false
    ) {
      this.informationMessage.show = !this.informationMessage.show;
      this.informationMessage.content = message;
      this.informationMessage.error = error;
      this.informationMessage.reload = reloadSuggestion;
      this.informationMessage.critical = critical;
    },
  },
};
</script>

