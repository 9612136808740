<template>
  <div class="ma-4">
	<InformationToast
      :show="informationMessage.show"
      :message="informationMessage.content"
      :fullscreen="false"
      :error="informationMessage.error"
      :reload="informationMessage.reload"
      :critical="informationMessage.critical"
    />
	
		  
    <PageHeading
      title="Sicherung"
      subtitle="Übersicht über den Alarmzeiten. Damit diese Zeiten aktiv werden, müssen Sie diese aktiv speichern."
    >
      <template v-slot:action>
        <!-- <CreateTimelapse v-on:created="createdNewTimelapse" v-on:errorloading="creationCriticalError"
	  	v-on:errorEvent="creationEventError"
	  /> -->
        <v-btn
          v-show="isAdmin && hasCameras && armingTimes != undefined"
          :loading="saving"
          v-on:click="saveArmingTimes"
          rounded
          color="primary"
          depressed
          :ripple="false"
          ><v-icon class="mr-1">mdi-content-save</v-icon>Speichern</v-btn
        >
      </template>
    </PageHeading>

  
    <div
	v-if="hasCameras && armingTimes != undefined"
      class="pt-2 ma-5"
      style="border-radius: 10px; max-width: calc(100vw - 256px)"
    >
      <v-row class="ma-0 mt-5" style="border-radius: 10px !important">
        <div style="width: 50px; margin-top: 57px">
          <div v-for="i in 24" :key="i" style="height: 25px">
            <v-row class="ma-0 mr-1" justify="end">
              <span style="font-size: 12px" class="grey--text"
                >{{ `0${i}`.slice(-2) }}:00</span
              >
            </v-row>
          </div>
        </div>
        <CalendarElement
          v-for="(dayTime, i) in Object.keys(armingTimes)"
          :key="i"
          v-on:change="timeChangeEvent"
          :admin="isAdmin"
          :dayIdentifier="dayTime"
          :time="armingTimes[dayTime]"
          v-on:all="setAllDaysEvent"
          v-on:weekday="setWeekdaysEvent"
          v-on:weekend="setWeekendEvent"
          :isWeekday="i < 5"
          :first="i == 0"
          :last="i == Object.keys(armingTimes).length - 1"
          :weekday="weekdays[i]"
        />
      </v-row>

      <v-row align="center" class="ma-0 ml-12">
        <div
          style="
            background-color: var(--v-primary-base);
            height: 10px;
            width: 35px;
            opacity: 0.9;
            border-radius: 5px;
          "
          class="mr-1"
        />
        Alarmzeiten
      </v-row>

      <v-divider class="mt-10 mb-10" />

      <v-row class="ma-0 ml-5 mr-5">
        <div style="width: calc(100% - 400px)" class="pr-3">
          Unsere Notruf- und Serviceleitstelle steht Ihnen rund um die Uhr, 365
          Tage im Jahr zur Verfügung. Telefon Leitstelle: <b>06152 937 5859</b>.
          <br />
          <br />
          Bei technischen Fragen steht Ihnen Außerdem unsere Service-Nummer zur
          Verfügung. Technischer Service: <b>06152 8079 8182</b>
        </div>
        <div style="width: 400px">
          <img
            style="border-radius: 10px; max-width: 100%"
            src="/leitstelle.jpg"
          />
        </div>
      </v-row>
    </div>

    <CameraNotSetup v-else/>
	
  </div>
</template>
<script>
import CalendarElement from "@/components/Calendar/CalendarElement.vue";
import PageHeading from "../../../../packages/frontend/components/TextStyles/PageHeading.vue";
import InformationToast from "@/components/SetupSidebar/InformationToast.vue";
import CameraNotSetup from '../../../../packages/frontend/components/helper/CameraNotSetup'
export default {
  components: {
    CalendarElement,
    PageHeading,
    InformationToast,
	CameraNotSetup
  },
  data() {
    return {
      hasCameras: false,
      isAdmin: false,
      informationMessage: {
        content: "",
        show: false,
        error: false,
        reload: false,
        critical: false,
      },
      saving: false,
      weekdays: [
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
        "Sonntag",
      ],

      armingTimes: undefined,
    };
  },

  async created() {
    var user = await this.$firebase.user();
    if (!user || !user.claims) return this.$router.push("/login");
    this.isAdmin = user.claims.admin;

    //this.$api.create(this.$route.params.id);
    var res = await this.$api.request().getProjectCameras();

    if (res.error && String(res.message).includes("404")) {
      this.hasCameras = false;
    } else {
      this.hasCameras = true;
      await this.getArmingSchedule();
    }

    // TODO: Sschöner machen im core mit status code!!
  },

  methods: {
    async getArmingSchedule() {
      var testSchedule = await this.$api.request().getProjectSchedule();
      console.log("TEST", testSchedule);

      if (testSchedule.error) {
        //this.showToast('Beim Laden der Alarmzeiten ist ein Fehler aufgetreten.', true, true, true)
        var sched = {
          arming: [],
          timelapse: [],
        };
      } else {
        var sched = {
          arming: testSchedule.__timeblocks__.filter((i) => i.type == "arming"),
          timelapse: testSchedule.__timeblocks__.filter(
            (i) => i.type == "timelapse"
          ),
        };
      }

      var armingTimes = {};

      try {
        armingTimes.mon = this.parseArmingTime(0, sched.arming);
        armingTimes.tue = this.parseArmingTime(1, sched.arming);
        armingTimes.wed = this.parseArmingTime(2, sched.arming);
        armingTimes.thu = this.parseArmingTime(3, sched.arming);
        armingTimes.fri = this.parseArmingTime(4, sched.arming);
        armingTimes.sat = this.parseArmingTime(5, sched.arming);
        armingTimes.sun = this.parseArmingTime(6, sched.arming);
        console.log(armingTimes.mon);
      } catch (error) {
        this.showToast(
          "Beim Laden der Alarmzeiten ist ein Fehler aufgetreten.",
          true,
          true,
          true
        );
        console.log("ERROR", error);
      }

      setTimeout(() => {
        this.armingTimes = armingTimes;
      }, 10);
    },

    formatArmingTimes(times) {
      var timeArr = [];
      timeArr = this.formatDayTime(times.mon, 0, timeArr);
      timeArr = this.formatDayTime(times.tue, 1, timeArr);
      timeArr = this.formatDayTime(times.wed, 2, timeArr);
      timeArr = this.formatDayTime(times.thu, 3, timeArr);
      timeArr = this.formatDayTime(times.fri, 4, timeArr);
      timeArr = this.formatDayTime(times.sat, 5, timeArr);
      timeArr = this.formatDayTime(times.sun, 6, timeArr);
      return timeArr;
    },

    formatDayTime(time, dayIdentifier, timeArr) {
      if (time.disable) return timeArr;

      if (time.start == time.end) {
        timeArr.push({ from: 0, to: 1440, dayOfWeek: dayIdentifier });
      } else {
        timeArr.push({ from: 0, to: time.end * 60, dayOfWeek: dayIdentifier });
        timeArr.push({
          from: time.start * 60,
          to: 1440,
          dayOfWeek: dayIdentifier,
        });
      }

      return timeArr;
    },

    parseArmingTime(dayWeek, armingTimes) {
      var parsedData = armingTimes.filter((i) => i.dayOfWeek == dayWeek);
      console.log(parsedData);
      if (parsedData.length == 0) {
        return { disable: true, start: 18, end: 6 };
      }

      if (parsedData.length > 2) {
        throw Error("Falsches Format");
      } else {
        if (parsedData.length == 1) {
          return { disable: false, start: 0, end: 0 };
        } else {
          console.log("HERERE", parsedData[0].startTime);
          if (parsedData[0].endTime < parsedData[1].startTime) {
            var start = parsedData[0].endTime / 60;
            var end = parsedData[1].startTime / 60;
          } else {
            var start = parsedData[1].endTime / 60;
            var end = parsedData[0].startTime / 60;
          }
        }
        if (end == 24) {
          end = 0;
        }

        if (start > end) {
          throw Error("Daten sind fehlerhaft formatiert");
        } else {
          return { disable: false, start: end, end: start };
        }
      }
    },

    /**
     * With this function an information or error toast can be displayed
     * @param message Toast content message
     * @param error (optional, default: false) Boolean whether it is an error toast
     * @param reloadSuggestion (optional, default: false) Boolean whether a reload suggestion shall be made
     * @param critical (optional, default: false) Boolean whether error is critical (disable menu)
     */
    showToast(
      message,
      error = false,
      reloadSuggestion = false,
      critical = false
    ) {
      console.log("SHOW TOAST!");
      this.informationMessage.show = !this.informationMessage.show;
      this.informationMessage.content = message;
      this.informationMessage.error = error;
      this.informationMessage.reload = reloadSuggestion;
      this.informationMessage.critical = critical;
      console.log("SET ARMING TIMES!");
    },
    async saveArmingTimes() {
      this.saving = true;
      var scheduleTimes = this.formatArmingTimes(this.armingTimes);
      console.log(scheduleTimes);
      var resp = await this.$api
        .request()
        .postProjectSchedule({ arming: scheduleTimes, timelapse: [] });
      if (resp && !resp.error) {
        this.showToast(
          "Die Alarmzeiten werden nun auf die Kamera geschrieben."
        );
      } else {
        this.showToast(
          "Beim Schreiben der Alarmzeiten ist ein Fehler aufgetreten",
          true
        );
      }
      setTimeout(() => {
        this.saving = false;
      }, 2000);
    },

    setAllDaysEvent(eventData) {
      this.armingTimes.mon = eventData;
      this.armingTimes.tue = eventData;
      this.armingTimes.wed = eventData;
      this.armingTimes.thu = eventData;
      this.armingTimes.fri = eventData;
      this.armingTimes.sat = eventData;
      this.armingTimes.sun = eventData;
    },
    setWeekdaysEvent(eventData) {
      this.armingTimes.mon = eventData;
      this.armingTimes.tue = eventData;
      this.armingTimes.wed = eventData;
      this.armingTimes.thu = eventData;
      this.armingTimes.fri = eventData;
    },

    setWeekendEvent(eventData) {
      this.armingTimes.sat = eventData;
      this.armingTimes.sun = eventData;
    },

    timeChangeEvent(eventData) {
      this.armingTimes[eventData.day] = eventData.data;
    },
  },
};
</script>