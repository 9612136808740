<template>
  <div style="height: 250px">
    <CardTitle
      title="Anmelden"
      subtitle="Bitte geben Sie Ihre Mail-Adresse & Passwort an."
    />

    <div>
      <!-- <div class="ml-4 mr-4 pb-2">Bitte melden Sie sich an.</div> -->
      <v-text-field
        v-model="userCredentials.mail"
        placeholder="Mail-Adresse"
        filled
        rounded
        dense
        hide-details
      ></v-text-field>
      <v-text-field
        v-model="userCredentials.password"
        class="mt-4"
        placeholder="Passwort"
        filled
        rounded
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        name="input-10-1"
        @click:append="showPassword = !showPassword"
        dense
        hide-details
      ></v-text-field>
      <!-- <v-row class="ma-0">
        <v-spacer /><u
          v-on:click="showForgetInput = true"
          class="mr-4"
          style="cursor: pointer"
          >Passwort vergessen?</u
        ></v-row
      > -->
    </div>
  </div>
</template>

<script>
import CardTitle from "./CardTitle";
export default {
  props: ["forgot"],
  components: {
    CardTitle,
  },
  data() {
    return {
      userCredentials: {
        mail: "",
        password: "",
      },
      showPassword: false,
      showForgetInput: false,
    };
  },

  watch: {
    forgot() {
      this.showForgetInput = this.forgot;
    },

    showForgetInput() {
      this.$emit("forgot", this.showForgetInput);
    },
    userCredentials: {
      handler() {
        this.$emit("input", this.userCredentials);
      },
      deep: true,
    },
  },
};
</script>