<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row class="ma-0">
          <span v-show="items.length > 0" class="black--text">{{ title }}</span>

          <v-spacer />
          <slot name="add-action" />
        </v-row>

        <div
          style="
            position: absolute;
            top: 50px;
            right: 3px;
            width: calc(100% - 6px);
            left: 3px;
            z-index: 250;
          "
        >
          <slot name="expand-area" />
        </div>

        <v-divider class="mt-3" v-show="items && items.length > 0" />
        <div v-show="!items || items.length == 0" style="height: 290px">
          <v-col align="center" style="position: absolute; top: 125px">
            <slot name="alternative" />
          </v-col>
        </div>
        <v-virtual-scroll
          v-show="items && items.length > 0"
          :bench="5"
          :items="items"
          height="250"
          item-height="64"
        >
          <template v-slot:default="{ item }">
            <slot :item="item"></slot>
          </template>
        </v-virtual-scroll>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["items", "title"],
};
</script>
