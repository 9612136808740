import Vue from 'vue'
import App from './App.vue'
import router from './router'

// import FirebasePlugin from '../../packages/plugins/firebase';
import FirebasePlugin from '../../packages/frontend/plugins/firebase';
import ApiPlugin from '../../packages/frontend/plugins/api'


import vuetify from './plugins/vuetify';
import storage from './plugins/storage';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import VueZoomer from 'vue-zoomer'
import VueVideoPlayer from 'vue-video-player'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyCD22BsdEw3MQSjR2UBpipW4Kb7B7sfrcQ',
		libraries: 'places', // This is required if you use the Autocomplete plugin
		// OR: libraries: 'places,drawing'
		// OR: libraries: 'places,drawing,visualization'
		// (as you require)

		//// If you want to set the version, you can do so:
		// v: '3.26',
	},

	//// If you intend to programmatically custom event listener code
	//// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
	//// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
	//// you might need to turn this on.
	// autobindAllEvents: false,

	//// If you want to manually install components, e.g.
	//// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
	//// Vue.component('GmapMarker', GmapMarker)
	//// then disable the following:
	// installComponents: true,
})
// require videojs style
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'

Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)

Vue.use(VueZoomer)
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.component('vue-draggable-resizable', VueDraggableResizable)
import fullscreen from 'vue-fullscreen'
Vue.use(fullscreen)
Vue.use(storage)

Vue.prototype.$project = {
	dev: true,
	server: {
		project: 'https://frontend-proxy-oisyqrn6aq-ew.a.run.app',
		camera: 'https://frontend-proxy-oisyqrn6aq-ew.a.run.app',
		auth: 'https://europe-west1-alinosense.cloudfunctions.net'
	}
}

Vue.config.productionTip = false

Vue.use(FirebasePlugin, {
    apiKey: "AIzaSyCPLYAshsdos_1nVGLvEK1ycpoE9QX4tF4",
    authDomain: "alinosense.firebaseapp.com",
    projectId: "alinosense",
    storageBucket: "alinosense.appspot.com",
    messagingSenderId: "14908828135",
    appId: "1:14908828135:web:a6c88d64bad40bb024f542"
  })

Vue.use(ApiPlugin, { project: false })

Vue.prototype.$version = 'BETALOL'


new Vue({
	router,
	vuetify,
	render: h => h(App)
}).$mount('#app')
