var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"height":"50px","position":"relative"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingCameraData),expression:"!loadingCameraData"}],staticClass:"font-weight-bold sidebar-title"},[_vm._v(" Kameras")])]),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingCameraData),expression:"!loadingCameraData"}]}),(!_vm.loadingCameraData)?_c('div',{staticClass:"scroll-style"},[_c('v-virtual-scroll',{attrs:{"bench":50,"items":_vm.projectCameras,"height":"100%","item-height":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{style:(_vm.selectedCamera.serialNumber == item.serialNumber
              ? 'background-color: #FAE6E6'
              : ''),attrs:{"ripple":false},on:{"click":function($event){return _vm.selectCamera(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.selectedCamera.serialNumber == item.serialNumber
                  ? 'primary--text'
                  : '',domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{staticClass:"font-weight-light",class:_vm.selectedCamera.serialNumber == item.serialNumber
                  ? 'primary--text'
                  : '',domProps:{"textContent":_vm._s(item.serialNumber)}})],1),_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedCamera.serialNumber == item.serialNumber),expression:"selectedCamera.serialNumber == item.serialNumber"}]},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-divider')]}}],null,false,1363908132)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }