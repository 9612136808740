<template>
  <v-navigation-drawer app permanent color="white">
    <v-row justify="center">
      <!-- <div class="mt-10"></div> -->
      <v-img
        class="mt-4 mb-4 ml-5 mr-5"
        src="../../assets/logos/alinosense.png"
		contain
		width="180"
      ></v-img>
    </v-row>
    <v-divider class="mt-1" />

    <div>
      <div>
        
      </div>
      <v-menu
	  max-height="500"
        v-model="showProjects"
        :nudge-right="260"
        transition="fade-transition"
		close-on-content-click
		close-on-click
      >
      <template v-slot:activator="{ on, attrs }">
        <v-list style="padding: 0px !important">
			
          <v-list-item
            :ripple="false"
    
          v-bind="attrs"
          v-on="projects.length > 0 || admin ? on: ''"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-row class="ma-0 ml-1 mr-0 pt-1" align="center">
                  <span>
                    <!-- Handling wenn undefined??? -->
                    {{
                    `${project.substring(0, 25)} ${
                      project.length > 25 ? "..." : ""
                    }`
                  }}</span>
                  <v-spacer />
                  <v-icon
                    v-show="admin || projects.length > 0"
                    small
                    :class="showProjects ? 'primary white--text' : ''"
                    class="selectIcon"
                    >mdi-chevron-right
                  </v-icon>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
        <v-list >
			
          <v-list-item
            :ripple="false"
            dense
            v-show="admin"
            v-on:click="$router.push('/projects')"
			style="border-bottom: 1px solid rgba(200, 200, 200, .8)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <div>
                  <v-row class="ma-0" align="center">
                    <v-icon small class="mr-2">mdi-format-list-bulleted</v-icon>
                    <span class="mr-7"><b>Projekteübersicht</b></span>
                  </v-row>
                </div>
              </v-list-item-title>
              <!-- <v-list-item-subtitle v-text="`/${project.id}`"/> -->
            </v-list-item-content>
			 <v-divider v-show="userProjects.length > 0" />
          </v-list-item>
		  
	
          <v-list-item
		  dense
            :ripple="false"
            v-for="(project, index) in projects"
            :key="index"
            link
            v-on:click="openProject(project.id)"
          >
            <v-hover v-slot="{ hover }">
              <v-list-item-content>
                <v-list-item-title>
                  <div>
                    <v-row class="ma-0">
                      <span class="mr-7">{{ project.name }}</span> <v-spacer />
                      <div style="width: 17px; min-width: 17px; height: 20px">
                        <v-fade-transition>
                          <v-icon v-show="hover" small>mdi-open-in-new</v-icon>
                        </v-fade-transition>
                      </div>
                    </v-row>
                  </div>
                </v-list-item-title>
                <!-- <v-list-item-subtitle v-text="`/${project.id}`"/> -->
              </v-list-item-content></v-hover
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-divider />
	
    <!-- Navigation sidebar to select page -->
    <ListSidebar :admin="admin" class="mt-2" />

    <span style="position: absolute; bottom: 3px">
      <a style="font-size: 12px" class="ml-7" href="https://parkort.io/video/"
        >Datenschutzerklärung</a
      >
      <a
        style="font-size: 12px"
        class="ml-6"
        href="https://alinotec.de/impressum/"
        >Impressum</a
      >
    </span>
  </v-navigation-drawer>
</template>

<script>
import ListSidebar from "../../../frontend/components/navigation/ListSidebar.vue";

export default {
  components: {
    ListSidebar,
  },
  props: ["admin", "project", "projects"],
  data() {
    return {
      showProjects: false,
      userProjects: [],
    };
  },
  async created() {
    let user = await this.$firebase.user();
	
    Object.keys(user.claims.projects).forEach((proj) => {
      if (this.$route.params.id != proj) {
        this.userProjects.push({
          name: user.claims.projects[proj].name,
          id: proj,
        });
      }
    });
    console.log(this.userProjects);
    console.log(
      "USER?!?!?: ",
      user.claims.projects,
      Object.keys(user.claims.projects)
    );
  },

  methods: {
    openProject(id) {
      let routeData = this.$router.resolve(`/project/${id}/overview`);
      window.open(routeData.href, "_blank");
    },

    shortenName(nameString, maxChar) {
      var tempName = nameString.charAt(0).toUpperCase() + nameString.slice(1);
      return tempName.length > maxChar
        ? tempName.substr(0, maxChar - 1) + "..."
        : tempName;
    },
    changeProject(projectId) {
      this.$router.push({ name: "project", params: { projectId } });
    },
  },
};
</script>

<style scoped>
.lotName {
  pointer-events: none;
}
.legalLinks {
  font-size: 13px;
  color: grey;
  cursor: pointer;
  transition: 0.4s;
}
.legalLinks:hover {
  color: black;
}
.selectIcon {
  border-radius: 8px;
}
</style>