var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Privacy Policy ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasRights),expression:"hasRights"}],staticClass:"ma-2",class:{ active: _vm.expandInvite },staticStyle:{"position":"absolute","top":"10px","right":"10px"},attrs:{"disabled":_vm.loadingUser,"color":"primary","rounded":"","depressed":"","ripple":false},on:{"click":function($event){_vm.expandInvite = !_vm.expandInvite}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v(" Nutzer Hinzufügen ")],1),_c('v-card-text',[_c('div',[_c('v-col',{staticClass:"shrink"},[_c('v-expand-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandInvite),expression:"expandInvite"}],staticClass:"mx-auto secondary",attrs:{"height":"160","width":"100%"}},[_c('v-card-text',[_c('span',{staticClass:"pb-5"},[_vm._v(" Bitte geben Sie die Mail Adresse und Rechte des neuen Benutzers an.")]),_c('div',{staticClass:"mt-3",staticStyle:{"position":"relative"}},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('v-text-field',{staticStyle:{"max-width":"100%"},attrs:{"dense":"","hide-details":"","placeholder":"E-mail","filled":"","rounded":""},model:{value:(_vm.user.mail),callback:function ($$v) {_vm.$set(_vm.user, "mail", $$v)},expression:"user.mail"}}),_c('v-menu',{attrs:{"transition":"fade-transition","nudge-top":"15"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"user-select"},'div',attrs,false),on),[_c('div',[_c('span',{staticClass:"black--text",staticStyle:{"position":"absolute","top":"50%","transform":"translateY(-48%)","margin-left":"10px","font-size":"15px"}},[_c('span',[_vm._v(_vm._s(_vm.user.right))]),_c('v-icon',{staticStyle:{"position":"absolute","left":"75px"}},[_vm._v("mdi-chevron-down")])],1)])])]}}])},[_c('v-list',_vm._l((_vm.items),function(n){return _c('v-list-item',{key:n,attrs:{"ripple":false,"dense":""},on:{"click":function($event){return _vm.selectRole(n)}}},[_c('v-list-item-title',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(n))])],1)}),1)],1)],1)],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"ripple":false,"rounded":"","color":"primary","text":""},on:{"click":_vm.inviteUser}},[_vm._v(" Einladung senden ")])],1)],1)],1)],1)],1),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"relative","max-height":"400px","height":"400px"}},[(_vm.loadingUser)?_c('div',{staticClass:"loading-user"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":40,"color":"primary"}})],1):_vm._e(),_c('v-virtual-scroll',{attrs:{"bench":5,"items":_vm.userList,"max-height":"400","item-height":"64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.mail)+" ")])],1),_c('v-list-item-action',[(_vm.userMail != item.mail)?_c('v-menu',{attrs:{"disabled":!_vm.hasRights,"transition":"fade-transition","nudge-top":"15"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.hasRights
                              ? 'user-select-existing'
                              : 'user-select-existing-readonly'},'div',attrs,false),on),[_c('div',[_c('span',{staticClass:"black--text",class:_vm.hasRights ? 'role-name' : 'role-name-readonly'},[_c('span',[_vm._v(_vm._s(_vm.getRole(item.role)))])]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasRights),expression:"hasRights"}],staticStyle:{"position":"absolute","right":"10px","top":"50%","transform":"translateY(-50%)"}},[_vm._v("mdi-chevron-down")])],1)])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"ripple":false,"dense":""},on:{"click":function($event){return _vm.changeUserRole(item.mail, 'w')}}},[_c('v-list-item-title',{staticStyle:{"font-size":"16px"}},[_vm._v(" Mitarbeiter ")])],1),_c('v-list-item',{attrs:{"ripple":false,"dense":""},on:{"click":function($event){return _vm.changeUserRole(item.mail, 'r')}}},[_c('v-list-item-title',{staticStyle:{"font-size":"16px"}},[_vm._v(" Betrachter ")])],1),_c('v-list-item',{attrs:{"ripple":false,"dense":""},on:{"click":function($event){return _vm.changeUserRole(item.mail, 'o')}}},[_c('v-list-item-title',{staticStyle:{"font-size":"16px"}},[_vm._v(" Inhaber ")])],1)],1)],1):_vm._e()],1)],1),_c('v-divider')]}}])})],1)])]),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }