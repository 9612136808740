<template>
  <div class="project-page">
	  <InformationToast
      :show="informationMessage.show"
      :message="informationMessage.content"
      :fullscreen="true"
      :error="informationMessage.error"
      :reload="informationMessage.reload"
      :critical="informationMessage.critical"
    />

    <v-img
      width="150"
      class="pt-5 company-logo"
      contain
      src="./../assets/logos/alinotec.png"
    />
    <v-container>
      <v-dialog persistent v-model="createDialog" width="650">
        <CreateProject v-on:close="createDialog = false" />
      </v-dialog>
      <PageHeading
        title="Projekte"
        subtitle="Übersicht über den Alarmzeiten. Damit diese Zeiten aktiv werden, müssen Sie diese aktiv speichern."
      >
        <template v-slot:action>
          <!-- <CreateTimelapse v-on:created="createdNewTimelapse" v-on:errorloading="creationCriticalError"
	  	v-on:errorEvent="creationEventError"
	  /> -->

          <v-btn
            v-on:click="createDialog = true"
            rounded
            color="primary"
            depressed
            :ripple="false"
            ><v-icon class="mr-1">mdi-plus</v-icon> Projekt erstellen</v-btn
          >
          <TooltipButton
            :loading="refreshAccountData"
            class="ml-2"
            left
            iconName="mdi-refresh"
            icon
            delay="500"
            tooltip="Projektdaten aktualisieren"
            v-on:click="refreshAccount"
          />
        </template>
      </PageHeading>

      <!-- <div
        style="background-color: white; border-radius: 5px"
        class="elevation-2"
      >	
	  <v-row class="ma-4 pt-2 pb-2" align="center">
        <div >
          <b>Einladung <i>XYZ</i> </b><br />
          Sie wurden zur Mitarbeit im Projekt <b>XYZ</b> eingeladen. Möchten Sie
          dem Projekt beitreten?
        </div>
		<v-spacer/>
		<v-btn class="mr-2" small :ripple="false" rounded depressed color="primary" outlined>ablehnen</v-btn>
		<v-btn small :ripple="false" rounded depressed color="primary">Annehmen</v-btn>
	  </v-row>
		 :style="refreshAccount ? 'cursor: progress' : ''"
      </div> -->
      <div style="positon: relative; width: 100%">
        <v-virtual-scroll
		:key="updateList"
          :style="
            refreshAccountData ? 'pointer-events: none; opacity: .3;' : ''
          "
          :bench="25"
          :items="userProjects"
          height="calc(100vh - 200px)"
          item-height="64"
        >
          <template v-slot:default="{ item }">
            <v-tooltip
              style="opacity: 1 !important"
              bottom
			  disabled
              open-delay="2000"
              transition="fade-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  color="primary"
                  :key="item.id"
                  :ripple="false"
                  v-on:click="listClickHandle(0, 0, item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <strong>{{ item.name }}</strong>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="font-weight-light" style="font-size: 14px">{{
                        item.id
                      }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-row>
                      <v-btn
                        class="mr-2"
                        icon
                        :ripple="false"
                        v-on:click="listClickHandle(1, 0, item)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >

                      <v-btn
					  	disabled
                        class="mr-2"
                        icon
                        :ripple="false"
                        v-on:click="listClickHandle(1, 2, item)"
                        ><v-icon>mdi-account-multiple-plus</v-icon></v-btn
                      >
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <div v-if="preview">
                Aktueller Baufortschritt:
                <div
                  class="mt-2 mb-2"
                  style="
                    background-color: white;
                    height: 300px;
                    width: 450px;
                    border-radius: 10px;
                  "
                ></div>
              </div>
              <div v-else>
                {{ item }} <br />
                <span class="font-weight-light"
                  >Leider ist keine Projektvorschau möglich.</span
                >
              </div>
            </v-tooltip>
          </template>
        </v-virtual-scroll>
      </div>

      <v-divider />

      <v-dialog v-model="userManagement.show" width="700">
        <InviteUser :project="userManagement.data" />
      </v-dialog>

	  <v-dialog v-model="deleteDialog.show" width="350">
		  <v-card>
			  <v-card-title>
				  Projekt löschen
			  </v-card-title>
			  <v-card-text>
				  Sind sie sicher, dass sie das Projekt <i>{{deleteDialog.name}}</i> löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.
        	
			  </v-card-text>

			  <v-card-actions>
				  <v-spacer/>
				  	<v-btn rounded depressed :ripple="false" text color="primary"><span class="mr-1 ml-1">Abbrechen</span></v-btn>

				  <v-btn v-on:click="deleteProject(deleteDialog.id)" rounded depressed :ripple="false" color="primary"><span class="mr-1 ml-1">Löschen</span></v-btn>
			  </v-card-actions>
		  </v-card>
      </v-dialog>


    </v-container>
  </div>
</template>
<script>
import InformationToast from "../components/InformationToast";

import CreateProject from "../components/projects/CreateProject";
import ProjectInvitations from "../components/projects/Invitations.vue";
import TooltipButton from "../components/TooltipButton.vue";
import InviteUser from "../components/projects/InviteUser";
import PageHeading from "../components/TextStyles/PageHeading.vue";

export default {
  components: {
    ProjectInvitations,
    TooltipButton,
    InviteUser,
    PageHeading,
    CreateProject,
	InformationToast,
  },
  data() {
    return {
		updateList: false,
		informationMessage: {
        content: "",
        show: false,
        error: false,
        reload: false,
        critical: false,
      },

		deleteDialog: {
			show: false,
			project: undefined,
			id: undefined
		},
      createDialog: false,
      userManagement: {
        show: false,
        data: null,
      },
      refreshAccountData: false,
      invites: [],
      userProjects: [],
      preview: true,
      buttonClicked: false,
    };
  },

  async created() {
    // if (this.$route.query.invite) {
    // 	this.welcomeInvitation()
    // }

    // this.$api.create("teststelle");
    // console.log("GET USER");
    // var result = await this.$api.request().projectUser();
    // console.log(result);

    this.refreshAccount();
    //this.getProjects();
  },

  methods: {
	  /**
     * With this function an information or error toast can be displayed
     * @param message Toast content message
     * @param error (optional, default: false) Boolean whether it is an error toast
     * @param reloadSuggestion (optional, default: false) Boolean whether a reload suggestion shall be made
     * @param critical (optional, default: false) Boolean whether error is critical (disable menu)
     */
    showToast(
      message,
      error = false,
      reloadSuggestion = false,
      critical = false
    ) {
      this.informationMessage.show = !this.informationMessage.show;
      this.informationMessage.content = message;
      this.informationMessage.error = error;
      this.informationMessage.reload = reloadSuggestion;
      this.informationMessage.critical = critical;
    },
	
    //   welcomeInvitation() {
    // 	let query = Object.assign({}, this.$route.query);
    // 	delete query.invite;
    // 	this.$router.replace({ query });
    //   },
    showUserManagement(project) {
      this.userManagement.data = project;
      this.userManagement.show = true;
    },

    timeoutButton() {
      this.buttonClicked = true;
      setTimeout(() => {
        this.buttonClicked = false;
      }, 5);
    },

    listClickHandle(clickID, buttonID, payload) {
      if (clickID == 1) {
        if (buttonID == 0) {
			this.deleteDialog.name = payload.name
			this.deleteDialog.id = payload.id
          this.deleteDialog.show = true
        } else if (buttonID == 2) {
          this.showUserManagement(payload);
        }
        console.log("BUTTON", buttonID, payload);
        this.timeoutButton();
      } else {
        if (!this.buttonClicked) {
          return this.$router.push(`/project/${payload}`)
        }
      }
    },
    async getProjects() {
		console.log("GET PROJECTs")
		try {
			var res = await this.$api.authRequest().getAllProjects();
	  if (!res || res.error) {
		  this.showToast('Beim Laden der Projekte ist ein Fehler aufgetreten.', true, true, true)
	  }
		} catch (error) {
			this.showToast('Beim Laden der Projekte ist ein Fehler aufgetreten.', true, true, true)
		}
      
	console.log(res)
      this.userProjects = res;
	  this.updateList = !this.updateList
    },

    async refreshAccount() {
      this.refreshAccountData = true;
      //await this.$firebase.refreshAccount();
      await this.getProjects();
	  this.refreshAccountData = false

    },

    async deleteProject(projectName) {
		console.log("PROJECT NAME", projectName)
		if (!projectName) {
			this.showToast('Beim Löschen ist ein Fehler aufgetreten')
		}
      console.log("deleteProject", projectName);
	  this.$api.create(projectName)
      var resp = await this.$api.request().deleteProject();
	  console.log(resp)
	  if (resp.error) {
		  this.showToast('Beim Löschen ist ein Fehler aufgetreten')
	  }
	  this.deleteDialog.show = false
	  this.$api.delete()
		this.getProjects()
    },
  },
};
</script>

<style scoped>
html {
  overflow: hidden !important;
}
.project-page {
  background-color: #f8f8f8;
  min-height: 100vh;
  width: 100vw;
}
.company-logo {
  position: absolute;
  top: 0px;
  left: 15px;
  opacity: 1 !important;
}
.info-window-maps {
  height: 230px;
  width: 330px;
  padding: 15px;
  background-color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
}
</style>