<template>
  <div>
    hjallos PRoject settings lol

    <div class="calendar-column">
      <v-row class="ma-0" justify="center">Montag</v-row>
      <v-hover v-slot="{ hover }" :disabled="false">
        <div class="calendar-area-time">
          <v-overlay
            absolute
            color="white"
            :opacity="0.8"
            :value="hover"

          >
		  <div style="height: 600px; width: 200px; background-color: transparent; cursor: pointer" v-on:click="openEditDialog"/> 
	
			<div style="position: absolute; top: 100px; " class="black--text"> LOL</div>
		  </v-overlay>
          <div :style="topTime" >
			  
            <span style="position: absolute; bottom: 0px; font-size: 13px; left: 10px; opacity: .8" class="white--text font-weight-light"
              >
			  <b>18:00</b> Uhr  bis  <b>6:00</b> Uhr
			  </span>
          </div>
          <div v-for="i in 24" :key="i" class="calendar-hour-row" />
          <div :style="bottomTime" >
			  <span style="position: absolute; top: 0px; font-size: 13px; left: 10px; opacity: .8" class="white--text font-weight-light"
              >
			  <b>18:00</b> Uhr  bis  <b>6:00</b> Uhr
			  </span>
		  </div>
        </div>
      </v-hover>
		 <v-dialog
      v-model="changeTime"
      width="350"
    >

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Privacy Policy
        </v-card-title>

        <v-card-text>
			<v-row align="center" class="ma-0">
			<v-text-field
		  v-model="timeStart"
          type="time"
		  color="primary"
		  rounded
		  filled
		  hide-details
		  dense
		  style="max-width: 150px"

        ></v-text-field>
		<span class="mr-2 ml-2">bis</span>
		<v-text-field
		  v-model="timeEnd"
          type="time"
		  color="primary"
		  rounded
		  filled
		  hide-details
		  dense
		  style="max-width: 150px"

        ></v-text-field>
			</v-row>


        </v-card-text>
			
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
		  	rounded
			  depressed
            color="primary"
			:ripple="false"
            @click="closeEditDialog"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
		changeTime: false,

		timeStart: '12:30:00',
		timeEnd: '15:30:00',

		update: false,
		alarmTime: {
			start: 20.75, 
			end: 8
		},
		defaultStyle: `
			background-color: var(--v-primary-base);
			position: absolute; 
			width: 100%; 
			opacity: .8; 
			border-radius: 10px;
			`
	};
  },
  created() {
	  this.timeStart = this.parseFloatTime(this.alarmTime.start)
	  this.timeEnd = this.parseFloatTime(this.alarmTime.end)
  },

  methods: {
	  parseFloatTime(time) {
		  var hour = parseInt(time)
		  
		  var min = (60*(time - hour))
		  
		  return `${(`0${hour}`.slice(-2))}:${(`0${min}`.slice(-2))}`
	  },
	  parseTime(time) {
		  var tempTime = time.split(':')
		  return parseFloat(tempTime[0] + '.' + (10/(60/tempTime[1])))
	  },
	  closeEditDialog() {
		  this.alarmTime.start = this.parseTime(this.timeStart)
		  this.alarmTime.end = this.parseTime(this.timeEnd)
			this.update = !this.update
			this.changeTime = false
	  },
	  openEditDialog() {
		  console.log("DIALOG") 
		  this.changeTime = true;
	  }
  },
  computed: {
	
    bottomTime() {
		console.log("BOTTOM TIME")
		var startTime = this.alarmTime.start
		var endTime = this.alarmTime.end
		if (endTime == startTime) {
			return this.defaultStyle + `
			bottom: 0px; 
			height: ${24 * 25}px; `
		}
		
		if (startTime > endTime) {
			return this.defaultStyle + `
		bottom: 0px; 
		height: ${parseInt((24 - startTime) * 25)}px; 
		border-bottom-right-radius: 0px!important;
		border-bottom-left-radius: 0px!important;`
		} else {
			return 'display: none'
		}
      
    },
	topTime() {
		var startTime = this.alarmTime.start
		var endTime = this.alarmTime.end
		if (endTime == startTime) {
			return 'display: none'
		}
		if (startTime > endTime) {
			return this.defaultStyle + `
		top: 0px; 
		height: ${parseInt((endTime * 25))}px; 
		border-top-right-radius: 0px!important;
		border-top-left-radius: 0px!important;`
		} else {
			console.log("THI")
			return this.defaultStyle + `
			top: ${parseInt((startTime * 25))}px; 
			height: ${parseInt(((endTime - startTime) * 25))}px; 

			border-top-right-radius: 10px!important;
			border-top-left-radius: 10px!important;
			
			`
		}
      

    },
  },
};
</script>
<style scoped>
.calendar-column {
  position: relative;
  height: 625px;
  width: 200px;
  background-color: white;
}
.calendar-area-time {
  position: relative;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}
.calendar-set-time {
  position: absolute;
  top: 0px;
  background-color: var(--v-primary-base);
  height: 150px;
  width: 100%;
  opacity: 0.8;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.calendar-set-time-bottom {
  position: absolute;
  bottom: 0px;
  background-color: var(--v-primary-base);
  height: 100px;
  width: 100%;
  opacity: 0.8;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.calendar-hour-row {
  width: 200px;
  height: 25px;
  background-color: transparent;
  border-bottom: 1px solid rgba(199, 199, 199, 0.4);
}
</style>