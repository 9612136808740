<template>
  <div class="ma-4">
    <InformationToast
      :show="informationMessage.show"
      :message="informationMessage.content"
      :fullscreen="false"
      :error="informationMessage.error"
      :reload="informationMessage.reload"
      :critical="informationMessage.critical"
    />
    <PageHeading
      title="Kameras"
      subtitle="Übersicht über alle Kameras in diesem Projekt."
    >
      <template v-slot:action>
        <!-- <CreateTimelapse v-on:created="createdNewTimelapse" v-on:errorloading="creationCriticalError"
	  	v-on:errorEvent="creationEventError"
	  /> -->
        <v-btn rounded color="primary" depressed :ripple="false" v-on:click="addCamera = !addCamera"
          ><v-icon class="mr-1">mdi-content-save</v-icon>Kamera
          hinzufügen</v-btn
        >
      </template>
    </PageHeading>

    <v-virtual-scroll
      :bench="25"
      :items="cameras"
      height="300"
      item-height="64"
    >
      <template v-slot:default="{ item }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.serialNumber }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-row class="ma-0">
              <v-btn
                v-on:click="removeCamera(item.serialNumber)"
                class="mr-3"
                icon
				disabled
                :ripple="false"
              >
                <v-icon> mdi-delete </v-icon>
              </v-btn>

              <v-btn
                v-on:click="showCameraArmingTimes(item.schedule)"
                :disabled="
                  !item.schedule || item.schedule.error || loadingSchedule
                "
                :loading="loadingSchedule"
                icon
                :ripple="false"
              >
                <v-icon> mdi-calendar-range </v-icon>
              </v-btn>
            </v-row>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>
      </template>
    </v-virtual-scroll>
	<v-dialog v-model="addCamera" width="600">
		
		<v-card>
			<v-card-title>Kamera hinzufügen</v-card-title>
			<!-- 
                v-on:error="errorEvent" -->
			<CameraInformation
                :projectIdentifier="$route.params.id"
                v-on:create="createdCameraEvent"
                v-on:close="addCamera = false"
				v-on:error="errorAddingEvent"
              />
		</v-card>
	</v-dialog>
    <v-dialog v-model="cameraArming.show" width="1200">
      <v-card style="background-color: #f6f6f6">
        <v-card-title style="padding: 0px !important">
          <v-spacer />
          <v-btn
            class="mt-4 mr-4"
            icon
            :ripple="false"
            depressed
            v-on:click="cameraArming.show = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text style="padding-top: 0px !important">
          <AlarmSchedule
            :camera="true"
            :readonly="true"
            :loading="false"
            :schedule="cameraArming.schedule"
            :isAdmin="false"
            v-on:error="errorDialogEvent"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
	
	
  </div>
</template>

<script>
import PageHeading from "../../../../packages/frontend/components/TextStyles/PageHeading.vue";
import InformationToast from "../../../../packages/frontend/components/InformationToast";
import AlarmSchedule from "@/components/Cameras/AlarmSchedule.vue";
import CameraInformation from "../../../../packages/frontend/components/projects/creation/CameraInformation";

export default {
  components: {
    PageHeading,
    InformationToast,
    AlarmSchedule,
	CameraInformation
  },

  data() {
    return {
		addCamera: false, 
      cameraArming: {
        show: false,
        schedule: undefined,
      },
      cameras: this.$api.projectCameras(),
      loadingSchedule: true,
      informationMessage: {
        content: "",
        show: false,
        error: false,
        reload: false,
        critical: false,
      },
    };
  },

  async created() {
    console.log("CREATED; CAMS: ", this.cameras);

    for (let index = 0; index < this.cameras.length; index++) {
      var scheduleData = await this.$api
        .request()
        .getCameraSchedule(this.cameras[index].serialNumber);

      if (scheduleData.error || !scheduleData || !scheduleData.arming) {
        this.showToast(
          `Fehler beim Laden der Alarmzeit von der Kamera '${this.cameras[index].name}'`,
          true
        );
      } else {
        this.cameras[index].schedule = scheduleData;
      }
    }
    setTimeout(() => {
      this.loadingSchedule = false;
    }, 500);

    console.log("CREATED; CAMS: ", this.cameras);
  },

  methods: {
	 async createdCameraEvent() {
		  var cameraDataHandle = await this.$api.request().getProjectCameras()
		  if (!cameraDataHandle || cameraDataHandle.error || cameraDataHandle.length == 0) {
			  this.showToast('Beim Neuladen der Kameras kam es zu einen unerwarteten Fehler', true, true, true)
		  } else {
			  this.cameras = cameraDataHandle
		  }

		  this.addCamera = false

	  },
	  errorAddingEvent(errorData) {
		  console.log("EVENT!!!")
		  this.showToast('Beim Hinzufügen der Kamera ist ein unerwarteter Fehler aufgetreten.', true)
	  },
    async removeCamera(serialNumber) {
      try {
        var resp = await this.$api.request().removeCamera(serialNumber);
        if (resp.error || !resp) {
          this.showToast(
            "Beim Löschen der Kamera ist ein unerwarteter Fehler aufgetreten.",
            true
          );
        }
      } catch (error) {
        this.showToast(
          "Beim Löschen der Kamera ist ein unerwarteter Fehler aufgetreten.",
          true
        );
      }
    },

    errorDialogEvent(eventData) {
      this.cameraArming.show = false;
      this.cameraArming.schedule = undefined;
      this.showToast(
        eventData.content,
        eventData.error,
        eventData.reload,
        eventData.critical
      );
    },
    showCameraArmingTimes(cameraScheduleObj) {
      if (cameraScheduleObj == undefined || cameraScheduleObj.length == 0) {
        this.showToast(
          "Beim Laden der Kamera Alarmzeiten ist ein Fehler aufgetreten.",
          true
        );
      } else {
        console.log("SCHEDU", cameraScheduleObj);
        this.cameraArming.schedule = cameraScheduleObj;
        this.cameraArming.show = true;
      }
    },
    /**
     * With this function an information or error toast can be displayed
     * @param message Toast content message
     * @param error (optional, default: false) Boolean whether it is an error toast
     * @param reloadSuggestion (optional, default: false) Boolean whether a reload suggestion shall be made
     * @param critical (optional, default: false) Boolean whether error is critical (disable menu)
     */
    showToast(
      message,
      error = false,
      reloadSuggestion = false,
      critical = false
    ) {
      this.informationMessage.show = !this.informationMessage.show;
      this.informationMessage.content = message;
      this.informationMessage.error = error;
      this.informationMessage.reload = reloadSuggestion;
      this.informationMessage.critical = critical;
    },
  },
};
</script>