<template>
  <div  @contextmenu="handler($event)">
    <!-- Background Construction (?) image; loaded from config file -->
    <v-img
      id="backgroundImage"
      class="background-construction"
      src="../../assets/background/login_background.jpg"
    >
      <div
        style="position: absolute; bottom: 15px; left: 20px; opacity: 0.7"
        class="white--text"
      >
        <v-row justify="center" class="ma-0">
          <v-icon color="white" size="14" class="mr-1">mdi-copyright</v-icon>
          <span style="font-size: 14px"
            >{{ backgroundConfig.login.credits.name }},</span
          >
          <span class="ml-1 font-weight-light" style="font-size: 14px">{{
            backgroundConfig.login.credits.year
          }}</span>
        </v-row>
      </div>
    </v-img>
    <v-img
      width="200"
      class="pt-5 company-logo"
      contain
      src="./../../assets/logos/alinotec.png"
    />

    <!-- The login card consists of two parts; on the left side a carousel where new 
		information, new functions, etc. can be shown to the customer; on the right side is the login or 
		the forgotten password part. -->
    <v-card flat class="landing-sidebar">
      <div class="invalid-invite">
        <v-alert
          v-model="showInvalidInvite"
          color="primary"
          border="left"
          elevation="1"
          colored-border
        >
          Oh, leider ist die Einladung zum Projekt '{{ invite.project }}'
          abgelaufen.
        </v-alert>
      </div>
      <!-- The data of the carousel is stored in a config and can be changed at any time; the data is loaded as DIVS in the HTML. -->
      <div class="carousel-side">
        <v-carousel
          style="height: 100%"
          height="100%"
          hide-delimiter-background
          :show-arrows="false"
          :continuous="true"
          :cycle="true"
        >
          <v-carousel-item v-for="i in carouselConfig" :key="i">
            <div class="carousel-content" v-html="i"></div
          ></v-carousel-item>
        </v-carousel>
      </div>
      <div class="login-side">
        <v-fade-transition>
          <TooltipButton
            v-show="showForgetInput"
            class="ml-2 mt-2"
            left
            iconName="mdi-chevron-left"
            icon
            delay="500"
            tooltip="Zurück zur Anmeldung"
            v-on:click="showForgetInput = false"
          />
        </v-fade-transition>

        <v-card-text class="login-field-style">
          <LoginCard
            v-if="showForgetInput == false"
            :forgot="showForgetInput"
            @input="userInputEvent"
            @forgot="forgotPasswordEvent"
          />

          <ForgetCard v-else @input="userInputEvent" />
        </v-card-text>


        <v-card-actions class="action-button">
          <v-col style="padding-top: 0px !important">
            <div class="" v-show="errorMessage.show">
              <v-col class="ma-0" style="padding-bottom: 3px !important">
                <v-row class="ma-0" align="center">
                  <div v-if="errorMessage.error">
                    <v-icon color="primary" size="20">mdi-alert-circle</v-icon>

                    <span
                      class="primary--text ml-2"
                      style="font-size: 14px; max-width: 90%"
                    >
                      <b>Fehler</b>
                    </span>
                  </div>
                  <div v-else>
                    <v-icon color="green" size="20">mdi-check-circle</v-icon>
                    <span
                      class="black--text ml-2"
                      style="font-size: 14px; max-width: 90%"
                    >
                      <b>Super!</b>
                    </span>
                  </div>
                </v-row>
                <div>
                  <span
                    v-if="errorMessage.error"
                    class="primary--text font-weight-light"
                    style="font-size: 14px"
                    >{{ errorMessage.text }}</span
                  >
                  <span
                    v-else
                    class="black--text font-weight-light"
                    style="font-size: 14px"
                    >{{ errorMessage.text }}</span
                  >
                </div>
              </v-col>
            </div>
            <v-btn
              :disabled="isDisabledLogin"
              :loading="loginLoading"
              class="mt-2"
              rounded
              color="primary"
              :ripple="false"
              block
              v-on:click="loginButtonHandle"
              depressed
              >{{ showForgetInput ? "Zurücksetzen" : "Anmelden" }}</v-btn
            >
          </v-col>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
//import { signIn } from '@/plugins/auth';
import carouselConfig from "../../config/loginCarousel.config.json";
import backgroundConfig from "../../config/background.config.json";
import TooltipButton from "../../components/TooltipButton.vue";
import LoginCard from "../../components/auth/LoginCard.vue";
import ForgetCard from "../../components/auth/ForgetCard.vue";

export default {
  data() {
    return {
      successReset: false,
      carouselConfig,
      backgroundConfig,
      loginLoading: false,
      errorMessage: {
        show: false,
        error: true,
        text: "",
      },
      showForgetInput: false,
      showPassword: false,

      userCredentials: {
        mail: "",
        password: "",
      },

      invite: {
        isInvite: false,
        project: null,
      },
      showInvalidInvite: false,
    };
  },
  components: {
    TooltipButton,
    LoginCard,
    ForgetCard,
  },

  created() {
    if (this.$route.query.invite) {
      var datetimeQuery = this.$route.query.dt;
      var projectQuery = this.$route.query.invite;

      let query = Object.assign({}, this.$route.query);
      delete query.invite;
      delete query.dt;
      this.$router.replace({ query });

      this.invite.project = projectQuery;
      if (this.isValidInvite(datetimeQuery)) {
        this.invite.isInvite = true;
      } else {
        this.showInvalidInvite = true;
      }
    }
  },
  watch: {
    showForgetInput: function () {
      this.errorMessage.show = false;
      this.errorMessage.error = true;
      this.errorMessage.text = "";
      this.successReset = false;
    },
  },

  computed: {
    isDisabledLogin() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.successReset) {
        return true;
      } else if (
        this.userCredentials.mail.length > 0 &&
        re.test(this.userCredentials.mail)
      ) {
        if (this.showForgetInput) {
          return false;
        } else if (this.userCredentials.password.length > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },

  methods: {
    forgotPasswordEvent(eventState) {
      this.showForgetInput = eventState;
    },

    /**
     *
     *
     * @param eventData Input Data containing mail & password of login
     */
    userInputEvent(eventData) {
      this.userCredentials = { ...eventData };
    },

    isValidInvite(dt) {
      var hourDiff = Math.floor((+new Date() - dt * 1000) / 3600000);
      if (hourDiff <= 168 && hourDiff >= 0) {
        return true;
      } else {
        return false;
      }
    },
    handler: function (e) {
      e.preventDefault();
    },

    async forgotPassword() {
      //console.log("FORGOT PASSWORT: ", this.userCredentials.mail);
      let result = await this.$api
        .authRequest()
        .forgot({ mail: this.userCredentials.mail });
		
      console.log(result);
      if (result == undefined) {
        this.errorMessage.text =
          "Oh, leider ist ein Fehler aufgetreten. Probieren Sie es erneut.";
        this.errorMessage.error = true;
      } else if (result.error) {
        this.errorMessage.text = result.message;
        this.errorMessage.error = true;
      } else {
        this.errorMessage.text =
          "Überprüfen Sie Ihr Postfach, wir haben Ihnen eine Mail gesendet.";
        this.errorMessage.error = false;
        this.successReset = true;
      }

      this.errorMessage.show = true;
    },

    async loginButtonHandle() {
      this.loginLoading = true;
      if (this.showForgetInput) {
        await this.forgotPassword();
      } else {
        await this.login();
      }
      this.loginLoading = false;
    },

    async login() {
      let loginResult = await this.$firebase.login(
        this.userCredentials.mail,
        this.userCredentials.password
      );

      if (loginResult && loginResult.success) {
        if (this.invite.isInvite && this.invite.project != undefined) {
          // this.$api.create(this.$route.query.invite)
          var claimedProject = await this.$api.authRequest().addProjectClaims({
            mail: this.userCredentials.mail,
            project: this.invite.project,
            accept: true,
          });

          console.log(claimedProject);

          if (
            claimedProject &&
            claimedProject != undefined &&
            claimedProject.length > 0
          ) {
            // this.$api.delete()
            await this.$firebase.auth.signOut();

            loginResult = await this.$firebase.login(
              this.userCredentials.mail,
              this.userCredentials.password
            );

            if (loginResult.user.projects[claimedProject] != undefined) {
              this.$router
                .push({
                  name: "project",
                  params: {
                    id: claimedProject,
                  },
                })
                .catch((err) => {
                  console.log("FOUND ERROR", err);
                });
              // this.$router.push(`/project/${claimedProject}/overview`)
            }
          }
        }

        if (loginResult.user && loginResult.user.projects) {
          console.log("HER!");
          //if (loginResult.user.admin == true) {
          return this.$router.push("/projects");
          //}
          //   return this.$router.push(
          //     `/project/${Object.keys(loginResult.user.projects)[0]}`
          //   );
        } else {
          return this.$router.push("/oops");
        }
      } else {
        this.userCredentials.password = "";
        if (loginResult && loginResult.message) {
          this.errorMessage.text = loginResult.message;
        } else {
          this.errorMessage.text =
            "Ein unbekannter Fehler ist aufgetreten. Bitte probieren Sie es nochmal.";
        }
        this.errorMessage.error = true;
        this.errorMessage.show = true;
      }
    },
  },
};
</script>



<style scoped>
.background-construction {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  opacity: 0.6;
  object-fit: contain;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.landing-sidebar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: white;
  width: 900px;
  height: 500px;
  z-index: 2;
}

.login-fields {
  position: absolute;
}
.company-logo {
  position: absolute;
  top: 10px;
  left: 20px;
  opacity: 1 !important;
}
.action-button {
  position: absolute;
  bottom: 0px;
  width: 100%;
  right: 0px;
}
.carousel-content {
  position: absolute;

  background-color: transparent;
  margin-top: 0px;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.carousel-side {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px !important;
  height: 500px;
  width: 500px;
  background-color: var(--v-primary-base);
}

.login-side {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 400px;
  top: 50%;
  transform: translateY(-50%);
}

.invalid-invite {
  position: absolute;
  right: 0px;
  width: 360px;
  margin-right: 20px;
  top: 10px;
}
.login-field-style {
  padding-top: 0px !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

</style>