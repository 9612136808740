"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiRequests = void 0;
const axios_1 = require("axios");
/**
 * author: <steffen.linssen@parkort.io>
 *
 * Api request collection (GET, POST, PUT, DELETE, PATCH)
 *
 */
class ApiRequests {
    constructor(url, projectId, token) {
        this.url = url;
        this.token = token;
        this.projectId = projectId;
    }
    format(url, uri) {
        return __awaiter(this, void 0, void 0, function* () {
            var result = {
                header: yield this.createHeader(),
                url: this.formatURL(url, uri)
            };
            console.log(result);
            return result;
        });
    }
    /**
     * Formatting of the header with project name and authorization token
     * author: <steffen.linssen@parkort.io>
     *
     * @returns formatted Header as object
     */
    createHeader() {
        return __awaiter(this, void 0, void 0, function* () {
            return { "Authorization": `Bearer ${yield this.token}`, 'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers': '*' };
        });
    }
    formatURL(url, uri) {
        return `${url}/${uri}`;
    }
    handleRequest(response) {
        return __awaiter(this, void 0, void 0, function* () {
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error('lol');
            }
        });
    }
    /**
     * Api GET Request
     * author: <steffen.linssen@parkort.io>
     *
     * @param url Request Url
     * @returns Promise<ApiResponse<T>> Request promise
     */
    get(url, uri) {
        return __awaiter(this, void 0, void 0, function* () {
            // Creating the header and URL for the request
            var req = yield this.format(url, uri);
            if (!req)
                return { error: true };
            // GET Request execution
            return axios_1.default.get(req.url, { headers: req.header })
                .then(response => { return this.handleRequest(response); })
                .catch(err => { return { error: true, message: err, status: err.response.status }; });
        });
    }
    /**
     * Api POST Request
     * author: <steffen.linssen@parkort.io>
     *
     * @param url Request Url
     * @param payload Request Payload
     * @returns Promise<ApiResponse<T>> Request promise
     */
    post(url, uri, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            // Creating the header and URL for the request
            var req = yield this.format(url, uri);
            if (!req)
                return { error: true };
            // POST Request execution
            return axios_1.default.post(req.url, payload, { headers: req.header })
                .then(response => { return this.handleRequest(response); })
                .catch(err => {
                return { error: true, message: err };
            });
        });
    }
    /**
     * Api PUT Request
     * author: <steffen.linssen@parkort.io>
     *
     * @param url Request Url
     * @param payload Request Payload
     * @returns Promise<ApiResponse<T>> Request promise
     */
    put(url, uri, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            // Creating the header and URL for the request
            var req = yield this.format(url, uri);
            if (!req)
                return { error: true };
            // PUT Request execution
            return axios_1.default.put(req.url, payload, { headers: req.header })
                .then(response => { return this.handleRequest(response); })
                .catch(err => { return { error: true, message: err }; });
        });
    }
    /**
     * Api DELETE Request
     * author: <steffen.linssen@parkort.io>
     *
     * @param url Request Url
     * @returns Promise<ApiResponse<T>> Request promise
     */
    delete(url, uri) {
        return __awaiter(this, void 0, void 0, function* () {
            // Creating the header and URL for the request
            var req = yield this.format(url, uri);
            if (!req)
                return { error: true };
            // DELETE Request execution
            return axios_1.default.delete(req.url, { headers: req.header })
                .then(response => { return this.handleRequest(response); })
                .catch(err => { return { error: true, message: err }; });
        });
    }
    /**
     * Api PATCH Request
     * author: <steffen.linssen@parkort.io>
     *
     * @param url Request Url
     * @param payload Request Payload
     * @returns Promise<ApiResponse<T>> Request promise
     */
    patch(url, uri, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            // Creating the header and URL for the request
            var req = yield this.format(url, uri);
            if (!req)
                return { error: true };
            // PATCH Request execution
            return axios_1.default.patch(req.url, payload, { headers: req.header })
                .then(response => { return this.handleRequest(response); })
                .catch(err => { return { error: true, message: err }; });
        });
    }
}
exports.ApiRequests = ApiRequests;
