<template>
  <div>
    <!-- Toolbar -->
    <div style="height: 50px; position: relative">
      <span v-show="!loadingCameraData" class="font-weight-bold sidebar-title">
        Kameras</span
      >
    </div>

    <v-divider v-show="!loadingCameraData" />
    <div v-if="!loadingCameraData" class="scroll-style">
      <v-virtual-scroll
        :bench="50"
        :items="projectCameras"
        height="100%"
        item-height="60"
      >
        <template v-slot:default="{ item }">
          <v-list-item
            :style="
              selectedCamera.serialNumber == item.serialNumber
                ? 'background-color: #FAE6E6'
                : ''
            "
            v-on:click="selectCamera(item)"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title
                :class="
                  selectedCamera.serialNumber == item.serialNumber
                    ? 'primary--text'
                    : ''
                "
                v-text="item.name"
              ></v-list-item-title>
              <v-list-item-subtitle
                class="font-weight-light"
                :class="
                  selectedCamera.serialNumber == item.serialNumber
                    ? 'primary--text'
                    : ''
                "
                v-text="item.serialNumber"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action
              v-show="selectedCamera.serialNumber == item.serialNumber"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
		
      loadingCameraData: false,
      selectedCamera: {
        serialNumber: undefined,
      },

      projectCameras: [],
    };
  },

  async created() {
    await this.retrieveCameraInformation();
  },
  methods: {
    async retrieveCameraInformation() {
      this.loadingCameraData = true;
      this.$emit("loading", true);

		try {
			 var cameras = await this.$api.request().getProjectCameras();	
			 
			 if ((cameras.error && cameras.status == 404) || cameras.length == 0) {
				 return this.$emit('noCameras', true)

			 } else if(cameras.error) {
				  return this.$emit('critical', 'Ein unerwarteter, interner Server Fehler ist aufgetreten.')
			 }

			 this.hasCameras = true
			
		} catch (error) {
			return this.$emit('critical', 'Ein unerwarteter, interner Server Fehler ist aufgetreten.')
		}


      for (let index = 0; index < cameras.length; index++) {
		  
        var cameraPresets = await this.$firebase.getStoragePresets(
          "gs://alinosense.appspot.com",
          `raw/${cameras[index].serialNumber}`
        );
        cameras[index].presets = cameraPresets;
      }

      this.projectCameras = cameras;
      this.loadingCameraData = false;
      this.$emit("loading", false);
    },

    async selectCamera(cameraId) {
      if (this.selectedCamera.serialNumber != cameraId.serialNumber) {
        this.selectedCamera = { ...cameraId };
        this.$emit("selected", this.selectedCamera);
      }
    },
  },
};
</script>

<style scoped>
.sidebar-title {
  font-size: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll-style {
  height: calc(100% - 50px);
}
</style>