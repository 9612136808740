<template>
  <div>
    <v-card>
      <v-card-text>
        <span class="pl-3"
          >Bitte füllen Sie alle notwendigen (*) Projektfelder aus.</span
        >

        <v-text-field
          v-model="projectInformation.name"
          class="mt-5"
          filled
          hide-details
          rounded
          dense
          placeholder="Projektname*"
        ></v-text-field>

        <v-text-field
          v-model="projectInformation.contact"
          class="mt-8"
          filled
          hide-details
          rounded
          dense
          placeholder="Ansprechpartner*"
        ></v-text-field>

        <v-text-field
          v-model="projectInformation.addr.street"
          class="mt-2"
          filled
          hide-details
          rounded
          dense
          placeholder="Straße & Hausnummer"
        ></v-text-field>

        <v-row class="ma-0 mt-2">
          <v-text-field
            v-model="projectInformation.addr.zip"
            filled
            hide-details
            rounded
            dense
            placeholder="PLZ"
            class="mr-2"
            style="max-width: 200px"
          ></v-text-field>
          <v-text-field
            v-model="projectInformation.addr.city"
            filled
            hide-details
            rounded
            dense
            placeholder="Stadt"
          ></v-text-field>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-on:click="$emit('close', true)"
          rounded
          depressed
          :ripple="false"
          text
        >
          <span class="ml-2 mr-2"> Abbrechen</span>
        </v-btn>
        <v-btn
          :loading="loadingCreation"
          v-on:click="createProject"
          depressed
          rounded
          :ripple="false"
          :disabled="this.projectInformation.name.length == 0"
          color="primary"
        >
          <span class="ml-2 mr-2"> Weiter</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
		loadingCreation: false, 
      projectInformation: {
        name: "",
        contact: "",
        addr: {
          street: "",
          zip: "",
          city: "",
        },
      },
    };
  },
	methods: {
		 async createProject() {
      this.loadingCreation = true;
		var user = await this.$firebase.user();

      try {
        var createdProject = await this.$api.authRequest().createProject({
          owner: user.email,
          name: this.projectInformation.name,
        });
		
		if (!createdProject || createdProject.error) {
			 this.showToast("Beim Erstellen des Projektes ist ein unerwarteter Fehler aufgetreten.", true, false);
			this.loadingCreation = false;
			return;
		}

      } catch (error) {
		  this.$emit('error', 'Beim Erstellen des Projektes ist ein unerwarteter Fehler aufgetreten. ')

      	this.loadingCreation = false;
		return;
      }

      //this.projectIdentifier = 'e0e9e565-42cf-4477-a96e-a879b0abdf69'//createdProject.id;
      this.loadingCreation = false;
      this.$emit('create', createdProject.id)
    },
	}
 

};
</script>