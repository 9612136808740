<template>
  <div
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "
  >
    <v-col align="center">
      <b>Oh, keine Kameras gefunden.</b>
      <br />
      <span class="font-weight-light"
        >Gehen Sie in die Einstellungen & fügen Sie Kameras hinzu.</span
      >
      <br />

      <v-btn v-on:click="$router.push({ name: 'camera' })" class="mt-5" rounded depressed color="primary" :ripple="false"
        ><v-icon class="mr-2">mdi-cog</v-icon>Einstellungen</v-btn
      >
    </v-col>
  </div>
</template>

<script>
export default {
  props: ["route"],
};
</script>