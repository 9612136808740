"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Api = void 0;
const api_requests_1 = require("./api-requests");
class Api extends api_requests_1.ApiRequests {
    constructor(url, projectId, token) {
        console.log("CONSTRUCTOR!", url);
        super(url, projectId, token);
    }
    // -----------------------------------------------------------------------------------------
    // --------------------------------------- USER AUTH --------------------------------------- 
    // -----------------------------------------------------------------------------------------
    forgot(userObject) {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][FORGOT]');
            return yield this.post(this.url.auth, 'forgot', userObject);
        });
    }
    projectUser() {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][PROJECTUSER]');
            return yield this.get(this.url.auth, `user/users/${this.projectId}`);
        });
    }
    // Inviting users; if the execution is successful, the link is returned as a string
    inviteUser(userObject) {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][INVITEUSER]');
            return yield (this.post(this.url.auth, `user/invite/${userObject.project}`, userObject));
        });
    }
    addProjectClaims(userObject) {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][ADDPROJECTCLAIMS]');
            return yield (this.post(this.url.auth, `user/join`, userObject));
        });
    }
    // -----------------------------------------------------------------------------------------
    // ---------------------------------------- PROJECT ---------------------------------------- 
    // -----------------------------------------------------------------------------------------
    getAllProjects() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield (this.get(this.url.project, `project`));
        });
    }
    createProject(projectObject) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('[API][CREATEPROJECT]');
            return yield (this.post(this.url.project, `project`, projectObject));
        });
    }
    getProjectCameras() {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][GETPROJECT]');
            if (!this.projectId)
                return { error: true };
            return yield (this.get(this.url.camera, `camera/project/${this.projectId}`));
        });
    }
    getProject() {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][GETPROJECT]');
            if (!this.projectId)
                return { error: true };
            return yield (this.get(this.url.project, `project/${this.projectId}`));
        });
    }
    deleteProject() {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][DELETEPROJECT]');
            if (!this.projectId)
                return { error: true };
            return yield (this.delete(this.url.project, `project/${this.projectId}`));
        });
    }
    getProjectSchedule() {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][GETSCHEDULE]');
            if (!this.projectId)
                return { error: true };
            return yield (this.get(this.url.project, `project/${this.projectId}/schedule`));
        });
    }
    getCameraSchedule(serialNumber) {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][CAMERASCHEDULE]');
            if (!this.projectId)
                return { error: true };
            return yield (this.get(this.url.camera, `camera/${serialNumber}/armingSchedule`));
        });
    }
    postProjectSchedule(projectSchedule) {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][POSTSCHEDULE]');
            if (!this.projectId)
                return { error: true };
            return yield (this.post(this.url.project, `project/${this.projectId}/schedule`, projectSchedule));
        });
    }
    removeCamera(serialNumber) {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][ADDCAMERA]');
            if (!this.projectId)
                return { error: true };
            return yield (this.put(this.url.camera, `camera/${serialNumber}/deregisterProject`, {}));
        });
    }
    addCamera(cameraObj) {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('[API][ADDCAMERA]');
            if (!this.projectId)
                return { error: true };
            var result = yield (this.post(this.url.camera, `camera/setupCamera`, cameraObj));
            if (result.error)
                return result;
            return yield (this.put(this.url.camera, `camera/${result.serialNumber}/registerProject/${this.projectId}`, cameraObj));
        });
    }
}
exports.Api = Api;
