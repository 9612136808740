<template>
<div>
  <v-tooltip
    :bottom="bottom"
    :top="top"
    :left="left"
    :right="right"
    :open-delay="delay"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :icon="icon"
        v-bind="attrs"
        v-on="on"
        v-on:click="$emit('click', true)"
		:href="link"
		:loading="loading"
		:ripple="false"
      >
        <v-icon :color="color">{{ iconName }}</v-icon>
      </v-btn>
    </template>
    <v-row align="center" justify="center" class="ma-0">
      <span>
        {{ tooltip }}
      </span>
      <div
	  	v-if="shortcut != undefined && shortcut.length > 0"
        style="
          background-color: white;
          width: 20px;
          border-radius: 5px;
          opacity: 0.5;
        "
        class="ml-3"
      >
        <v-row class="ma-0" justify="center">
          <span
            style="font-family: 'Courier New', monospace"
            class="black--text"
            >{{shortcut}}</span
          >
        </v-row>
      </div>
    </v-row>
  </v-tooltip>
  </div>
</template>

<script>
export default {
  props: [
	  "loading",
    "top",
    "bottom",
    "left",
    "right",
    "icon",
    "iconName",
    "delay",
    "tooltip",
    "shortcut",
	"link", 
	"color"
  ],
};
</script>

