<template>
  <div>
    <v-snackbar
      :style="fullscreen ? '' : 'padding-left: 256px'"
      style="opacity: 0.8 !important"
      :timeout="!critical ? (error ? 5000 : 2000) : null"
      elevation="3"
      top
      centered
      :color="error ? 'red' : 'white'"
      v-model="showSnackbar"
    >
      <v-icon
        style="
          position: absolute;
          left: 15px;
          top: calc(50% - 12px);
          opacity: 0.8;
        "
        class="mr-2 noselect"
        :class="error ? 'white--text' : 'black--text'"
      >
        {{ error ? "mdi-alert-circle-outline" : "mdi-check-circle-outline" }}
      </v-icon>
      <v-row class="ml-8 mt-1 mb-1 noselect font-weight-medium" align="center">
        <span :class="error ? 'white--text' : 'black--text'">{{
          message
        }}</span>
      </v-row>
      <v-row
        v-show="error && reload"
        class="ml-8 mt-1 mb-1 noselect"
        align="center"
      >
        <span class="" v-on:click="refreshPage" style="cursor: pointer"
          >Hier klicken, um die Seite neu zu laden.</span
        >
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          small
          text
          v-bind="attrs"
          icon
          :ripple="false"
          @click="showSnackbar = false"
        >
          <v-icon small :class="error ? 'white--text' : 'black--text'"
            >mdi-close</v-icon
          >
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["message", "fullscreen", "show", "error", "reload", "critical"],
  data() {
    return {
      showSnackbar: false,
      prevMessage: "",
    };
  },

  watch: {
    show() {
      if (this.message != "") {
        this.setSnackbar();
      }
    },
  },

  methods: {
    refreshPage() {
      window.location.reload();
    },

    setSnackbar() {
      if (this.showSnackbar != true) {
        this.showSnackbar = true;
      }
    },
  },
};
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>