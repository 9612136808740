import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
//import 'firebase/firestore';
import 'firebase/storage';
import crc32 from 'crc/crc32';

export default {

	install(Vue, firebaseCreds) {
		// initialize Firebase with required Credentials

		firebase.initializeApp(firebaseCreds);
		// if(window.location.hostname === 'localhost' && Vue.prototype.$project.dev) {
		//   // console.log("DEV!!")
		//   firebase.auth().useEmulator('http://localhost:9099/');

		// }
		let auth = firebase.auth();
		Vue.firebaseAuth = auth

		Vue.prototype.$firebase = {
			auth,

			async login(username, password) {

				try {

					// Login the user with the email address and password; the function throws an error if the login is not 
					// successful, but if it is successful it returns the user object
					let userCredential = await firebase.auth().signInWithEmailAndPassword(username, password)

					if (userCredential) {

						try {
							let claims = (await userCredential.user.getIdTokenResult()).claims

							if (claims.admin != undefined && claims.projects != undefined) {
								return { success: true, user: claims }

							} else {
								auth.signOut()
								return { success: false, message: 'Claim Fehler' }
							}

						} catch (err) {
							auth.signOut()
							return { success: false, message: 'Claim Fehler' }
						}
					}

				} catch (err) {

					let message = 'Ein interner Server Fehler ist aufgetreten.'

					switch (err.code) {
						case 'auth/wrong-password': message = 'Falsches Password'; break;
						case 'auth/internal-error': message = 'Interner Fehler ist aufgetreten'; break;
						case 'auth/user-not-found': message = 'Mail-Adresse existiert nicht'; break
						default: break;
					}

					auth.signOut()
					return { success: false, message: message }
				}

			},

			async register(mail, password) {

				firebase.auth().createUserWithEmailAndPassword(mail, password)
					.then((result) => {
						// console.log(result);
					}).catch((err) => {
						// console.log(err)
					});
			},

			async user() {
				return new Promise((resolve, reject) => {
					// console.log("1")
					let unsub = firebase.auth().onAuthStateChanged(function (user) {
						let userObject = {}
						unsub();
						// console.log("2")

						userObject.email = user.email
						userObject.emailVerified = user.emailVerified
						userObject.displayName = user.displayName
						userObject.claims = undefined
						// console.log("3")

						user.getIdTokenResult()
							.then(claims => {
								// console.log("4")
								userObject.claims = claims.claims

								resolve(userObject)
							})
							.catch(err => {
								reject(err)
							})
					});
				});
			},

			async refreshAccount() {
				await firebase.auth().currentUser.getIdToken(true)
			},


			async validResetLink(actionCode) {

				return auth.verifyPasswordResetCode(actionCode)
					.then((email) => {
						return { success: true, mail: email }
					})
					.catch(() => {
						return { success: false }
					})
			},

			async getStorageImageUrl(storageIdentifier, cameraIdentifier) {
				var storageRef = firebase.app().storage(storageIdentifier).ref(cameraIdentifier)
				return await storageRef.getDownloadURL()					
			},

			async getStorageImage(storageIdentifier, projectIdentifier, name) {

				var storageRef = firebase.app().storage(storageIdentifier).ref(`${projectIdentifier}/${name}`)
				return await storageRef.getDownloadURL()
			},

			async getStoragePresets(storageIdentifier, cameraIdentifier) {

				try {
					var storageMetaData = await firebase.app().storage(storageIdentifier).ref(cameraIdentifier).listAll()

					// console.log(storageMetaData)
					var presetList = []

					storageMetaData.prefixes.forEach(folder => {
						presetList.push(folder.name)
					});

					return presetList
				} catch (error) {
					return;
				}


			},


			async getStorage(storageIdentifier, projectIdentifier) {
				// console.log("GET STR")
				try {
					var sortDateMethod = function (date1, date2) {
						if (Date.parse(date1) > Date.parse(date2)) return 1;
						if (Date.parse(date1) < Date.parse(date2)) return -1;
						return 0;
					};

					var dataHandle = {}
					// console.log("1", storageIdentifier, projectIdentifier)
					// retrieve all storage Meta Data

					var storageMetaData = await firebase.app().storage(storageIdentifier).ref(projectIdentifier).listAll()


					// console.log("2")
					storageMetaData.items.forEach(async function (imageRef) {

						var dateObj = imageRef.name.split('T')
						var hourObj = dateObj[1].split(':')

						if (!dataHandle[dateObj[0]]) dataHandle[dateObj[0]] = {}
						if (!dataHandle[dateObj[0]][hourObj[0]]) dataHandle[dateObj[0]][hourObj[0]] = []

						imageRef.meta = await imageRef.getMetadata()

						dataHandle[dateObj[0]][hourObj[0]].push(imageRef)
					})

					// console.log(dataHandle)
					var sortedData = {}

					var sortedDateArray = Object.keys(dataHandle).sort(sortDateMethod)
					sortedDateArray.forEach(dateNameObj => {
						sortedData[dateNameObj] = {}
						var sortedTimeArray = Object.keys(dataHandle[dateNameObj]).sort(function (a, b) { return a - b });

						sortedTimeArray.forEach(timeHandleObj => {
							sortedData[dateNameObj][timeHandleObj] = []
							sortedData[dateNameObj][timeHandleObj] = (dataHandle[dateNameObj][timeHandleObj])
						});
					});

					return sortedData
				} catch (error) {
					return undefined
				}


			},

			async resetPassword(actionCode, password) {

				return auth.confirmPasswordReset(actionCode, password)
					.then(() => {
						return true
					}).catch(() => {
						return false
					});
			},
		}
	}
}
