<template>
    <div>

        <v-btn v-on:click="retrieveInvitations">UPDATE</v-btn>
        INVITATIONS!
        <div v-for="(invite, index) in invites" :key="index">
            <v-row align="center">
            {{invite.project}}
            <v-spacer/>
            <v-btn class="mr-2" v-on:click="toggleInvite(true, invite)">Annehmen</v-btn>
            <v-btn v-on:click="toggleInvite(false, invite)">Ablehnen</v-btn>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            invites: []
        }
    },

    created() {
        this.retrieveInvitations()
    },
    
    methods: {
        async retrieveInvitations() {
            
            this.invites = await this.$api.request().getInvitation()
            this.$emit("inviteAccept", true)
        },

        async toggleInvite(accept, invite) {
            
            let user = await this.$firebase.user()
            await this.$api.request().addProjectClaims({mail: user.email, project: invite.project, decline: !accept})
            this.refreshAccount()
        },

        async refreshAccount() {
            await this.$firebase.refreshAccount()
            this.retrieveInvitations()
        }
    }
}
</script>