<template>
	<div>
		<v-row class="ma-0 mt-8 mb-2" align="center">
		<div style="position: relative; height: 50px;" >
      <h1 class="font-weight-medium">{{title}}</h1>
	  <div style="position: absolute; bottom: 3px; background-color: var(--v-primary-base); width: 100%; height: 4px; border-radius: 2px"></div>
		</div>
	  <v-spacer />
	  <slot name="action"/>
      <!-- <CreateTimelapse v-on:created="createdNewTimelapse" v-on:errorloading="creationCriticalError"
	  	v-on:errorEvent="creationEventError"
	  /> -->
    </v-row>
    <span class="pt-3 font-weight-light"
      >{{subtitle}}
    </span>
    <v-divider class="mt-2 mb-2" />
	</div>
</template>

<script>
export default {
	props: ['title', 'subtitle']
}
</script>