<template>
  <div class="ma-4 pb-10" style="width: 100%">
    <div v-if="!loading && armingTimes != undefined">
      <v-row class="ma-0 mt-5" style="border-radius: 10px !important">
        <div style="width: 50px; margin-top: 57px">
          <div v-for="i in 24" :key="i" style="height: 25px">
            <v-row class="ma-0 mr-1" justify="end">
              <span style="font-size: 12px" class="grey--text"
                >{{ `0${i}`.slice(-2) }}:00</span
              >
            </v-row>
          </div>
        </div>
        <CalendarElement
          v-for="(dayTime, i) in Object.keys(armingTimes)"
          :key="i"
          v-on:change="timeChangeEvent"
          :admin="isAdmin && !readonly"
          :dayIdentifier="i"
          :timeblocks="armingTimes[dayTime].times"
          :isWeekday="i < 5"
          :first="i == 0"
          :last="i == Object.keys(armingTimes).length - 1"
          :weekday="weekdays[i]"
        />
      </v-row>

      <v-row align="center" class="ma-0 ml-12 mr-12">
        <div
          style="
            background-color: var(--v-primary-base);
            height: 10px;
            width: 35px;
            opacity: 0.9;
            border-radius: 5px;
          "
          class="mr-1"
        />
        Alarmzeiten

        <v-spacer />
		<div v-show="!camera">
        <span  class="font-weight-light mr-2">Letzte Aktualisierung:</span>
        {{ formateTimeLocal() }}
		</div>
      </v-row>
    </div>
  </div>
</template>
<script>
import CalendarElement from "@/components/Calendar/CalendarElement.vue";

export default {
  props: ["isAdmin", "readonly", "loading", "schedule", "save", "camera"],
  components: {
    CalendarElement,
  },

  data() {
    return {
      camerasSync: false,
      saving: false,
      weekdays: [
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
        "Sonntag",
      ],
      armingTimes: undefined,
    };
  },
  created() {
      console.log("CREATED BRAH!", this.schedule);
      this.armingTimes = this.formatTimeblocks(this.schedule);

      if (this.schedule.synchronized == false) {
        this.showToast("Die Alarmzeiten sind nicht mit den Kameras synchronisiert!", true);
      }
    },

  watch: {
    save: function () {
      this.saveArmingTimes();
    },

    

    loading: function () {

      if (!this.loading) {
        this.armingTimes = this.formatTimeblocks(this.schedule);

        if (this.schedule.synchronized == false) {
          this.showToast("Die Alarmzeiten sind nicht synchronisiert.", true);
        }
      }
    },
  },

  methods: {
    formateTimeLocal() {
      const event = new Date(this.schedule.lastSync);

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      return `${event.toLocaleDateString(
        "de-DE",
        options
      )} (${event.toLocaleTimeString("de-DE")} Uhr)`;
    },
    formatTimeblocks(scheduleData) {
      var armingSchedule = {};
	  console.log("FTB", scheduleData)

      try {
        if (scheduleData.error && scheduleData.status == 404) {
          armingSchedule = {
            arming: [],
            timelapse: [],
          };
        } else {
			if (!this.camera) {
				armingSchedule = {
            arming: scheduleData.__timeblocks__.filter(
              (i) => i.type == "arming"
            ),
            timelapse: scheduleData.__timeblocks__.filter(
              (i) => i.type == "timelapse"
            ),
          };
			} else {
				armingSchedule = scheduleData
			}
          
        }
      } catch (error) {
        this.showToast(
          "Beim Laden der Alarmzeiten ist ein Server Fehler aufgetreten.",
          true,
          true,
          true
        );
        return;
      }

      var armingTimes = {};

      try {
        for (let index = 0; index <= 6; index++) {
          armingTimes[index] = this.parseArmingTime(
            index,
            armingSchedule.arming
          );
        }
        return armingTimes;
      } catch {
        return this.showToast(
          "Beim Laden der Alarmzeiten ist ein Fehler aufgetreten.",
          true,
          true,
          true
        );
      }
    },

    parseArmingTime(dayWeek, armingTimes) {
      var parsedData = armingTimes.filter((i) => i.dayOfWeek == dayWeek);

      var armingObj = { disable: true, times: [] };
      if (parsedData.length > 0) {
        parsedData.forEach((time) => {
          if (time.startTime >= 0 && time.endTime <= 1440) {
            armingObj.times.push({
              start: time.startTime / 60,
              end: time.endTime / 60,
            });
          } else {
            return this.showToast(
              "Fehler bei Alarmzeit aufgetreten; nicht lesbar."
            );
          }
        });
      }

      return armingObj;
    },

    /**
     * With this function an information or error toast can be displayed
     * @param message Toast content message
     * @param error (optional, default: false) Boolean whether it is an error toast
     * @param reloadSuggestion (optional, default: false) Boolean whether a reload suggestion shall be made
     * @param critical (optional, default: false) Boolean whether error is critical (disable menu)
     */
    showToast(
      message,
      error = false,
      reloadSuggestion = false,
      critical = false
    ) {
      var errorData = {
        content: message,
        error: error,
        critical: critical,
        reload: reloadSuggestion,
      };
      this.$emit("error", errorData);
    },

    formatArming(times) {
      console.log("FORMAT", times);
      var timeArr = [];

      Object.keys(times).forEach((dayIdentifier) => {
        if (
          times[dayIdentifier] &&
          times[dayIdentifier].times &&
          times[dayIdentifier].times.length > 0
        ) {
          times[dayIdentifier].times.forEach((timeHandle) => {
            timeArr.push({
              dayOfWeek: dayIdentifier,
              startTime: parseInt(timeHandle.start * 60),
              endTime: parseInt((timeHandle.end == 0 ? 24 : timeHandle.end) * 60),
            });
          });
        }
      });

      return timeArr;
    },

    formatTimelapse(scheduleTimes) {
      var timelapseDebounceBuffer = 1;

      var timelapseArr = [];

      for (let index = 0; index < 7; index++) {
        var dayTimes = scheduleTimes.filter((i) => i.dayOfWeek == index);

        if (dayTimes.length == 0) {
          timelapseArr.push({
            dayOfWeek: index,
            startTime: 0,
            endTime: 1440,
          });
        } else {
          var sortedTimes = dayTimes.sort((a, b) => a.startTime - b.startTime);

          if (sortedTimes.length > 0) {
            sortedTimes.forEach((timeBlock, i) => {
              if (timeBlock.startTime != 0 && i == 0) {
                timelapseArr.push({
                  dayOfWeek: index,
                  startTime: 0,
                  endTime: parseInt(
                    timeBlock.startTime - timelapseDebounceBuffer
                  ),
                });
              }

              if (timeBlock.endTime != 1440 && i == sortedTimes.length - 1) {
                timelapseArr.push({
                  dayOfWeek: index,
                  startTime: parseInt(
                    timeBlock.endTime + timelapseDebounceBuffer
                  ),
                  endTime: 1440,
                });
              }

              if (
                i < sortedTimes.length - 1 &&
                timeBlock.endTime != sortedTimes[i + 1].startTime
              ) {
                timelapseArr.push({
                  dayOfWeek: index,
                  startTime: parseInt(
                    timeBlock.endTime + timelapseDebounceBuffer
                  ),
                  endTime: parseInt(
                    sortedTimes[i + 1].startTime - timelapseDebounceBuffer
                  ),
                });
              }
            });
          }
        }
      }
      return timelapseArr;
    },

    formatTimes() {
      console.log("formatTimes");
    },

    async saveArmingTimes() {
      this.saving = true;
      this.formatTimes();
      var scheduleTimes = this.formatArming(this.armingTimes);
      var timelapseTimes = this.formatTimelapse(scheduleTimes);
      console.log("MOIN", { arming: scheduleTimes, timelapse: timelapseTimes });
      this.saving = false;

      try {
       var resp = await this.$api.request().postProjectSchedule({
          arming: scheduleTimes,
          timelapse: timelapseTimes,
        });
		console.log(resp)
        this.$emit("saved", true);
      } catch (error) {
        this.showToast(
          "Beim Speichern der Daten ist ein unerwarteter Fehler aufgetreten. Bitte probieren Sie es noch einmal.",
          true
        );
        this.$emit("saved", false);
      }
    },

    timeChangeEvent(eventData) {
		console.log("TIME CHANGED", eventData)

      this.armingTimes[eventData.day].times = eventData.data;

      if (eventData.allWeekday) {
        for (let index = 0; index < 5; index++) {
          this.armingTimes[index].times = eventData.data;
        }
      }

      if (eventData.allWeekend) {
        for (let index = 5; index < 7; index++) {
          this.armingTimes[index].times = eventData.data;
        }
      }

      this.$emit("change", true);
    },
  },
};
</script>