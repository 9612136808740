<template>
    <div>
        SIGN UP
        <v-text-field v-model="mail" label="Mail-Adresse"/>
        <v-btn v-on:click="register()">REGISTERLOL</v-btn>

    </div>
</template>

<script>

export default {
    
    data() {
        return {
            mail: ''
        }
    },
    async created() {
        let content = this.$route.query
        
        try {
            if (content.mode == 'invite') {
               this.$router.push(`/login?invite=${content.project}&id=${content.id}`)
                
            } else if (content.mode == 'register') {
                console.log('register')

            } else {
                console.log("ks")
                this.$router.push('/login')
            }

        } catch (error) {
            this.$router.push('/login')
        }

        // try {
        //    if (this.$route.query.mode == 'invite') {
        //         var result = await this.$firebase.validRegisterLink(this.$route.query.link, this.$route.query.uid)
                
        //         if (this.$firebase.auth.currentUser.email != result) {
        //             console.log("EINLADUNGS MAIL PASST NICHT(!)")

        //         } else {
        //             this.$api.create(this.$route.query.project)

        //             var claimed = await this.$api.request().addProjectClaims({
        //                 mail: result, 
        //                 project: {[this.$route.query.project]: 'r'}
        //             })

        //             this.$api.delete()
                    
        //             if (!claimed || claimed.projects[this.$route.query.project] == undefined) {
        //                 if (claimed.project.length == 0) {
        //                     this.$router.push('/login')
        //                 }
            
        //                 this.$router.push(`project/${claimed.projects[Object.keys(claimed.projects)[0]]}`)

        //             } else {
        //                 console.log(this.$route.query.project)
        //                 this.$router.push(`project/${this.$route.query.project}`)
        //             }
                
        //         }
        //     } 
        // } catch (error) {
        //     this.$router.push('/oops')
        // }
        
    }, 

    methods: {

        async registerInvitation(mail) {
            console.log("REGISTER INVITE", mail)
        },

        async register() {
            
            await this.$firebase.register(this.mail, 'teste234234')
        },
    }
}
</script>