import { render, staticRenderFns } from "./projectOverview.vue?vue&type=template&id=4573eb26&scoped=true&"
import script from "./projectOverview.vue?vue&type=script&lang=js&"
export * from "./projectOverview.vue?vue&type=script&lang=js&"
import style0 from "./projectOverview.vue?vue&type=style&index=0&id=4573eb26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../baucam/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4573eb26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../baucam/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VContainer,VDialog,VDivider,VIcon,VImg,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VSpacer,VTooltip,VVirtualScroll})
