<template>
    <v-app-bar app dark color="primary" dense flat style="z-index: 1000">
      <div>{{ $route.meta.title }}</div>
      <v-spacer />
      
      <span v-show="($version != undefined && $version.length > 0)" class="font-weight-thin mr-3" style="opacity: .6; font-size: 13px">{{$version}}</span>
      
    </v-app-bar>
</template>

<script>
// import UserHandle from "@/components/ProjectPage/UserHandle.vue";

export default {

    // components: {
    //     UserHandle
    // }
};
</script>