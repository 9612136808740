<template>
<div>
	
 <!-- <v-chip class="mb-2" color="primary" :ripple="false" v-on:click="selectLatest()" :outlined="!latestDate">
	 <v-row align="center" class="ma-0" justify="center"><v-icon size="20" class="mr-1">mdi-calendar-blank</v-icon>Heute</v-row></v-chip>
 -->

<v-col class="ma-0" style="padding: 0px!important">
	<h3 class="font-weight-medium black--text ml-4">Datum </h3>
	<!-- <span class="ml-4">Bitte wählen Sie ein Datum aus.</span> -->
  <v-date-picker
	:first-day-of-week="1"
  	:min="$storage.minDate"
    :allowed-dates="allowedDates"
    :max="$storage.maxDate"
	:events="event"
    no-title
	color="primary"
    width="280"
    locale="de-de"
    v-model="pickerValue"
    v-on:change="changedDate"
  ></v-date-picker>
</v-col>
</div>
</template>

<script>
export default {
  props: ["allowed", "event", "input", "disablePrevious"],

  data() {
    return {
      pickerValue: new Date(Date.parse(this.input)).toISOString().substr(0, 10),
    };
  },

  watch: {
	  input() {
		  if (this.input != undefined) {
			  this.pickerValue = new Date(Date.parse(this.input)).toISOString().substr(0, 10)
		  }
	  },
  },

  methods: {
    changedDate() {
      this.$emit("change", this.pickerValue);
    },
    allowedDates(val) {
		
		if (this.disablePrevious && Date.parse(val) < Date.parse(this.event[0])) {
			return false
		} else {
			return this.$storage.allowedDates().indexOf(val) !== -1;
		}

      
    },
  },
};
</script>