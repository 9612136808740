<template>
  <div>
	  <ServerError v-if="criticalServerError">
	  </ServerError>
	  <div v-else>
      <div
        v-show="!showContent"
        style="
          height: 100vh;
          position: absolute;
          width: 100vw;
          background-color: white;
          z-index: 200;
        "
      >
        <div class="loading">
          <v-row align="center" justify="center">
			  Loading!
            <v-progress-circular
              v-show="!showContent"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </div>
      </div>


    <div>
      <fullscreen
        ref="fullscreen"
        @change="fullscreenChange"
        style="overflow: hidden !important"
      >
        <SettingsSidebar
          :opacity="true"
          title="Einstellungen"
          :loading="loadingMeta"
          beforeTitle="Vorher"
          afterTitle="X-Ray"
          :fullscreen="fullscreen"
          v-on:changedfirst="changedFirstImage"
          v-on:changedsecond="changedSecondImage"
          v-on:opacityChanged="opacityChangeHandle"
          v-on:fullscreen="toggleFullscreen"
          v-on:center="centerElement"
          :smallForward="forwardImage.small"
          v-on:loading="loadingImageEvent"
		  v-on:serverError="serverErrorEvent"
        />

        <div
          @contextmenu="show"
          id="relationContainer"
          :style="fullscreen ? 'height: 100vh;' : 'height: 100vh'"
          style="width: 100vw; background-color: #000000C5; overflow-y: hidden"
        >
          <v-img
            class="imagePosition nodrag noselect"
            id="image1"
            :src="imageHandle.one.url"
          />
          <v-img
            class="imagePosition nodrag noselect"
            id="image2"
            :style="'opacity:' + opacityValue"
            :src="imageHandle.two.url"
          />
          <vue-draggable-resizable
            id="draggableElement"
            :key="refreshPosition"
            class="noselect"
            class-name-handle="my-handle-class"
            :min-width="200"
            :min-height="200"
            :x="x"
            :y="y"
            :w="width"
            :h="height"
            @dragging="onDrag"
            @resizing="onResize"
            :parent="false"
            :resizable="true"
            :prevent-deactivation="true"
            style="border: 1px dashed white; padding-top: 165px; cursor: move"
            :onDragStart="onDragStartCallback"
          >
            <v-fade-transition>
              <v-overlay
                color="white"
                v-show="createdXray == true"
                :opacity="0.8"
                style="line-height: 15px"
              >
                <v-col class="ma-0" align="center">
                  <v-icon
                    class="rotating"
                    color="black"
                    style="opacity: 0.7"
                    size="45"
                    >mdi-cursor-move</v-icon
                  >
                  <br />
                  <span
                    class="font-width-light black--text"
                    style="font-size: 14px"
                    >Bewegen Sie dieses Fenster.</span
                  >
                </v-col>
              </v-overlay>
            </v-fade-transition>

            <!-- <v-fade-transition>
		<div v-show="opacityValue < 1 && !loadingMeta" class="white--text opacity-info-class noselect">{{"Transparenz: " + parseInt(opacityValue * 100) + "%"}}</div>
		</v-fade-transition> -->

            <v-fade-transition>
              <div
                v-show="formatDate && !loadingMeta && !createdXray"
                class="white--text date-info-class noselect"
              >
                {{ formatDate }}
              </div>
            </v-fade-transition>
          </vue-draggable-resizable>
		    <v-menu
          v-model="rightClick.show"
          :position-x="rightClick.x"
          :position-y="rightClick.y"
          absolute
          offset-y
          close-on-content-click
        >
          <v-list dense>
            <v-list-item v-on:click="downloadImage" :ripple="false">
				
              <v-list-item-title>     <v-icon size="18" class="black--text mr-1">mdi-download</v-icon>Bild herunterladen</v-list-item-title>
            </v-list-item>

			<v-list-item v-on:click="downloadImage" :ripple="false">
				
              <v-list-item-title>     <v-icon size="18" class="black--text mr-1">mdi-help-circle-outline</v-icon>Hilfestellung</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        </div>
      
      </fullscreen>
    </div>
	  </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueCompareImage from "vue-compare-image";
import SettingsSidebar from "@/components/SettingsSidebar.vue";
import ImageComponent from "@/components/ImageComponent.vue";
import ServerError from "../../../../packages/frontend/components/error/ServerError"
export default {
  components: {
    VueCompareImage,
    SettingsSidebar,
    ImageComponent,
	ServerError,
  },
  data() {
    return {
		criticalServerError: false,
      rightClick: {
        show: false,
        x: 0,
        y: 0,
      },
      createdXray: true,
      opacityValue: 1,
      width: 200,
      height: 200,
      x: 200,
      y: 200,
      fullscreen: false,

      loadingMeta: true,

      imageHandle: {
        one: {
          time: null,
          date: null,
          url: null,
        },
        two: {
          url: null,
          time: null,
          date: null,
        },
      },
      lastPosition: 1,
      showContent: false,
      refreshPosition: false,
      relImageSize: null,
      forwardImage: {
        small: true,
        medium: true,
        big: true,
      },
    };
  },

  mounted() {
    window.addEventListener("resize", this.windowResizeEvent);
  },

  destroyed() {
    window.removeEventListener("resize", this.windowResizeEvent);
  },

  computed: {
    formatDate() {
      var imageDate = new Date(this.imageHandle.two.date);
      const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      return imageDate.toLocaleDateString("de-DE", options);
    },
  },

  methods: {
	  async downloadImage() {
		  console.log("DOWNLOAD IMAGE")
	  },
    loadingImageEvent(status) {
      this.loadingMeta = status;
      this.createdXray = true;
      this.cropImage();
      setTimeout(() => {
        this.showContent = true;
        this.cropImage();
        this.relImageSize = document
          .getElementById("image2")
          .getBoundingClientRect();
        this.centerElement();
      }, 1000);
    },

    centerElement() {
      var imageHandleBounds = document
        .getElementById("image1")
        .getBoundingClientRect();

      this.x = imageHandleBounds.width / 2 - this.width / 2;
      this.y =
        imageHandleBounds.top + imageHandleBounds.height / 2 - this.height / 2;
     
      this.cropImage();
    },

    toggleForwardImage() {
      this.forwardImage.small = !this.forwardImage.small;
    },
    show(e) {
      e.preventDefault();
      this.rightClick.show = false;
      this.rightClick.x = e.clientX;
      this.rightClick.y = e.clientY;
      this.$nextTick(() => {
        this.rightClick.show = true;
      });
    },

    windowResizeEvent() {
      this.cropImage();
    },

    opacityChangeHandle(opacityValueNumb) {
      this.opacityValue = opacityValueNumb / 100;
    },

    cropImage() {
      var element = document.getElementById("image2");

      var offsetTop = element.getBoundingClientRect().top;
      if (!this.showContent) {
        this.x = 300;
        this.y = 300;
        this.width = 250;
        this.height = 250;
        element.style.clip = `rect(${this.y}px, ${this.width + this.x}px, ${
          this.height + this.y + 47
        }px, ${this.x}px)`;
        return;
      }
      if (this.fullscreen) {
        element.style.clip = `rect(${this.y - parseInt(offsetTop)}px, ${
          this.width + this.x
        }px, ${this.height + this.y - parseInt(offsetTop)}px, ${this.x}px)`;
      } else {
        element.style.clip = `rect(${this.y - parseInt(offsetTop)}px, ${
          this.width + this.x
        }px, ${this.height + this.y - parseInt(offsetTop)}px, ${
          this.x
        }px)`;
      }
    },
    onResize: function (x, y, width, height) {
      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;
      this.cropImage();
    },
    onDrag: function (x, y) {
      this.x = x;
      this.y = y;

      this.cropImage();
    },
    onDragStartCallback(ev) {
      if (this.createdXray) {
        this.createdXray = false;
      }
    },

    toggleFullscreen() {
      this.$refs["fullscreen"].toggle(); // recommended

      this.cropImage();
    },
    fullscreenChange(fullscreen) {
      var element = document.getElementById("image2");
      var newSize = element.getBoundingClientRect();

      this.height = this.height * (newSize.height / this.relImageSize.height);
      this.width = this.width * (newSize.width / this.relImageSize.width);

      this.x = this.x * (newSize.width / this.relImageSize.width);
      // this.y = this.y * ((newSize.bottom)/(this.relImageSize.bottom))  //??????

      this.fullscreen = fullscreen;

      this.relImageSize = newSize;
      this.refreshPosition = !this.refreshPosition;
      this.cropImage();
    },
	serverErrorEvent(eventData) {
		console.log("SERVER ERR EVENT")
		this.criticalServerError = true
	},

    changedFirstImage(imageObj) {
      this.imageHandle["one"] = imageObj;
    },

    changedSecondImage(imageObj) {
      this.imageHandle["two"] = imageObj;
      this.cropImage();
    },

    async setFirstImage(name) {
      try {
        this.images.left = await this.$firebase.getStorageImage(
          "gs://at-dev-test",
          "DS-2DE4425IW-DE20201127AAWRF16199234/test3",
          name
        );
      } catch (error) {
        console.log("ERROR OCCURED SETTING FIRST IMAGE");
      }
    },

    async setSecondImage(name) {
      try {
        this.images.right = await this.$firebase.getStorageImage(
          "gs://at-dev-test",
          "DS-2DE4425IW-DE20201127AAWRF16199234/test3",
          name
        );
      } catch (error) {
        console.log("ERROR OCCURED SETTING SECOND IMAGE");
      }
    },
  },
};
</script>

<style >
@keyframes rotating {
  0% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -ms-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
  }
  100% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.rotating {
  -webkit-animation: rotating 3s linear infinite;
  -moz-animation: rotating 3s linear infinite;
  -ms-animation: rotating 3s linear infinite;
  -o-animation: rotating 3s linear infinite;
  animation: rotating 3s linear infinite;
}

.imagePosition {
  position: absolute;
  top: 0px;
  left: 0px;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
}
.my-handle-class {
  z-index: 200;
  position: absolute;
  background-color: white;/*var(--v-primary-base);*/
  border: 2px solid white;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  opacity: 1;
  box-model: border-box;
  -webkit-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}

.my-handle-class-ml {
  top: 50%;
  margin-top: -7px;
  left: -6px;
  cursor: w-resize;
}

.my-handle-class-tm {
  top: -6px;
  left: 50%;
  margin-left: -7px;
  cursor: n-resize;
}

.my-handle-class-mr {
  top: 50%;
  margin-top: -7px;
  right: -6px;
  cursor: e-resize;
}

.my-handle-class-bm {
  bottom: -6px;
  left: 50%;
  margin-left: -7px;
  cursor: s-resize;
}

.my-handle-class-tl {
  top: -5px;
  left: -5px;
  cursor: nw-resize;
}

.my-handle-class-tr {
  top: -5px;
  right: -5px;
  cursor: ne-resize;
}

.my-handle-class-bl {
  bottom: -5px;
  left: -5px;
  cursor: sw-resize;
}

.my-handle-class-br {
  bottom: -5px;
  right: -5px;
  cursor: se-resize;
}

.my-handle-class-tl:hover,
.my-handle-class-tl:active,
.my-handle-class-tr:hover,
.my-handle-class-tr:active,
.my-handle-class-bl:hover,
.my-handle-class-bl:active,
.my-handle-class-br:hover,
.my-handle-class-br:active,
.my-handle-class-tm:hover,
.my-handle-class-tm:active,
.my-handle-class-ml:hover,
.my-handle-class-ml:active,
.my-handle-class-mr:hover,
.my-handle-class-mr:active,
.my-handle-class-bm:hover,
.my-handle-class-bm:active {
  opacity: 1;
  transform: scale(1.4);
}

.date-info-class {
  border-top-right-radius: 5px;
  opacity: 0.7;
  position: absolute;
  bottom: 0px;
  border-top: 1px solid black;
  background-color: var(--v-primary-base);
  border-right: 1px solid black;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 13px;
}
.opacity-info-class {
  border-bottom-right-radius: 5px;
  opacity: 0.7;
  position: absolute;
  top: 0px;
  border-bottom: 1px solid black;
  background-color: var(--v-primary-base);
  border-right: 1px solid black;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 13px;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.nodrag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px; /*set to a negative number 1/2 of your height*/
  margin-left: -25px; /*set to a negative number 1/2 of your width*/
}
</style>