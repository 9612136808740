<template>
  <div>
    <div
      style="
        height: 50px;
        width: 100%;
        position: absolute;
        background-color: white;
        z-index: 200;
        border-radius: 10px;
      "
    >
      <v-row
        align="center"
        class="ma-0"
        style="padding-top: 6px; margin-bottom: 6px"
      >
        <span class="ml-5 font-weight-bold" style="font-size: 17px">
          {{ camera.name }}</span
        >

        <TooltipButton
          class="ml-2"
          right
          iconName="mdi-cog"
          icon
          v-on:click="$router.push({ name: 'camera' })"
          delay="1000"
          tooltip="Kamera-Einstellungen"
        />
        <v-spacer />
		<div v-if="camera != undefined && camera.presets != undefined && Object.keys(camera.presets).length > 0">
        <v-menu
          top
          close-on-content-click
          :disabled="camera.presets.length == 1"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="select-preset" v-bind="attrs" v-on="on">
              <span class="select-preset-text">
                <v-row>
                  Ausrichtung:
                  <b class="ml-1">{{ cropPresetName(currPreset) }}</b>
                </v-row>
              </span>
              <v-icon
                v-if="camera.presets.length > 1"
                style="position: absolute; margin-top: 6px; right: 10px"
                >mdi-chevron-down</v-icon
              >
            </div>
          </template>

          <v-list>
            <v-list-item
              :ripple="false"
              :disabled="currPreset == item"
              v-on:click="retrieveAllFiles(item)"
              v-for="(item, index) in camera.presets"
              :key="index"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
		</div>

        <v-btn disabled class="mr-4 ml-4" icon :ripple="false"
          ><v-icon>mdi-folder-zip</v-icon></v-btn
        >
      </v-row>
      <v-divider class="pt-4 mt-2" />
    </div>

    <div v-if="!loadingData || loading">
      <div v-if="!files" style="height: 700px">
        <v-col align="center" class="center-element">
          <v-icon class="pb-1" size="60">mdi-file-remove-outline</v-icon>
          <br />
          <span class="font-weight-light"> Keine Bilder vorhanden</span>
        </v-col>
      </div>
      <div v-else>
        <v-list
          style="
            position: absolute;
            margin-top: 50px;
            width: 100%;
            max-height: calc(100% - 50px);
            overflow-y: auto !important;
            border-bottom-right-radius: 10px;
          "
          dense
          subheader
          two-line
        >
          <div v-for="(dateFiles, index) in files" :key="index">
            <v-subheader
              class="pl-4 white--text primary font-weight-bold"
              style="font-size: 15px"
              >{{ formatTimestamp(index) }}

              <v-spacer />
              <v-tooltip bottom open-delay="1000">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    depressed
                    text
                    :ripple="false"
                  >
                    <v-icon color="white" style="opacity: 0.95">
                      mdi-folder-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>Alle Bilder vom {{ index }} herunterladen</span>
              </v-tooltip>
            </v-subheader>

            <template v-for="(file, i) in dateFiles">
              <v-list-item
                :ripple="false"
                :key="i"
                dense
                class="list-item-style"
                v-on:click="showImage(i, index)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    ><span style="font-size: 16px">{{
                      i
                    }}</span></v-list-item-title
                  >

                  <v-list-item-subtitle>{{
                    fileType(file[0])
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-row class="ma-0" align="center">
                    <span class="mr-10 grey--text">{{ formatTime(i) }}</span>
                    <span class="mr-15 grey--text">{{
                      bytesToSize(file[0])
                    }}</span>
                    <v-btn icon :ripple="false">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </template>
          </div>
        </v-list>
      </div>
    </div>
    <div v-else style="height: 700px">
      <v-col align="center" class="center-element">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </div>

    <v-dialog v-model="showPreviewImage.show" width="1200">
      <v-card style="width: 1200px; min-height: 500px">
        <v-btn
          v-on:click="showPreviewImage.show = false"
          class="dialog-close-button"
          rounded
          depressed
          :ripple="false"
          color="primary"
          ><v-icon>mdi-close</v-icon>schliessen</v-btn
        >
        <v-img :src="showPreviewImage.url"></v-img
      ></v-card>
    </v-dialog>
  </div>
</template>

<script>
import TooltipButton from "../../../../packages/frontend/components/TooltipButton.vue";

export default {
  props: ["camera", "loading"],
  components: {
    TooltipButton,
  },
  data() {
    return {
      showPreviewImage: {
        show: false,
        url: undefined,
      },
      files: {},
      loadingData: false,
      currPreset: undefined,
    };
  },
  created() {
    this.retrieveAllFiles(this.camera.presets[0]);
  },

  watch: {
    camera: function () {
      this.retrieveAllFiles(this.camera.presets[0]);
    },
  },

  methods: {
    async showImage(fileName, date) {
      try {
        var url = await this.$firebase.getStorageImageUrl(
          "gs://alinosense.appspot.com",
          `raw/${this.camera.serialNumber}/${this.currPreset}/${date}T${fileName}`
        );
		
        if (!url) {
          this.$emit(
            "error",
            "Beim Laden des Bildes ist ein Fehler aufgetreten."
          );
        }
      } catch (error) {
        this.$emit(
          "error",
          "Beim Laden des Bildes ist ein Fehler aufgetreten."
        );
      }

        this.showPreviewImage.url = url;
        this.showPreviewImage.show = true;
    },

    async retrieveAllFiles(preset) {
     
      this.currPreset = preset;
      this.loadingData = true;

      var storageData = await this.$firebase.getStorage(
        "gs://alinosense.appspot.com",
        `raw/${this.camera.serialNumber}/${preset}`
      );

      if (!storageData || Object.keys(storageData).length == 0) {
        this.files = undefined;
      } else {
        this.files = storageData;
      }

      setTimeout(() => {
        this.loadingData = false;
      }, 500);
    },


    cropPresetName(name) {
      if (name.length > 13) {
        return `${name.substring(0, 13)}...`;
      } else {
        return name;
      }
    },
    formatTime(timeObj) {
      var splitTime = timeObj.split(".")[0].split("-");
      return `${splitTime[0]}:${splitTime[1]}.${splitTime[2]} Uhr`;
    },

    fileType(fileObj) {
      if (!fileObj || !fileObj.meta || !fileObj.meta.contentType) return "-";
      return fileObj.meta.contentType;
    },
    bytesToSize(fileObj) {
      if (!fileObj || !fileObj.meta || !fileObj.meta.size) return "-";
      var bytes = fileObj.meta.size;
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    formatTimestamp(time) {
      const event = new Date(time);

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      return event.toLocaleDateString("de-DE", options);
    },
  },
};
</script>

<style scoped>
.select-preset {
  position: relative;
  cursor: pointer;
  height: 35px;
  width: 250px;
  background-color: rgba(128, 128, 128, 0.2);
  border-radius: 20px;
  transition-duration: 250ms;
}
.select-preset:hover {
  background-color: rgba(128, 128, 128, 0.3);
  transition-duration: 250ms;
}

.select-preset-text {
  position: absolute;
  font-size: 15px;
  top: 54%;
  left: 50%;
  width: 250px;
  margin-left: 30px;
  transform: translate(-50%, -50%);
}

.dialog-close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 250;
}
.center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

