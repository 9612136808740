<template>
	<v-card>
        <v-card-title class="headline">
          Alarmzeiten einstellen <span class="ml-2 font-weight-light">({{weekday}})</span>
        </v-card-title>

        <v-card-text>
          <v-row class="ma-0">
            <v-btn
              :color="settingTimeOption == 0 ? 'primary' : ''"
              v-on:click="settingTimeOption = 0"
              depressed
              tile
              :ripple="false"
              style="
                width: 50%;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
              "
              >Arbeitszeiten</v-btn
            >
            <v-btn
              :color="settingTimeOption == 1 ? 'primary' : ''"
              v-on:click="settingTimeOption = 1"
              depressed
              tile
              :ripple="false"
              style="
                width: 50%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              "
              >Alarmblöcke</v-btn
            >
          </v-row>
          <v-checkbox
            :disabled="checkboxHandle.allDay"
            v-model="checkboxHandle.disable"
            :ripple="false"
            :label="`Sicherung komplett deaktivieren`"
            hide-details
            dense
          ></v-checkbox>
		  <v-checkbox
              style="padding-top: 0px !important"
              :disabled="checkboxHandle.disable"
              v-model="checkboxHandle.allDay"
              :ripple="false"
              :label="`Ganzen Tag absichern (24h)`"
              hide-details
              dense
            ></v-checkbox>

          <div v-if="settingTimeOption == 0">
        
            <div style="position: relative; height: 90px" class="mt-5">
              <v-fade-transition>
                <div
                  v-show="checkboxHandle.disable || checkboxHandle.allDay"
                  style="
                    position: absolute;
                    width: 340px;
                    height: 65px;
                    background-color: white;
                    opacity: 0.9;
                    z-index: 100;
                  "
                />
              </v-fade-transition>
              <span class="black--text"><b>Arbeitszeiten:</b></span>
              <v-row align="center" class="ma-0">
                <v-text-field
                  v-model="workTimes.start"
                  type="time"
                  color="primary"
                  rounded
                  filled
                  hide-details
                  dense
                  style="max-width: 150px"
                ></v-text-field>
                <span class="mr-2 ml-2">bis</span>
                <v-text-field
                  v-model="workTimes.end"
                  type="time"
                  color="primary"
                  rounded
                  filled
                  hide-details
                  dense
                  style="max-width: 150px"
                ></v-text-field>
                <v-fade-transition>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon

                        v-bind="attrs"
                        v-on="on"
                        v-show="workTimesError"
                        class="ml-2"
                        color="primary"
                        >mdi-alert-circle</v-icon
                      >
                    </template>
                    <span v-if="parseInt(workTimes.start) > parseInt(workTimes.end)"
                      >Die Endzeit darf nicht vor der Startzeit liegen.</span
                    >
                    <span v-else
                      >Die Arbeitszeit muss mindestens eine Minute lang
                      sein.</span
                    >
                  </v-tooltip>
                </v-fade-transition>
              </v-row>
            </div>
          </div>
          <div class="mt-5" v-else>
            <div
              class="time-block-overview"
              v-for="(slot, i) in time"
              :key="'time' + i"
			  :style="checkboxHandle.disable || checkboxHandle.allDay ? 'opacity: .5 ;background-color: rgb(190, 190, 190)' : ''"
            >
              <v-hover v-slot="{ hover }" :disabled="checkboxHandle.disable || checkboxHandle.allDay">
                <div style="width: 100%; height: 100%" >
                  <span class="time-block-overview-text">
                    Zeitblock {{ `0${i + 1}`.slice(-2) }}:
                    <b class="ml-2">{{ parseFloatTime(slot.start) }} Uhr</b> bis
                    <b>{{ parseFloatTime(slot.end) }} Uhr</b>
                    {{
                      parseFloatTime(slot.start) > parseFloatTime(slot.end)
                        ? "(+ 1 Tag)"
                        : ""
                    }}</span
                  >

                  <v-btn
                    v-on:click="deleteTimeblock(slot)"
                    class="time-block-overview-delete"
                    v-show="hover"
                    :ripple="false"
                    icon
                    small
                    ><v-icon size="18">mdi-delete</v-icon></v-btn
                  >
                </div>
              </v-hover>
            </div>
            <v-btn
			:disabled="checkboxHandle.disable || checkboxHandle.allDay"
              class="mb-1"
              :ripple="false"
              depressed
              block
              v-on:click="addTimeBlock.show = !addTimeBlock.show"
              :class="{ active: addTimeBlock.show }"
              ><v-icon class="mr-2">mdi-plus</v-icon>Alarmblock
              hinzufügen</v-btn
            >

            <v-expand-transition>
              <v-card
                v-show="addTimeBlock.show"
                height="150"
                width="100%"
                class="mx-auto secondary"
              >
                <v-card-text>
                  Geben Sie die Start und Endzeit des Zeitblocks an.
                  <v-row align="center" class="ma-0 mt-2">
                    <v-text-field
                      v-model="addTimeBlock.start"
                      type="time"
                      color="primary"
                      rounded
                      filled
                      hide-details
                      dense
                      style="max-width: 150px"
                    ></v-text-field>
                    <span class="mr-2 ml-2">bis</span>
                    <v-text-field
                      v-model="addTimeBlock.end"
                      type="time"
                      color="primary"
                      rounded
                      filled
                      hide-details
                      dense
                      style="max-width: 150px"
                    ></v-text-field>
                    <v-fade-transition>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-show="timeBlockCollisions"
                            class="ml-2"
                            color="primary"
                            >mdi-alert-circle</v-icon
                          >
                        </template>
                        Überlappung mit anderen Zeitblock
                      </v-tooltip>
                    </v-fade-transition>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    v-on:click="addTimeBlock.show = false"
                    rounded
                    color="primary"
                    small
                    text
                    class="mr-2"
                    depressed
                    :ripple="false"
                    >schließen</v-btn
                  >
                  <v-btn
                    v-on:click="pushTimeblock()"
                    rounded
                    :disabled="timeBlockCollisions"
                    color="primary"
                    small
                    depressed
                    :ripple="false"
                    >hinzufügen</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </div>
		  <v-divider class="mt-5 mb-2" />
          <div>
            <span class="black--text"><b>Erweiterte Einstellungen:</b></span>
            <v-checkbox
              dense
              v-model="checkboxHandle.week"
              :ripple="false"
              :label="`${
                isWeekday
                  ? 'Für jeden Wochentag festlegen.'
                  : 'Für das ganze Wochenende festlegen.'
              }`"
              hide-details
              :disabled="checkboxHandle.wholeWeek"
              style="padding: 0px !important"
            ></v-checkbox>
            <v-checkbox
              dense
              :disabled="checkboxHandle.week"
              v-model="checkboxHandle.wholeWeek"
              style="padding: 0px !important"
              :ripple="false"
              :label="`Für alle Tage festlegen.`"
              hide-details
            ></v-checkbox>
          </div>
        </v-card-text>
		
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            text
            rounded
            color="primary"
            :ripple="false"
            @click="$emit('close', true)"
            class="pl-3 pr-3"
          >
            Abbrechen
          </v-btn>

          <v-btn
            rounded
            depressed
            color="primary"
            :ripple="false"
            @click="closeEditDialog"
            class="pl-3 pr-3"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
</template>


<script>
import CalendarChangeDialog from '@/components/Calendar/CalendarChangeDialog.vue'
export default {
  props: [
    "timeblocks",
    "isWeekday", 
	"weekday"
  ],
  components: {
	  CalendarChangeDialog
  },

  data() {
    return {
		checkboxHandle: {
			allDay: false,
			wholeWeek: false,
			week: false,
			disable: false
		},
      time: [],
      addTimeBlock: {
        show: false,
        start: "02:00:00",
        end: "04:00:00",
      },
      settingTimeOption: 0,


		workTimes: {
			start: "06:30:00",
			end: "18:30:00"
		},


    };
  },

  watch: {
    timeblocks() {
      this.time = JSON.parse(JSON.stringify(this.timeblocks));
	  
	  if (this.time.length == 0) {
		this.checkboxHandle.disable = true
	}
	else if (this.time.length == 2) {
		  this.workTimes.start = this.parseFloatTime(this.time[0].end)
		  this.workTimes.end = this.parseFloatTime(this.time[1].start)
	  } else {
		  this.settingTimeOption = 1
	  }
    },
  },
  created() {
    this.time = JSON.parse(JSON.stringify(this.timeblocks));
	
	if (this.time.length == 0) {
		this.checkboxHandle.disable = true
	}
	else if (this.time.length == 2) {
		  this.workTimes.start = this.parseFloatTime(this.time[0].end)
		  this.workTimes.end = this.parseFloatTime(this.time[1].start)
	  } else {
		  this.settingTimeOption = 1
	  }
  },

  methods: {
    pushTimeblock() {
      this.time.push({
        start: this.parseTime(this.addTimeBlock.start),
        end: this.parseTime(this.addTimeBlock.end),
      });
      this.addTimeBlock = {
        show: false,
        start: "02:00:00",
        end: "04:00:00",
      };
    },
    deleteTimeblock(timeblock) {
      try {
        this.time.splice(this.time.indexOf(timeblock), 1);
      } catch (error) {
        this.$emit(
          "error",
          "Beim Löschen des Zeitblocks ist ein Fehler aufgetreten."
        );
      }
    },
	
    parseFloatTime(time) {
      var hour = parseInt(time);
      var min = parseInt(60 * (time - hour));
      return `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)}`;
    },

    parseTime(time) {
      var tempTime = time.split(":");
      return parseFloat(
        tempTime[0] + "." + Math.ceil(10000 / (60 / tempTime[1]))
      );
    },

    closeEditDialog() {
		
      if (this.settingTimeOption == 0) {
        this.time = [
			{
				start: 0, 
				end: this.parseTime(this.workTimes.start)
			},
			{
				start: this.parseTime(this.workTimes.end), 
				end: 24
			}
		]
      }
      var resultTimeblock = [];

	if (this.checkboxHandle.disable) {
     } else if (this.checkboxHandle.allDay) {
        resultTimeblock.push({ start: 0, end: 24 });
        this.time = resultTimeblock;
      } else {
        resultTimeblock = this.time;
      }

      this.$emit("save", {
		  time: resultTimeblock, 
		  checkbox: this.checkboxHandle
	  });


	  this.$emit('close', true)
		// EMIT!
    },
	
  },
  computed: {
	  workTimesError() {
		  if (!this.checkboxHandle.disable && !this.checkboxHandle.allDay &&
		  (this.parseTime(this.workTimes.start) >= this.parseTime(this.workTimes.end))) {
			  return true
		  } else {
			  return false
		  }           
	  },
    timeBlockCollisions() {
      var overlap = false;
      var blockStart = this.parseTime(this.addTimeBlock.start);
      var blockEnd = this.parseTime(this.addTimeBlock.end);

      this.time.forEach((time) => {
        if (
          blockStart < blockEnd &&
          ((blockStart < time.start && blockEnd > time.end) ||
            (blockStart >= time.start && blockEnd <= time.end) ||
            (blockStart < time.start && blockEnd > time.end) ||
            (blockStart < time.start && blockEnd > time.start))
        ) {
          overlap = true;
        } else if (
          blockStart > blockEnd &&
          (blockStart < time.start || blockStart < time.end)
        ) {
          overlap = true;
        }
      });
      return overlap;
    },
  },
};
</script>
<style scoped>

.time-block-overview {
  cursor: default;
  position: relative;
  height: 40px;
  width: 100%;
  background-color: rgba(245, 245, 245, 1);
  margin-top: 1px;
  margin-bottom: 1px;
  border-radius: 5px;
}
.time-block-overview-text {
  position: absolute;
  top: 50%;
  font-size: 15px;
  transform: translateY(-50%);
  left: 15px;
}

.time-block-overview-delete {
  position: absolute;
  top: 50%;
  font-size: 15px;
  transform: translateY(-50%);
  right: 15px;
}

.v-btn.active .v-icon {
  transform: rotate(-45deg);
}
</style>