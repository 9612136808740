<template>
  <div>
    <v-row class="ma-0 mb-2" justify="center"
      ><span
        style="
          padding-bottom: 2px;
          border-bottom: 3px solid var(--v-primary-base);
          font-size: 25px;
        "
        >{{title}}</span
      >
    </v-row>
    <v-row class="ma-3 mb-6" justify="center">
      <span style="text-align: center">
        {{subtitle}}
      </span>
    </v-row>
  </div>
</template>

<script>
export default {
	props: ['title', 'subtitle']
}
</script>