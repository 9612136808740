<template>
  <div>
    <!-- List of parent Navigation pages -->
    <v-list rounded nav dense light>
      <v-list-item-group color="primary" :value="currentPageId" mandatory>

        <template>

          <!-- Iteration over all pages to display in navigation sidebar -->
          <div v-for="page in routes" :key="page.title">
            <v-list-item
              v-on:click="forward(page.route)"
              :ripple="false"
              v-show="!page.admin || (page.admin && admin)"
            >

              <!-- Route icon; as declared in vue-router -->
              <v-list-item-icon class="elementIcon">
                <v-icon v-text="page.icon" />
              </v-list-item-icon>

              <!-- Route Title; as declared in vue-router -->
              <v-list-item-title>
                <span class="elementTitle">{{page.title}}</span>
              </v-list-item-title>
            </v-list-item>

            <!-- Expansion of subpages; only if subpages are existend -->
            <v-expand-transition>
              <div
                class="subpagePanel"
                v-show="page.sub.length > 0 && page.id === currentPageId"
              >

              <!-- Subpage List -->
                <v-list flat>
                  <v-list-item-group
                    color="primary"
                    mandatory
                    :value="currentSubPageId"
                  >
                    <v-list-item
                      v-on:click="forward(subPage.route)"
                      :ripple="false"
                      v-for="(subPage, index) in page.sub"
                      :key="index"
                      v-show="!subPage.admin || (subPage.admin && admin)"
                    >

                    <!-- Subpage Title; no icons in subpages -->
                      <v-list-item-title>
                        <span :class="currentSubPageId === index ? 'subpageSelected' : ''">
                            {{subPage.title}}
                        </span>
                      </v-list-item-title>
                    </v-list-item>

                  </v-list-item-group>
                </v-list>
              </div>
            </v-expand-transition>

          </div>
        </template>

      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>

/*  
    Component for the navigation sidebar, the selectable list. The main routes are displayed with icons; 
    the subpages are displayed as expansion panels as a list without icons
*/

export default {
  name: 'ListSidebar',

  props: {

    // admin
    admin: {
      type: [Boolean],
      required: false
    }, 
  },
  
  data() {
    return {
      userProjects: [],
      currentPageId: null,
      currentSubPageId: null,
    };
  },

  computed: {
    routes() {
      return this.$router.options.routes
        .find((routeObj) => routeObj.name === "project")
        .children.filter((route) => !route.meta.hideInDrawer)
        .map((routeHandle, index) => ({
          id: index,
          route: routeHandle.name,
          title: routeHandle.meta.title,
          icon: routeHandle.meta.icon,
          admin: routeHandle.meta.admin,
          subRoute: routeHandle.children ? routeHandle.path + "/" : null,
          sub: (routeHandle.children || []).map((subRoute, subIndex) => ({
            id: subIndex,
            title: subRoute.meta.title,
            admin: subRoute.meta.admin,
            route: subRoute.name,
          })),
        }));
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        let mainRoute = this.routes.find(
          (pageObj) => pageObj.route === this.$route.name
        );
        if (mainRoute) {
          this.currentPageId = mainRoute.id;
          this.currentSubPageId = null;
        } else {
          let subRoute = this.routes.find((page) =>
            page.sub.some((p) => p.route === this.$route.name)
          );
          if (subRoute) {
            this.currentPageId = subRoute.id;
            this.currentSubPageId = subRoute.sub.find(
              (p) => p.route === this.$route.name
            ).id;
          } else {
            this.currentPageId = null;
            this.currentSubPageId = null;
          }
        }
      },
    },
  },
  methods: {
    forward(routeName) {
      if (
        this.routes[this.currentPageId] &&
        routeName == this.routes[this.currentPageId].route
      )
        return;
      if (this.$route.name == routeName) return;
      this.$router.push({ name: routeName, params: this.$route.params });
    },
  },
};
</script>

<style scoped>

.elementIcon {
  margin-left: 8px
}

.elementTitle {
  font-weight: bold;
}

.subpagePanel {
  margin-left: 65px
}

.subpageSelected {
}
</style>