<template>
  <div>
    <AddScrollerTemplate title="Nutzer hinzufügen" :items="invitations">
      <template v-slot:add-action>
        <v-fade-transition>
			<!-- :disabled="addInviteLoading" -->
          <v-btn

            v-show="invitations.length > 0"
            v-on:click="addInvite = !addInvite"
            :class="{ active: addInvite }"
            small
            depressed
            disabled
            rounded
            color="primary"
            :ripple="false"
            ><v-icon class="mr-2">mdi-plus</v-icon>Nutzer einladen</v-btn
          >
        </v-fade-transition>
      </template>

      <template v-slot:expand-area>
        <InviteNewUser
          v-on:invite="inviteEvent"
          v-on:loading="addInviteLoading = true"
          v-on:closeInvite="addInvite = false"
          :projectIdentifier="projectIdentifier"
          :expand="addInvite"
        />
      </template>

      <template v-slot:alternative>
		  Funktion nicht verfügbar.
		  <br>
		  <!--  :disabled="addInviteLoading" -->
        <v-btn
		disabled
          v-on:click="addInvite = !addInvite"
          small
          depressed
          outlined
         
          rounded
          color="primary"
          :ripple="false"
          ><v-icon class="mr-2">mdi-plus</v-icon>Nutzer einladen</v-btn
        >
      </template>

      <template v-slot="{ item }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.mail }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span style="font-size: 13px">Einladung gesendet</span>
            </v-list-item-subtitle> </v-list-item-content
          ><v-list-item-action>
            <div class="user-select-existing-readonly mr-2">
              <div>
                <span class="role-name-readonly black--text">
                  <span>{{ item.right }}</span>
                </span>
              </div>
            </div>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>
      </template>
      <template v-slot:actions>
        <v-spacer />
        <v-btn
          v-on:click="$emit('close', true)"
          rounded
          depressed
          :ripple="false"
          text
        >
          <span class="ml-2 mr-2"> Abbrechen</span>
        </v-btn>
        <v-btn
          v-on:click="$router.push(`/project/${projectIdentifier}`)"
          depressed
          rounded
          :ripple="false"
          :disabled="addInvite"
          color="primary"
        >
          <span class="ml-2 mr-2"> Abschließen</span>
        </v-btn>
      </template>
    </AddScrollerTemplate>
  </div>
</template>

<script>
import InviteNewUser from "../invitation/InviteNewUser";
import AddScrollerTemplate from "./AddScrollerTemplate";
export default {
  props: ["projectIdentifier"],
  components: {
    AddScrollerTemplate,
    InviteNewUser,
  },

  data() {
    return {
      addInviteLoading: false,
      addInvite: false,
      invitations: [],
    };
  },

  methods: {
    inviteEvent(inviteData) {
      this.addInviteLoading = false;
      this.invitations.push(inviteData);
    },
  },
};
</script>


<style scoped>
.role-name-readonly {
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  left: 50%;
}

.user-select-existing-readonly {
  background-color: #f0f0f0;
  border-radius: 20px;
  margin-left: 10px;
  width: 130px;
  position: absolute;
  height: 40px;
  top: 0px;
  right: 0px;
  cursor: default;
  margin-top: 10px;
}
</style>