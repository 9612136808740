<template>
  <div class="noselect mt-3">
    <v-col class="ma-0" style="padding: 0px !important">
      <h3 class="font-weight-medium black--text ml-4">Uhrzeit</h3>
      <v-row class="ma-0 ml-5">
        <div v-for="(n, i) in 24" :key="n">
          <v-badge
            bottom
            left
            overlap
            :value="event.time == i"
            dot
            offset-x="21"
            color="orange"
            offset-y="7"
          >
            <v-btn
              :disabled="checkExistence(i)"
              :ripple="false"
              height="32"
              width="32"
              style="padding: 0 !important; font-size: 12 !important"
              class="mr-2 mt-2 font-weight-medium"
              :class="
                selectedHour == i
                  ? 'primary white--text'
                  : 'transparent black--text'
              "
              icon
              small
              v-on:click="changeHour(i)"
              ><span style="font-size: 12 !important">{{ i }}</span></v-btn
            >
          </v-badge>
        </div>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {
  props: ["datetime", "event", "disablePrevious"],
  data() {
    return {
      selectedHour: 12,
	  lastSubmitted: {
		  time: null, 
		  date: null
	  }
    };
  },

  created() {
    if (this.datetime != undefined) {
      this.selectedHour = this.datetime.time;
    }
  },
  watch: {
	  event() {
		  console.log("CE: ", this.event)
	  },
    datetime: {
      handler() {
        if (this.datetime != undefined) {
          this.selectedHour = this.datetime.time;
		  this.checkSelectedFit()
        }
      },
      deep: true,
    },
  },

  computed: {
    checkExistence() {
      return (hourHandle) => {
		if (this.disablePrevious && Date.parse(this.datetime.date) == Date.parse(this.event.date) && hourHandle < this.event.time) {
			return true
		} else {
			return !this.$storage.isAvailableHour(this.datetime.date, hourHandle);
		}
        
      };
    },
  },
  methods: {
	  
    checkSelectedFit() {
    	var clippedHour = this.$storage.clipDateTime(this.datetime.date, this.selectedHour)
		this.changeHour(clippedHour);
    },

    changeHour(hour) {
		
      this.selectedHour = hour;
	  if (this.selectedHour != this.lastSubmitted.time || this.datetime.date != this.lastSubmitted.date) {
		  this.$emit("changed", hour);

	  	this.lastSubmitted.time = this.selectedHour
		this.lastSubmitted.date = this.datetime.date 
	  }
      
    },
  },
};
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>