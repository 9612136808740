<template>
  <div>
    <v-expand-transition>
      <v-card
        v-show="expand"
        height="160"
        width="100%"
        class="mx-auto secondary"
      >
        <v-card-text>
          <span class="pb-5">
            Bitte geben Sie die Mail Adresse und Rechte des neuen Benutzers an.
            ok</span
          >
          <slot :model="true"></slot>
          <div style="position: relative" class="mt-3">
            <v-row class="ma-0" align="center">
              <v-text-field
                dense
                hide-details
                v-model="user.mail"
                placeholder="E-mail"
                style="max-width: 100%"
                filled
                rounded
              >
              </v-text-field>

              <v-menu transition="fade-transition" nudge-top="15">
                <template v-slot:activator="{ attrs, on }">
                  <div class="user-select" v-bind="attrs" v-on="on">
                    <div>
                      <span
                        class="black--text"
                        style="
                          position: absolute;
                          top: 50%;
                          transform: translateY(-48%);
                          margin-left: 10px;
                          font-size: 15px;
                        "
                      >
                        <span>{{ user.right }}</span>
                        <v-icon style="position: absolute; left: 75px"
                          >mdi-chevron-down</v-icon
                        >
                      </span>
                    </div>
                  </div>
                </template>

                <v-list>
                  <v-list-item
                    :ripple="false"
                    dense
                    v-for="n in items"
                    :key="n"
                    v-on:click="selectRole(n)"
                  >
                    <v-list-item-title style="font-size: 16px">{{
                      n
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            :ripple="false"
            rounded
            color="primary"
            depressed
            @click="$emit('closeInvite', true)"
            small
            text
          >
            <span class="mr-2 ml-2">Schliessen</span>
          </v-btn>
          <v-btn
		  class="mr-2"
            :disabled="!isMailAddr"
            :loading="loading"
            :ripple="false"
            rounded
            color="primary"
            depressed
            @click="inviteUser"
            small
          >
            <span class="mr-2 ml-2">Einladung senden</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  props: ["projectIdentifier", "expand"],

  data() {
    return {
      loading: false,
      items: ["Inhaber", "Mitarbeiter", "Betrachter"],
      user: {
        mail: "",
        right: "Mitarbeiter",
      },
    };
  },

  computed: {
	  isMailAddr() {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (this.user.mail.length > 0 &&
        re.test(this.user.mail)) {
			return true
		} else {
			return false
		}
	  }
  },

  methods: {
    async inviteUser() {
      console.log("INVITE USER");
      this.loading = true;
      this.$emit("loading", true);

      setTimeout(() => {
        this.$emit("invite", this.user);
        this.loading = false;
        this.$emit("closeInvite", true);
		this.user = {
			mail: "",
			right: "Mitarbeiter",
		}
      }, 2000);
      //   var rightHandle = "r";
      //   if (this.user.right == "Inhaber" || this.user.right == "Mitarbeiter") {
      //     rightHandle = "w";
      //   }
      //   var result = await this.$api.authRequest().inviteUser({
      //     mail: this.user.mail,
      //     rights: rightHandle,
      //     project: this.project.url,
      //   });
      //   console.log(result);
    },

    selectRole(role) {
      this.user.right = role;
    },
  },
};
</script>

<style scoped>
.v-btn.active .v-icon {
  transform: rotate(-45deg);
}
.user-select {
  background-color: #f0f0f0;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border-left: 1px solid rgb(219, 219, 219);
  margin-left: 10px;
  width: 120px;
  position: absolute;
  height: 40px;
  top: 0px;
  right: 0px;
  transition-duration: 250ms;
}
.user-select:hover {
  background-color: #e2e2e2;
  transition-duration: 250ms;
}

.user-select-existing {
  background-color: #f0f0f0;
  border-radius: 20px;
  margin-left: 10px;
  width: 130px;
  position: absolute;
  height: 40px;
  top: 0px;
  right: 0px;
  margin-top: 10px;
  transition-duration: 250ms;
}

.user-select-existing:hover {
  background-color: #e2e2e2;
  transition-duration: 250ms;
}

.user-select-existing-readonly {
  background-color: #f0f0f0;
  border-radius: 20px;
  margin-left: 10px;
  width: 130px;
  position: absolute;
  height: 40px;
  top: 0px;
  right: 0px;
  cursor: default;
  margin-top: 10px;
}
.role-name {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  left: 20px;
}
.role-name-readonly {
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  left: 50%;
}
.loading-user {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>