<template>
  <div class="ma-4">
    <InformationToast
      :show="informationMessage.show"
      :message="informationMessage.content"
      :fullscreen="false"
      :error="informationMessage.error"
      :reload="informationMessage.reload"
      :critical="informationMessage.critical"
    />

    <PageHeading
      title="Absicherung"
      subtitle="Übersicht über den Alarmzeiten. Damit diese Zeiten aktiv werden, müssen Sie diese aktiv speichern."
    >
      <template v-slot:action>
        <!-- <CreateTimelapse v-on:created="createdNewTimelapse" v-on:errorloading="creationCriticalError"
	  	v-on:errorEvent="creationEventError"
	  /> -->
        <!-- v-show="isAdmin && hasCameras && armingTimes != undefined" :loading="saving"-->
        <v-btn
		v-show="hasCameras"
          :disabled="!changedArmingTimes"
		  :loading="savingScheduleLoading || loadingData"
          v-on:click="saveScheduleTrigger()"
          rounded
          color="primary"
          depressed
          :ripple="false"
          ><v-icon class="mr-1">mdi-content-save</v-icon>Speichern</v-btn >
      </template>
    </PageHeading>

	 <CameraNotSetup v-if="!hasCameras" />
    <AlarmSchedule
      v-else-if="hasCameras && !loadingData"
      :readonly="false"
      :save="saveSchedule"
      :loading="loadingData"
      :schedule="armingTimes"
      :isAdmin="isAdmin"
      v-on:error="errorEvent"
      v-on:change="changeEvent"
	  v-on:saved="saveScheduleEvent"
    />
    <div v-else 
	style="
          position: absolute;
		  width: 400px; 
		  height: 300px;
          top: 50%;
          left: calc(50% - 200px);
          translate: transform(-50%, -50%);
        "
	>
 
        <v-col align="center" justify="center">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
			 class="mb-3"
          ></v-progress-circular>
		  
		  <v-fade-transition>
          <div class="font-weight-light" v-show="camerasSync" >
            <span >Kameras werden gerade synchronisiert...</span>
          </div>
		  </v-fade-transition>
        </v-col>
    
    </div>
  </div>
</template>
<script>
import InformationToast from "../../../../packages/frontend/components/InformationToast";
import PageHeading from "../../../../packages/frontend/components/TextStyles/PageHeading.vue";
import AlarmSchedule from "@/components/Cameras/AlarmSchedule.vue";
import CameraNotSetup from "../../../../packages/frontend/components/helper/CameraNotSetup";

export default {
  components: {
    InformationToast,
    PageHeading,
    AlarmSchedule,
    CameraNotSetup,
  },

  data() {
    return {
		savingScheduleLoading: false,
      changedArmingTimes: false,
      saveSchedule: false,
      camerasSync: false,
      hasCriticalError: false,
      loadingData: true,
      hasCameras: true,
      isAdmin: false,
      informationMessage: {
        content: "",
        show: false,
        error: false,
        reload: false,
        critical: false,
      },
      armingTimes: undefined,
    };
  },

  async created() {
    var user = await this.$firebase.user();

    if (!user || !user.claims) return this.$router.push("/login");
    this.isAdmin = user.claims.admin;

    if (this.$api.projectCameras().length > 0) {
      this.hasCameras = true;
      setTimeout(async () => {
        var success = await this.getArmingSchedule();

        if (success) {
          setTimeout(() => {
            this.hasCameras = true;
            this.loadingData = false;
          }, 500);
        }
      }, 500);
    } else {
      this.hasCameras = false;
    }
  },

  methods: {
	  saveScheduleTrigger() {
		  this.saveSchedule = !this.saveSchedule
		  this.savingScheduleLoading = true
	  },

	  async saveScheduleEvent(saveSuccess) {
		  this.savingScheduleLoading = false
		  if (saveSuccess) {
			  this.camerasSync = true
			  this.loadingData = true
			  var success = await this.getArmingSchedule();
			  if (success) {
				  this.loadingData = false;
			  }

		  }
	  },
    changeEvent() {
      this.changedArmingTimes = true;
    },
    errorEvent(eventData) {
      this.showToast(
        eventData.content,
        eventData.error,
        eventData.reload,
        eventData.critical
      );
    },

    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async getArmingSchedule() {
      try {
        var isSync = false;
        var retries = 0;

        while (!isSync && isSync != undefined) {
          retries += 1;
          var schedule = await this.$api.request().getProjectSchedule();
		  
          if (schedule.synchronized || (retries >= 0 && new Date() - new Date(schedule.lastSync) > 1 * 60 * 1000)) {
            isSync = schedule.synchronized;
            break;
          } else {
            this.camerasSync = true;
          }

          await this.timeout(5000);
        }
      } catch (error) {}
      this.armingTimes = schedule;
      return true;
    },

    /**
     * With this function an information or error toast can be displayed
     * @param message Toast content message
     * @param error (optional, default: false) Boolean whether it is an error toast
     * @param reloadSuggestion (optional, default: false) Boolean whether a reload suggestion shall be made
     * @param critical (optional, default: false) Boolean whether error is critical (disable menu)
     */
    showToast(
      message,
      error = false,
      reloadSuggestion = false,
      critical = false
    ) {
      this.informationMessage.show = !this.informationMessage.show;
      this.informationMessage.content = message;
      this.informationMessage.error = error;
      this.informationMessage.reload = reloadSuggestion;
      this.informationMessage.critical = critical;
    },
  },
};
</script>