<template>
  <div style="height: 250px">
    <CardTitle
      title="Passwort zurücksetzen"
      subtitle="Bitte geben Sie Ihre Mail-Adresse & Passwort an."
    />

    <div>
      <!-- <div class="ml-4 mr-4 pb-2">Bitte melden Sie sich an.</div> -->
      <v-text-field
        v-model="userCredentials.mail"
        placeholder="Mail-Adresse"
        filled
        rounded
        dense
        hide-details
      ></v-text-field>
	  
    </div>
  </div>
</template>

<script>
import CardTitle from "./CardTitle";
export default {
  props: ["forgot"],
  components: {
    CardTitle,
  },
  data() {
    return {
      userCredentials: {
        mail: "",
        password: "",
      },
    };
  },

  watch: {
    forgot() {
      this.showForgetInput = this.forgot;
    },

    showForgetInput() {
      this.$emit("forgot", this.showForgetInput);
    },
    userCredentials: {
      handler() {
        this.$emit("input", this.userCredentials);
      },
      deep: true,
    },
  },
};
</script>