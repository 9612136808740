import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import {baseRoutes} from '../../../packages/frontend/router'

Vue.use(VueRouter)

// General Pages
import ProjectPage    from '../../../packages/frontend/views/project.vue' //'@/views/Project.vue'

import SettingsPage   from '../../../packages/frontend/views/settings/settings.vue'
import ProjectSettingsPage   from '../../../packages/frontend/views/settings/projectSettings.vue'
import UserSettingsPage   from '../../../packages/frontend/views/settings/userSettings.vue'
import FeedbackPage from '../../../packages/frontend/views/feedback.vue'

import ProjectOverviewPage from '../../../packages/frontend/views/projectOverview.vue'
import adminPage from '../../../packages/frontend/views/admin.vue'

import WeatherChild from '@/views/project/Weather.vue'

import ImageCompareHandle from '@/views/project/ImageCompare.vue';
import ImageInImagePage      from '@/views/project/ImageInImage.vue'
import ImageSliderComparePage   from '@/views/project/ImageSliderCompare.vue'
import FilesPage from '@/views/project/Files.vue'
import CameraSettingsPage from '@/views/project/CameraSettings.vue'
import AlarmSettingsPage from '@/views/project/AlarmSettings.vue'

var projectRoutes = [
  
  {
    path: '/admin', 
    name: 'admin',
    component: adminPage, 
    meta: {
      requiresAuth: true, 
      requiresAdmin: true
    },
    // children: [
    //   {
    //     path: 'resetPassword',
    //     name: 'reset',
    //     component: ResetPage
    //   },
    // ]
  },

  {
    path: '/',
    redirect: {
		name: 'login'
	  },
  },
  
  {
    path: '/projects',
    name: 'projects',
    component: ProjectOverviewPage,
    meta: {
      requiresAuth: true, 
      requiresAdmin: true
    }
  },

  {
    path: '/project/:id',
    name: 'project',
    component: ProjectPage,
    redirect: {
      name: 'alarm'
    },
    meta: {
      requiresAuth: true,
      requiresClaim: true
    }, 
    children: [
	//   {
    //     name: 'overview',
    //     component: WeatherChild,
    //     path: 'overview',
    //     meta: {
    //       title: 'Weather',
    //       icon: "mdi-view-dashboard",
    //     },
    //   },
	//   {
    //     name: 'compare',
    //     meta: {
    //       title: 'Bild Vergleich',
    //       icon: "mdi-image-multiple",
    //     },
    //     path: 'compare',
    //     component: ImageCompareHandle, 
    //     redirect: {
    //       name: "image-in-image"
    //     },
    //     children: [
    //       {
    //         name: 'image-in-image',
    //         meta: {
    //           title: 'Bild-in-Bild',
    //         },
    //         path: 'image-in-image',
    //         component: ImageInImagePage
    //       },
    //       {
    //         name: 'images',
    //         meta: {
    //           title: 'Vergleich',
    //         },
    //         path: 'images',
    //         component: ImageSliderComparePage
    //       },
    //     ]
    //   },
	//   {
    //     name: 'files',
    //     component: FilesPage,
    //     path: 'files',
    //     meta: {
    //       title: 'Dateien',
    //       icon: "mdi-folder",
    //     },
    //   },

      {
        name: 'settings',
        meta: {
          title: 'Einstellungen',
          icon: "mdi-cog",
        },
        path: 'settings',
        component: SettingsPage, 
        redirect: {
          name: "alarm"
        },
        children: [
        //   {
        //     name: 'projectSettings',
        //     meta: {
        //       title: 'Projekteinstellungen',
        //     },
        //     path: 'project',
        //     component: ProjectSettingsPage
        //   },
		  {
            name: 'camera',
            meta: {
              title: 'Kameras',
            },
            path: 'camera',
            component: CameraSettingsPage
          },
		  {
            name: 'alarm',
            meta: {
              title: 'Absicherung',
            },
            path: 'alarm',
            component: AlarmSettingsPage
          },
        //   {
        //     name: 'user',
        //     meta: {
        //       title: 'Nutzereinstellungen',
        //     },
        //     path: 'user',
        //     component: UserSettingsPage
        //   },
        ]
      },

    //   {
    //   name: 'feedback',
    //   component: FeedbackPage,
    //   path: 'feedback',
    //   meta: {
    //     title: 'Feedback',
    //     icon: "mdi-heart",
    //   },
    // },

    ]

  },
]

const routes = baseRoutes.concat(projectRoutes)

// console.log(routes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


function getUser() {
  return new Promise((resolve) => {
    let unsub = Vue.firebaseAuth.onAuthStateChanged(function(user) {
      unsub();
      resolve(user);
    });
  });
}

router.beforeEach(async (to, from, next) => {
	console.log("ROUTER MIDDLEWARE")
	let user = await getUser();
	let token = undefined
	try {
		token = await user.getIdTokenResult(true)
	} catch (error) {
		token = undefined
	}
	
	
	if (token == undefined || !token || !user || !token.expirationTime || Date.parse(token.expirationTime) < (+ new Date())) {
		if (to.name == 'login') {
			next()
		} else {
			next('/login')
		}
		
	} else if (to.name == 'login') {
		next('/projects')
	}

	// if (to.matched[0].path == '/login' && user != null) {
	// 	next('/projects')
	// }

//   if (to.matched[0].path == '/oops' && from.name == null) {
//     next('/login')
//   }


//   if (to.path == '/invite' && to.query != undefined) {
    
//     Vue.firebaseAuth.signOut()
    
//     if (to.query.mode == 'invite') {
//       next(`/login?invite=${to.query.project}&id=${to.query.id}`); 
//     }
//   }

// console.log(to)
  if (to.matched.some(record => record.meta.requiresAuth) ) {
	  
    // Überprüfung ob der Nutzer angemeldet ist; falls angemeldet, die Nutzerdaten werden zurückgegeben
    
	// console.log("HERE 1")
    // Case: Nutzer angemeldet
    if (user != null) {
		
      let claims = (await user.getIdTokenResult()).claims
		next()
    //   if (to.matched.some(record => record.meta.requiresAdmin)) {
    //     // console.log("ADMIN?")
    //     if (claims.admin) {
    //       next()
    //     } else {
    //       next("/project/" + Object.keys(claims.projects)[0])
    //     }
    //   }

      // Überprüfung ob es sich um eine Route handelt, die Claims braucht (projects)
      if (to.matched.some(record => record.meta.requiresClaim)) {
        // console.log("HERE 2")
        if (claims && claims.projects) {
			
          if (Object.keys(claims.projects).includes(to.params.id) || claims.admin) {
            next()
          } 
        } else {
			// console.log("HERE 4")
          next('/oops')
        }
        next();
      } 

      next();
      
    } else {
      next("/login");
    }
  } else {
	  // console.log("HEHE")
    next()
  }
})

export default router


