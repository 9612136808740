<template>
  <div>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Privacy Policy
      </v-card-title>
      <v-btn
        v-show="hasRights"
        :disabled="loadingUser"
        :class="{ active: expandInvite }"
        class="ma-2"
        color="primary"
        rounded
        depressed
        :ripple="false"
        @click="expandInvite = !expandInvite"
        style="position: absolute; top: 10px; right: 10px"
      >
        <v-icon class="mr-1">mdi-plus</v-icon> Nutzer Hinzufügen
      </v-btn>
      <v-card-text>
        <div>
          <v-col class="shrink">
            <v-expand-transition>
              <v-card
                v-show="expandInvite"
                height="160"
                width="100%"
                class="mx-auto secondary"
              >
                <v-card-text>
                  <span class="pb-5">
                    Bitte geben Sie die Mail Adresse und Rechte des neuen
                    Benutzers an.</span
                  >
                  <div style="position: relative" class="mt-3">
                    <v-row class="ma-0" align="center">
                      <v-text-field
                        dense
                        hide-details
                        v-model="user.mail"
                        placeholder="E-mail"
                        style="max-width: 100%"
                        filled
                        rounded
                      >
                      </v-text-field>

                      <v-menu transition="fade-transition" nudge-top="15">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="user-select" v-bind="attrs" v-on="on">
                            <div>
                              <span
                                class="black--text"
                                style="
                                  position: absolute;
                                  top: 50%;
                                  transform: translateY(-48%);
                                  margin-left: 10px;
                                  font-size: 15px;
                                "
                              >
                                <span>{{ user.right }}</span>
                                <v-icon style="position: absolute; left: 75px"
                                  >mdi-chevron-down</v-icon
                                >
                              </span>
                            </div>
                          </div>
                        </template>
                        <v-list>
                          <v-list-item
                            :ripple="false"
                            dense
                            v-for="n in items"
                            :key="n"
                            v-on:click="selectRole(n)"
                          >
                            <v-list-item-title style="font-size: 16px">{{
                              n
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :ripple="false"
                    rounded
                    color="primary"
                    text
                    @click="inviteUser"
                  >
                    Einladung senden
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-col>
        </div>
        <div style="position: relative">
          <div style="position: relative; max-height: 400px; height: 400px">
            <div v-if="loadingUser" class="loading-user">
              <v-progress-circular
                indeterminate
                :size="40"
                color="primary"
              ></v-progress-circular>
            </div>

            <v-virtual-scroll
              :bench="5"
              :items="userList"
              max-height="400"
              item-height="64"
            >
              <template v-slot:default="{ item }">
                <!-- <v-hover v-slot="{ hover }"> -->
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >{{ item.mail }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <!-- <v-fade-transition>
					<div v-show="hover && userMail != item.mail">
						<v-btn style="margin-right: 130px; margin-top: 5px" rounded small depressed :ripple="false">Löschen</v-btn>
					</div>
					</v-fade-transition> -->

                    <v-menu
                      :disabled="!hasRights"
                      v-if="userMail != item.mail"
                      transition="fade-transition"
                      nudge-top="15"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          :class="
                            hasRights
                              ? 'user-select-existing'
                              : 'user-select-existing-readonly'
                          "
                          v-bind="attrs"
                          v-on="on"
                        >
                          <div>
                            <span
                              class="black--text"
                              :class="
                                hasRights ? 'role-name' : 'role-name-readonly'
                              "
                            >
                              <span>{{ getRole(item.role) }}</span>
                            </span>
                            <v-icon
                              v-show="hasRights"
                              style="
                                position: absolute;
                                right: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                              "
                              >mdi-chevron-down</v-icon
                            >
                          </div>
                        </div>
                      </template>
                      <v-list>
                        <v-list-item
                          :ripple="false"
                          dense
                          v-on:click="changeUserRole(item.mail, 'w')"
                        >
                          <v-list-item-title style="font-size: 16px">
                            Mitarbeiter
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :ripple="false"
                          dense
                          v-on:click="changeUserRole(item.mail, 'r')"
                        >
                          <v-list-item-title style="font-size: 16px">
                            Betrachter
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          :ripple="false"
                          dense
                          v-on:click="changeUserRole(item.mail, 'o')"
                        >
                          <v-list-item-title style="font-size: 16px">
                            Inhaber
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
                <!-- </v-hover> -->

                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["project"],

  data() {
    return {
      expandInvite: false,
      loadingUser: false,
      hasRights: true,
      userMail: undefined,
      userList: undefined,
      items: ["Inhaber", "Mitarbeiter", "Betrachter"],
      user: {
        mail: "",
        right: "Mitarbeiter",
      },
    };
  },

  async created() {
    var user = await this.$firebase.user();
    if (!user || !user.claims) return this.$router.push("/login");

    this.userMail = user.email;
    this.getProjectUser();
  },

  methods: {
    getProjectUser() {
      this.loadingUser = true;
      var userList = [
        {
          name: "Steffen Linßen",
          mail: "steffen.linssen@parkort.io",
          role: "w",
        },
        {
          name: "Steffen Linßen",
          mail: "linssenste@gmail.com",
          role: "w",
        },
        {
          name: "Benedict Mähn",
          mail: "benedict.maehn@parkort.io",
          role: "w",
        },
        {
          name: "Benedict Mähn",
          mail: "benedict.maehn@parkort.io",
          role: "w",
        },
        {
          name: "Benedict Mähn",
          mail: "benedict.maehn@parkort.io",
          role: "w",
        },
        {
          name: "Benedict Mähn",
          mail: "benedict.maehn@parkort.io",
          role: "w",
        },
        {
          name: "Benedict Mähn",
          mail: "benedict.maehn@parkort.io",
          role: "w",
        },
      ];

      setTimeout(() => {
        this.userList = userList;
        this.loadingUser = false;
      }, 1000);
    },

    getRole(right) {
      if (right == "w") {
        return "Mitarbeiter";
      } else if (right == "o") {
        return "Inhaber";
      } else {
        return "Betrachter";
      }
    },
    changeUserRole(mail, role) {
      this.userList[this.userList.findIndex((p) => p.mail == mail)].role = role;
      console.log("CHANGe", mail, role);
    },
    async inviteUser() {
      var rightHandle = "r";
      if (this.user.right == "Inhaber" || this.user.right == "Mitarbeiter") {
        rightHandle = "w";
      }
      var result = await this.$api.authRequest().inviteUser({
        mail: this.user.mail,
        rights: rightHandle,
        project: this.project.url,
      });
      console.log(result);
    },
    selectRole(role) {
      this.user.right = role;
    },
  },
};
</script>

<style scoped>
.v-btn.active .v-icon {
  transform: rotate(-45deg);
}
.user-select {
  background-color: #f0f0f0;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border-left: 1px solid rgb(219, 219, 219);
  margin-left: 10px;
  width: 120px;
  position: absolute;
  height: 40px;
  top: 0px;
  right: 0px;
  transition-duration: 250ms;
}
.user-select:hover {
  background-color: #e2e2e2;
  transition-duration: 250ms;
}

.user-select-existing {
  background-color: #f0f0f0;
  border-radius: 20px;
  margin-left: 10px;
  width: 130px;
  position: absolute;
  height: 40px;
  top: 0px;
  right: 0px;
  margin-top: 10px;
  transition-duration: 250ms;
}

.user-select-existing:hover {
  background-color: #e2e2e2;
  transition-duration: 250ms;
}

.user-select-existing-readonly {
  background-color: #f0f0f0;
  border-radius: 20px;
  margin-left: 10px;
  width: 130px;
  position: absolute;
  height: 40px;
  top: 0px;
  right: 0px;
  cursor: default;
  margin-top: 10px;
}
.role-name {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  left: 20px;
}
.role-name-readonly {
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  left: 50%;
}
.loading-user {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>