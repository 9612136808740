import { Api} from '../../api/dist/main'


export default {

    install(Vue, project) {
        let api = null;
		let projects = null;
		let cameras = null;

        function user() {
            return new Promise((resolve, reject) => {
                
              let unsub = Vue.firebaseAuth.onAuthStateChanged(function(user) {
                unsub();
                if (user == null || user == undefined) {
                    resolve(null)
                } else {
                    resolve(user.getIdToken());
                }
              });
            });
          }

        Vue.prototype.$api = {
            
            async create(projectId) {
                api = new Api(Vue.prototype.$project.server, projectId, user())
				projects = await api.getAllProjects()
				cameras = await api.getProjectCameras()
            },

            delete() {
                api = null
            },

            request() {
                return api
            }, 

			projectCameras() {
				
				if (cameras.error && cameras.status == 404) {
					return []
				}
				return cameras
			},

			projects() {
				return projects
			},

            authRequest() {
				return new Api(Vue.prototype.$project.server, undefined, user())
            }


        }
    }
}
