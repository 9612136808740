import PrivacyPage    from './views/general/privacy.vue'
import ImpressumPage  from './views/general/impressum.vue'
import NotFoundPage   from './views/general/oops.vue'

import LoginPage      from './views/auth/login.vue'
import SignUpPage     from './views/auth/signup.vue'
import ResetPage      from './views/auth/reset.vue'
import VerifyPage     from './views/auth/verify.vue'

export var baseRoutes = [
    
    {
      path: '/privacy',
      name: 'Privacy',
      component: PrivacyPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/impressum',
      name: 'Impressum',
      component: ImpressumPage,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/oops',
      name: 'oops',
      component: NotFoundPage,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '*',
      redirect: {
        name: 'oops'
      }
    },
  
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/invite',
      name: 'invite',
      component: SignUpPage,
      meta: {
        requiresAuth: false
      }
    },

    {
        path: '/project',
        redirect: {
            name: 'login'
        } 
    },

    {
      path: '/resetPassword',
      name: 'reset',
      component: ResetPage,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/verify',
      name: 'verify',
      component: VerifyPage,
      meta: {
        requiresAuth: false
      }
    },

  
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: Home
    // }
  ]





//   {
//     path: '/project/:id',
//     name: 'project',
//     component: ProjectPage,
//     redirect: {
//       name: 'overview'
//     },
//     meta: {
//       requiresAuth: true,
//       requiresClaim: true
//     }, 
//     children: [
//       {
//         name: 'test',
//         component: TestChild,
//         path: 'test',
//         meta: {
//           title: 'Dashboard',
//           icon: "mdi-view-dashboard",
//         },
//       },
//       {
//         name: 'overview',
//         component: OverviewPage,
//         path: 'overview',
//         meta: {
//           title: 'Übersicht',
//           icon: "mdi-view-dashboard",
//         },
//       },
//     ]

//   },