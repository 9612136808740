
<template>
  <div>
    <InformationToast
      :show="informationMessage.show"
      :message="informationMessage.content"
      :fullscreen="fullscreenModus"
      :error="informationMessage.error"
      :reload="informationMessage.reload"
      :critical="informationMessage.critical"
    />

    <div
      style="
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: red;
        width: 350px;
		z-index: 500;
      "
    >
      <!-- <v-row class="ma-0">
        <v-btn color="white" tile :ripple="false" depressed
          ><v-icon>mdi-help-circle-outline</v-icon></v-btn
        >
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Dropdown
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>Kamera 1 </v-list-item-title>
            </v-list-item>
			<v-list-item>
              <v-list-item-title>Kamera 2</v-list-item-title>
            </v-list-item>
			<v-list-item>
              <v-list-item-title>Kamera 3</v-list-item-title>
            </v-list-item>
			<v-list-item>
              <v-list-item-title>Kamera 5 Eingang oder so</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row> -->
    </div>
    <v-fab-transition>
      <v-tooltip left open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="z-index: 100"
            :disabled="loading || informationMessage.critical"
            :loading="loading"
            v-show="!sidebarDrawer"
            v-on:click="sidebarDrawer = !sidebarDrawer"
            color="primary"
            fab
            dark
            small
            absolute
            top
            right
            class="mt-10"
            :ripple="false"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <v-row align="center" justify="center" class="ma-0">
          <span>Einstellungen</span>
          <div
            style="
              background-color: white;
              width: 20px;
              border-radius: 5px;
              opacity: 0.5;
            "
            class="ml-3"
          >
            <v-row class="ma-0" justify="center">
              <span
                style="font-family: 'Courier New', monospace"
                class="black--text"
                >o</span
              >
            </v-row>
          </div>
        </v-row>
      </v-tooltip>
    </v-fab-transition>
    <v-navigation-drawer
      style="
        z-index: 1000;
        background-color: transparent;
        overflow-y: hidden !important;
      "
      absolute
      temporary
      right
      v-model="sidebarDrawer"
      hide-overlay
      clipped
      width="320"
      floating
      class="noselect elevation-0"
      v-if="!loading"
    >
      <v-card
        width="305"
        height="calc(100vh - 40px)"
        max-height="calc(100vh - 40px)"
        class="elevation-0 mt-5 mb-5"
        style="border-radius: 10px !important"
        v-show="!loading"
      >
        <v-card-title class="pt-5 pb-5"
          ><v-row align="center">
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2 ml-2"
                  icon
                  small
                  :ripple="false"
                  v-on:click="sidebarDrawer = !sidebarDrawer"
                >
                  <v-icon size="20">mdi-close</v-icon>
                </v-btn>
              </template>
              <v-row align="center" justify="center" class="ma-0">
                <span>Schließen</span>
                <div
                  style="
                    background-color: white;
                    width: 20px;
                    border-radius: 5px;
                    opacity: 0.5;
                  "
                  class="ml-3"
                >
                  <v-row class="ma-0" justify="center">
                    <span
                      style="font-family: 'Courier New', monospace"
                      class="black--text"
                      >o</span
                    >
                  </v-row>
                </div>
              </v-row>
            </v-tooltip>
            {{ title }}

            <v-fade-transition>
              <v-progress-circular
                v-show="loadingImage"
                class="ml-3"
                size="20"
                width="2"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-fade-transition>

            <v-spacer />
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-1"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :ripple="false"
                  v-on:click="showBottomBar = !showBottomBar"
                >
                  <v-icon v-if="!showBottomBar">mdi-card-text-outline</v-icon>
                  <v-icon v-else>mdi-card-text</v-icon>
                </v-btn>
              </template>
              <v-row align="center" justify="center" class="ma-0">
                <span>Hilfsmenü</span>
                <div
                  style="
                    background-color: white;
                    width: 20px;
                    border-radius: 5px;
                    opacity: 0.5;
                  "
                  class="ml-3"
                >
                  <v-row class="ma-0" justify="center">
                    <span
                      style="font-family: 'Courier New', monospace"
                      class="black--text"
                      >h</span
                    >
                  </v-row>
                </div>
              </v-row>
            </v-tooltip>
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  icon
                  @click="toggleFullscreen"
                >
                  <v-icon v-if="!fullscreenModus">mdi-fullscreen</v-icon>
                  <v-icon v-else>mdi-fullscreen-exit</v-icon>
                </v-btn>
              </template>
              <v-row align="center" justify="center" class="ma-0">
                <span>Vollbildmodus</span>
                <div
                  style="
                    background-color: white;
                    width: 20px;
                    border-radius: 5px;
                    opacity: 0.5;
                  "
                  class="ml-3"
                >
                  <v-row class="ma-0" justify="center">
                    <span
                      style="font-family: 'Courier New', monospace"
                      class="black--text"
                      >f</span
                    >
                  </v-row>
                </div>
              </v-row>
            </v-tooltip>
          </v-row></v-card-title
        >

        <v-divider />
        <v-tabs height="40" centered v-model="tab" grow v-if="!simple">
          <v-tab :ripple="false">{{ beforeTitle }}</v-tab>
          <v-tab :ripple="false">{{ afterTitle }}</v-tab>
        </v-tabs>
        <v-card-text style="padding: 0px !important; overflow-y: auto">
          <v-tabs-items v-model="tab">
            <v-tab-item eager style="height: 767px">
              <v-col justify="start">
                <DatePicker
                  class="mt-3"
                  :input="datetimeObj.first.date"
                  :event="!simple ? [this.datetimeObj.second.date] : []"
                  v-on:change="changedFirstDate"
                />
                <v-divider class="pb-3" />
                <TimePicker
                  :datetime="datetimeObj.first"
                  :event="!simple ? this.datetimeObj.second : null"
                  v-on:changed="changedFirstHour"
                />
                <v-btn
                  style="position: absolute; bottom: 15px; right: 15px"
                  text
                  :ripple="false"
                  v-on:click="tab = 1"
                  >Weiter
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-tab-item>
            <v-tab-item eager style="height: 767px">
              <v-col>
                <DatePicker
                  class="mt-3"
                  :input="datetimeObj.second.date"
                  :event="!simple ? [this.datetimeObj.first.date] : []"
                  v-on:change="changedSecondDate"
                />

                <v-divider class="pb-3" />
                <TimePicker
                  :datetime="datetimeObj.second"
                  :event="!simple ? this.datetimeObj.first : null"
                  v-on:changed="changedSecondHour"
                />

                <div v-if="opacity">
                  <v-divider class="pb-3 mt-3" />
                  <h3 class="font-weight-medium black--text ml-4">
                    Transparenz
                  </h3>
                  <v-slider
                    class="mt-3 ml-3 mr-3"
                    min="10"
                    max="100"
                    v-model="opacityHandler"
                  ></v-slider>
                </div>

                <v-btn
                  style="position: absolute; bottom: 15px; left: 15px"
                  text
                  :ripple="false"
                  v-on:click="tab = 0"
                  ><v-icon>mdi-chevron-left</v-icon> zurück
                </v-btn>

                <v-btn
                  depressed
                  color="primary"
                  style="position: absolute; bottom: 15px; right: 15px"
                  :ripple="false"
                  v-on:click="sidebarDrawer = false"
                  >Fertig
                </v-btn>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <v-fade-transition>
      <BottomControl
        v-show="showBottomBar && !fullscreenModus && !loading"
        style="
          position: absolute;
          bottom: 10px;
          z-index: 200;
          left: calc(50% - 260px);
          margin: auto;
        "
        class="noselect mb-2"
        v-on:dateChange="changedDateEvent"
        :first="datetimeObj.first"
        :second="datetimeObj.second"
        :loading="loadingImage"
        :beforeTitle="beforeTitle"
        :afterTitle="afterTitle"
        v-on:close="showBottomBar = false"
        v-on:center="centerElement"
      />
    </v-fade-transition>
  </div>
</template>

<script>
import DatePicker from "@/components/SetupSidebar/Datepicker.vue";
import TimePicker from "@/components/SetupSidebar/Timepicker.vue";
import BottomControl from "@/components/SetupSidebar/BottomControl.vue";
import InformationToast from "@/components/SetupSidebar/InformationToast.vue";

export default {
  components: {
    DatePicker,
    TimePicker,
    BottomControl,
    InformationToast,
  },
  props: [
    "title",
    "beforeTitle",
    "afterTitle",
    "fullscreen",
    "simple",
    "opacity",
    "smallForward",
    "mediumForward",
    "bigForward",
  ],
  data() {
    return {
      imageData: undefined,
      loading: true,
      informationMessage: {
        content: "",
        show: false,
        error: false,
        reload: false,
        critical: false,
      },
      showBottomBar: true,
      opacityHandler: 100,
      loadingImage: false,
      datetimeObj: {
        first: {
          date: new Date().toISOString().substr(0, 10),
          time: 15,
        },
        second: {
          date: new Date(new Date().setDate(new Date().getDate() - 1))
            .toISOString()
            .substr(0, 10),
          time: 15,
        },
      },
      prevDatetime: {
        first: {
          date: new Date().toISOString().substr(0, 10),
          time: 15,
        },
        second: {
          date: new Date(new Date().setDate(new Date().getDate() - 2))
            .toISOString()
            .substr(0, 10),
          time: 15,
        },
      },
      tab: 0,
      sidebarDrawer: false,
      fullscreenModus: false,
      latestDate: false,
    };
  },
  async created() {

    // In the first step, the image data is loaded from Google Storage; only the meta data
    // for the data is fetched; the URLs of the images are loaded on selection.
    this.loading = true;
    var storageImages = await this.$firebase.getStorage(
      "gs://at-dev-test",
      "DS-2DE4425IW-DE20201127AAWRF16199234/test3"
    );

	console.log(storageImages)
    this.$storage.create(storageImages);

    if (storageImages != undefined) {
      this.loading = false;
      this.initialDateSetter();
    } else {
      this.showToast(
        "Beim Laden der Projektdaten (Bilder) ist ein unerwarteter Fehler aufgetreten.",
        true,
        true,
        true
      );
	  
	  this.$emit('serverError', true)
    }

    window.addEventListener("keyup", this.keyboardInput);
  },

  destroyed() {
    window.removeEventListener("keyup", this.keyboardInput);
    this.$storage.delete();
  },

  watch: {
    loading: function () {
      this.$emit("loading", this.loading);
    },

    showBottomBar() {
      if (this.showBottomBar == true) {
        this.sidebarDrawer = false;
      }
    },
    opacityHandler: function () {
      this.$emit("opacityChanged", this.opacityHandler);
    },

    fullscreen: function () {
      if (this.fullscreenModus != this.fullscreen) {
        this.fullscreenModus = this.fullscreen;
      }
    },
  },

  methods: {
    initialDateSetter() {
      this.datetimeObj.first.date = this.$storage.maxDate;
      this.datetimeObj.first.time = this.$storage.clipDateTime(
        this.datetimeObj.first.date,
        this.datetimeObj.first.time
      );
      this.updateFirstImage();

      this.datetimeObj.second.date = this.$storage.minDate;
      this.datetimeObj.second.time = this.$storage.clipDateTime(
        this.datetimeObj.second.date,
        this.datetimeObj.second.time
      );
      this.updateSecondImage();
    },

    /**
     * With this function an information or error toast can be displayed
     * @param message Toast content message
     * @param error (optional, default: false) Boolean whether it is an error toast
     * @param reloadSuggestion (optional, default: false) Boolean whether a reload suggestion shall be made
     * @param critical (optional, default: false) Boolean whether error is critical (disable menu)
     */
    showToast(
      message,
      error = false,
      reloadSuggestion = false,
      critical = false
    ) {
      this.informationMessage.show = !this.informationMessage.show;
      this.informationMessage.content = message;
      this.informationMessage.error = error;
      this.informationMessage.reload = reloadSuggestion;
      this.informationMessage.critical = critical;

      // If the error is critical, the sidebar drawer is closed and the button disabled
      if (this.informationMessage.critical == true) {
        this.sidebarDrawer = false;
      }
    },

    changedDateEvent(newDateObj) {
      this.datetimeObj[newDateObj.type] = newDateObj.date;

      //   if (newDateObj.type == "first") {
      //     this.updateFirstImage();
      //   } else {
      //     this.updateSecondImage();
      //   }
    },

    // -------------------------------------------------------------------------------
    // ----------------------------- KEYBOARD FUNCTIONS ------------------------------
    // -------------------------------------------------------------------------------

    /**
     * Emitting Event to toggle Fullscreen
     */
    toggleFullscreen() {
      this.fullscreenModus = !this.fullscreenModus;
      this.$emit("fullscreen", this.fullscreenModus);
    },

    /**
     * Emitting Event to center image compare (X-Ray or Slider)
     */
    centerElement() {
      this.$emit("center", true);
      this.showToast("Das Element wurde erfolgreich zentriert. ");
    },

    /**
     * Listener Event for Keyboard input
     * @param event Keyboard event information
     */
    keyboardInput(event) {
      if (!this.loading && !this.informationMessage.critical) {
        switch (event.keyCode) {
          case 72: // Key: 'h' for bottom hel
            this.showBottomBar = !this.showBottomBar;
            break;
          case 79: // Key: 'o' for options
            this.sidebarDrawer = !this.sidebarDrawer;
            break;
          case 70: //Key: 'f' for fullscreen
            this.toggleFullscreen();
            break;
          case 67: //Key: 'c' for center element
            this.centerElement();
            break;
          case 49: //Key: '1' for left tab
            if (this.sidebarDrawer) {
              this.tab = 0;
            }

            break;
          case 50: //Key: '2' for right tab
            if (this.sidebarDrawer) {
              this.tab = 1;
            }

            break;
          default:
            break;
        }
      }
    },

    // -------------------------------------------------------------------------------
    // --------------------------- GOOGLE STORAGE FUNCTIONS --------------------------
    // -------------------------------------------------------------------------------

    /**
     * Query for the URL of the image from Firestore
     *
     * @param date selected Date in format YYYY-MM-DD (string)
     * @param hour selected hour as integer
     *
     * @returns Firestore Storage Bucket URL
     */
    async retrieveImageData(date, time) {
      var imageBucket;

      try {
        // For each hour there is an array with all captured images; this bucket is extracted.
        // If an error happens, an error is thrown, displayed to the user and the date is reset
        imageBucket = this.$storage.images[date];
        imageBucket = imageBucket[`0${time}`.slice(-2)];
        if (imageBucket == undefined) {
          throw Error("Image could not be retrieved.");
        }

        var hourStart = Date.parse(imageBucket[0].name);
        var imageHandle = imageBucket[0];

        // Extraction of the most recent image (closest to the selected full hour)
        imageBucket.forEach((imageObj) => {
          if (Date.parse(imageObj.name) < hourStart) {
            imageHandle = imageObj;
          }
        });

        // Querying the Firestore for the URL of the image
        return await this.$firebase.getStorageImage(
          "gs://at-dev-test",
          "DS-2DE4425IW-DE20201127AAWRF16199234/test3",
          imageHandle.name
        );
      } catch (error) {
        // Error Message for the user that an error occurred while loading the image;
        // the image and date (and hour) are set back to the previous (successfully
        // queried) value accordingly.
        this.showToast(
          "Ein interner Fehler ist beim Laden des Bildes aufgetreten.",
          (error = true)
        );
      }
    },

    /**
     * Checks if the date and time data are ok; also checks if the old date is the same as the new one,
     * so that not too much is updated; then the function queries the URL of the new image and returns it;
     * if an error occurs, this is indicated to the user with a toast and the old date and time value is set.
     *
     * @param type object name ('first' | 'second')
     * @returns Google Storage image URL
     */
    async updateImage(type) {
      if (
        this.prevDatetime[type].date != this.datetimeObj[type].date ||
        this.prevDatetime[type].time != this.datetimeObj[type].time
      ) {
        this.loadingImage = true;

        try {
          // Querying Image URL (Google Storage)
          var imageLink = await this.retrieveImageData(
            this.datetimeObj[type].date,
            this.datetimeObj[type].time
          );

          // Firing the event with image URL, and image information (date and time)
          this.$emit(`changed${type}`, {
            url: imageLink,
            date: this.datetimeObj[type].date,
            time: this.datetimeObj[type].time,
          });

          // Image (URL) was successfully loaded and event fired, so the new old datetime is the current one
          this.prevDatetime[type].date = this.datetimeObj[type].date;
          this.prevDatetime[type].time = this.datetimeObj[type].time;
        } catch (error) {
          // Handling of the error; the date and time are set to the previous value
          this.datetimeObj[type].date = this.prevDatetime[type].date;
          this.datetimeObj[type].time = this.prevDatetime[type].time;

          // Information toast that the user knows that an error occurred while loading the new image
          this.showToast(
            "Beim Laden des Bildes ist ein Fehler aufgetreten.",
            true
          );
        }

        this.loadingImage = false;
      }
    },

    /**
     * Triggers an update of the first image's image
     */
    updateFirstImage() {
      this.updateImage("first");
    },

    /**
     * Triggers an update of the second image's image
     */
    updateSecondImage() {
      this.updateImage("second");
    },

    // -------------------------------------------------------------------------------
    // ------------------------------- EVENT HANDELING -------------------------------
    // -------------------------------------------------------------------------------

    /**
     * Handeling first date change in calendar component; Since the Time Component is also
     * updated by a change of the date and fires an event, no update of the images is called
     * for this event, since the images are otherwise updated twice and possibly without a
     * collapsed time (i.e. the time does not exist).
     *
     * @param dateEvent new date formatted as YYYY-MM-DD (string)
     */
    changedFirstDate(dateEvent) {
      this.datetimeObj.first.date = dateEvent;
    },

    /**
     * Handling of the change event of the (first) time component and automatic updating of the displayed images
     *
     * @param hourEvent new time as full-hour integer
     */
    changedFirstHour(hourEvent) {
      this.datetimeObj.first.time = hourEvent;
      this.updateFirstImage();
    },

    /**
     * Handeling second date change in calendar component; Since the Time Component is also
     * updated by a change of the date and fires an event, no update of the images is called
     * for this event, since the images are otherwise updated twice and possibly without a
     * collapsed time (i.e. the time does not exist).
     *
     * @param dateEvent new date formatted as YYYY-MM-DD (string)
     */
    changedSecondDate(dateEvent) {
      this.datetimeObj.second.date = dateEvent;
    },

    /**
     * Handling of the change event of the (second) time component and automatic updating of the displayed images
     *
     * @param hourEvent new time as full-hour integer
     */
    changedSecondHour(hourEvent) {
      this.datetimeObj.second.time = hourEvent;
      this.updateSecondImage();
    },
  },
};
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>