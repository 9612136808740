<template>
    <div v-show="valid">
        TODO: Password zurücksetzen
        <v-btn v-on:click="resetPassword('test912293923')">jdjd</v-btn>
    </div>
</template>

<script>
export default {
    
    data() {
        return {
            valid: false,
            content: null,
            link: ''
        }
    },

    methods: {
        
        async resetPassword(newPassword) {
            try {
                let result = await this.$firebase.resetPassword(this.content.searchParams.get('oobCode'), newPassword)

                if (result = true) {
                    console.log("SUCESSFULLY RESETTED!")
                    this.$router.push('/login')
                }

            } catch (error) {
                return false
            }
        }
    },

    async created() {
        
        // When the client clicks on the reset link, it must be verified that the link is (1) valid at all (form) 
        // and (2) that it has not already been used up; the user is immediately redirected to the home page if 
        // the link is incorrect
        this.content = this.$route.query

        if (this.content && this.content.link) {
            this.content = new URL(this.content.link)
            
            // With the $oobCode$, which is given as a query parameter, Google Auth checks whether the user is authorized 
            // to do so and whether the link is correct. 
            if (this.content == undefined && this.content.searchParams.get('oobCode') != undefined) {
                this.$router.push('/')
            }

            // through Firebase it is now checked if the link is ok; otherwise redirect
            try {
                this.valid = (await this.$firebase.validResetLink(this.content.searchParams.get('oobCode'))).success

                if (!this.valid) {
                    this.$router.push('/login')
                }

            } catch (error) {

                // @TODO: log error or log error on plugin level (?)
                this.$router.push('/login')

            }
        } else {
            this.$router.push('/login')
        }
    }
}
</script>