<template>
  <div>
    <!-- Project view; only shown if data is loaded -->
    <div v-if="!loadingProject">
      <!-- <Toolbar/> -->
      <Navigation :admin="admin" :project="project.name" :projects="$api.projects()" />
      <v-main class="pageStyle">
        <router-view />
      </v-main>
    </div>
    <LoadingPage v-else />
  </div>
</template>


<script>
import Toolbar from "../components/navigation/Toolbar.vue";
import Navigation from "../components/navigation/Navigation.vue";
import LoadingPage from "../components/navigation/LoadingProject.vue";

export default {
  components: {
    LoadingPage,
    Navigation,
    Toolbar,
  },

  data() {
    return {
		loadingProject: true,
      	project: undefined,
      	admin: true,
    };	
  },

  async created() {
	  console.log("CREATED")
	// When a project is called up, the project data is loaded first, the projects to which the user 
	// is released and the cameras in the project; this is intended to reduce loading times on the 
	// project pages
    try {
      await this.$api.create(this.$route.params.id); // initialize API with project ID
      var proj = await this.$api.request().getProject();
	  console.log("PROJE", proj)
	
      if (!proj || proj.error) return this.$router.push("/oops");
	document.title = proj.name
      this.project = proj;
      // When a project is loaded, an initial check is made to see if the mounting was successful and
      // if there are any routes in the project handler
      var projectRouter = this.$router.options.routes.find(
        (routeObj) => routeObj.name === "project"
      );

      if (!projectRouter || !projectRouter.children || projectRouter.children.length == 0) {
        return this.$router.push("/oops");
      } else {
        this.admin = await this.isAdmin();

		setTimeout(() => {
			this.loadingProject = false;
		}, 1000);
		
      }

    } catch (error) {
		console.log(error)
      return this.$router.push("/oops"); // TODO: SERVER FEHLER!
    }
  },

  unmounted() {
    this.$api.delete();
  },

  methods: {
    async isAdmin() {
      let userObject = await this.$firebase.user();
      return userObject.claims.admin;
    },
  },
};
</script>

<style scoped>
.pageStyle {
  overflow: scroll;
  overflow-x: hidden;
  background-color: #f6f6f6;
  height: 100vh;
  box-sizing: border-box;
}
</style>

