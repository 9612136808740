
export default {

	install(Vue) {
		// initialize Firebase with required Credentials
		var images; 
		var minDate; 
		var maxDate; 

		Vue.prototype.$storage = {
			images, minDate, maxDate,

			create(imageData) {
				if (imageData == undefined) return; 
				this.images = imageData

				this.minDate = this._getMinimumDate()
				this.maxDate = this._getMaximumDate()
			},

			delete() {
				this.images, this.minDate, this.maxDate = undefined;
			},


			getNumbImages(start, end) {
				var coveredDates = []
				Object.keys(this.images).forEach(dateObj => {
					if (Date.parse(dateObj) >= Date.parse(start.date) && Date.parse(dateObj) <= Date.parse(end.date)) {
						coveredDates.push(dateObj)
					}
				});

				var framesCounter = 0
				coveredDates.forEach(dateStr => {
					
					Object.keys(this.images[dateStr]).forEach(dateTimeObj => {
						if (!(Date.parse(dateStr) == Date.parse(start.date) && dateTimeObj < start.time) && !(Date.parse(dateStr) == Date.parse(end.date) && dateTimeObj >= end.time)) {
							framesCounter += this.images[dateStr][dateTimeObj].length
						}
					});
				});
				
				return framesCounter

			},


			clipDateTime(date, hour) {
				if (this.images == undefined || this.images[date] == undefined) return; 
				if (this.images[date][hour] != undefined) return hour; 
				try {
					var timeObj = Object.keys(this.images[date]).map(Number)
					return timeObj.sort( (timeA, timeB) => Math.abs(hour - timeA) - Math.abs(hour - timeB))[0];
				} catch (error) {
					console.log(error)
					return;
				}
				
			},

			isAvailableHour(date, hour) {
				if (this.images == undefined || this.images[date] == undefined) return false; 
				return this._doesExist(hour, Object.keys(this.images[date]))
			},


			allowedDates() {
				if (this.images == undefined) return []; 

				return Object.keys(this.images)
			},

			_getMaximumDate() {

				var dateObj = Object.keys(this.images);
				var latestDate = dateObj.reduce(function (dateA, dateB) {
					return Date.parse(dateA) > Date.parse(dateB) ? dateA : dateB;
				});

				return latestDate
			},


			_getMinimumDate() {

				var dateObj = Object.keys(this.images);
				var minDate = dateObj.reduce(function (dateA, dateB) {
					return Date.parse(dateA) < Date.parse(dateB) ? dateA : dateB;
				});

				return minDate
			},

			retrieveMinimumDate(allowedDates) {

				var minDateValue = Date.parse(allowedDates[0]);
				var minDateHandle = allowedDates[0];

				allowedDates.forEach((dateHandle) => {
					if (Date.parse(dateHandle) < minDateValue)
						minDateHandle = dateHandle
				});
				return minDateHandle
			},

			retrieveLatestDate(allowedDates) {
				var maxDateValue = Date.parse(allowedDates[0]);
				var maxDateHandle = allowedDates[0];

				allowedDates.forEach((dateHandle) => {
					if (Date.parse(dateHandle) > maxDateValue)
						maxDateHandle = dateHandle
				});

				return maxDateHandle
			},


			_doesExist(hourHandle, allowedHours) {
				try {
					return allowedHours.includes(`0${hourHandle}`.slice(-2));
				} catch {
					return false
				}
			},

			getNextDate(selectedDate, allowedDates) {

				var currDate = Date.parse(selectedDate)
				var nextElement = (allowedDates[(allowedDates.length - 1)])
				var nextDateElement = Date.parse(nextElement)

				allowedDates.forEach(dateHandle => {

					var dateHandleObj = Date.parse(dateHandle)
					if (dateHandleObj > currDate && dateHandleObj < nextDateElement) {
						nextDateElement = dateHandleObj
						nextElement = dateHandle
					}

				});

				return nextElement
			},

			getMaximumTime(date, imageData) {
				if (date == undefined || imageData == undefined) return; 
				if (imageData[date] == undefined) return;

				var timeObj = Object.keys(imageData[date])

				var latestTime = timeObj.reduce(function (timeA, timeB) {
					return timeA > timeB ? timeA : timeB;
				});

				return latestTime
			},


			getMinimumTime(date, imageData) {
				if (date == undefined || imageData == undefined) return; 
				if (imageData[date] == undefined) return;
				
				var timeObj = Object.keys(imageData[date])

				var earliestTime = timeObj.reduce(function (timeA, timeB) {
					return timeA < timeB ? timeA : timeB;
				});

				return earliestTime
			},


			getMaximumDate(imageData) {
				if (imageData == undefined) return;

				var dateObj = Object.keys(imageData);
				var latestDate = dateObj.reduce(function (dateA, dateB) {
					return Date.parse(dateA) > Date.parse(dateB) ? dateA : dateB;
				});
				return latestDate
			},

			minDateTime() {
				return Math.min(...Object.keys(this.images[this.minDate]))
			},

			maxDateTime() {
				return Math.max(...Object.keys(this.images[this.maxDate]))
			},
		
			getMinimumDate(imageData) {
				if (imageData == undefined) return;

				var dateObj = Object.keys(imageData);
				var minDate = dateObj.reduce(function (dateA, dateB) {
					return Date.parse(dateA) < Date.parse(dateB) ? dateA : dateB;
				});
				return minDate
			},

			getPrevDayImage(selectedHour, selectedDate) {

				if (this.images == undefined) return; 

				try {
					const imageDatesObj = Object.keys(this.images)
					const selDateHandle = Date.parse(selectedDate)

					const prev_date_index = imageDatesObj
						.sort((a, b) => (Date.parse(b) - Date.parse(a)))
						.findIndex(date => Date.parse(date) - selDateHandle <= 0);
						
						const prevDate = imageDatesObj[prev_date_index + 1];

					return {time: selectedHour, date: prevDate}
				} catch {
					return {time: selectedHour, date: selectedDate}
				}
			},


			getNextDayImage(selectedHour, selectedDate) {
				if (this.images == undefined) return; 

				try {
					const imageDatesObj = Object.keys(this.images)
					const selDateHandle = Date.parse(selectedDate)

					const nextDate = imageDatesObj.reduce((dateA, dateB) => {
						const _diff = Date.parse(dateA) - selDateHandle;
						return _diff > 0 && _diff < Date.parse(dateB) - selDateHandle ? dateA : dateB;
					});

					return {time: selectedHour, date: nextDate}
				} catch {
					return {time: selectedHour, date: selectedDate}
				}
			},

			getNextHourImage(selectedHour, selectedDate) {
				const imageDateObj = Object.keys(this.images[selectedDate])

				if (selectedHour != Math.max(...imageDateObj)) {
					var nextHour = Math.max(...imageDateObj)
					imageDateObj.forEach(hourHandle => {
						if (hourHandle < nextHour && hourHandle > selectedHour) {
							nextHour = hourHandle
						}
					});
					if (nextHour != undefined) {
						return { time: parseInt(nextHour), date: selectedDate}
					}
				} else if (Date.parse(selectedDate) < Date.parse(this.maxDate)) {
					return this.getNextDayImage(0, selectedDate)
				}

				return { time: selectedHour, date: selectedDate}
			},


			getPrevHourImage(selectedHour, selectedDate) {
				const imageDateObj = Object.keys(this.images[selectedDate])

				if (selectedHour != Math.min(...imageDateObj)) {
					var nextHour = 0
					imageDateObj.forEach(hourHandle => {
						if (hourHandle > nextHour && hourHandle < selectedHour) {
							nextHour = hourHandle
						}
					});
					if (nextHour != undefined) {
						return { time: parseInt(nextHour), date: selectedDate}
					}
				} else if (Date.parse(selectedDate) > Date.parse(this.minDate)) {
					return this.getPrevDayImage(23, selectedDate)
				}

				return { time: selectedHour, date: selectedDate}
			},


		}
	}
}
