<template>
  <div>
    <fullscreen ref="fullscreen" @change="fullscreenChange">
      <v-container
        fill-height
        fluid
        style="
          background-color: black;
          padding: 0px !important;
          height: 100vh;
        "
      >
        <SettingsSidebar
          :simple="false"
          title="Einstellungen"
          beforeTitle="Vorher"
          afterTitle="Nacher"
          :loading="loadingMeta"
          :imageData="imageData"
          :fullscreen="fullscreen"
          v-on:changedfirst="changedFirstImage"
          v-on:changedsecond="changedSecondImage"
          v-on:fullscreen="toggleFullscreen"
        />

        <!-- <div id="right_panel">
          <div id="resize" :style="dragStyling"></div>
        </div>
		 <v-img class="nodrag noselect imagePosition" style="z-index: 200" id="rightImage" :src="images.right"/>
		 <v-img class="nodrag noselect imagePosition" style="z-index: 100" id="leftImage" :src="images.left"/>
	 -->

		<!--<img :src="images.right"/> -->

        <VueCompareImage
			:sliderLineWidth="4"
          :leftImage="images.left"
          :rightImage="images.right"
        />
      </v-container>
    </fullscreen>
  </div>
</template>

<script>
import VueCompareImage from "vue-compare-image";
import SettingsSidebar from "@/components/SettingsSidebar.vue";

export default {
  components: {
    VueCompareImage,
    SettingsSidebar,
  },
  data() {
    return {
      fullscreen: false,

      loadingMeta: true,
      images: {
        left:
          "https://firebasestorage.googleapis.com/v0/b/at-dev-test/o/DS-2DE4425IW-DE20201127AAWRF16199234%2Ftest3%2F2021-04-05T12%3A04%3A01.624Z?alt=media&token=1afa0018-2d99-4bff-b2db-18b6011c6058",
        right:
          "https://firebasestorage.googleapis.com/v0/b/at-dev-test/o/DS-2DE4425IW-DE20201127AAWRF16199234%2Ftest3%2F2021-04-05T12%3A04%3A01.624Z?alt=media&token=1afa0018-2d99-4bff-b2db-18b6011c6058",
      },
      imageData: null,
      sliderPos: 0,
      clickedMover: false,
	  dragStyle: ''
    };
  },

//   mounted() {
//     document.addEventListener("mousemove", this.resize);

//     //   var resize_el = document.getElementById("resize");
//     document
//       .getElementById("resize")
//       .addEventListener("mousedown", this.startDrag);
	  
//     document.addEventListener("mouseup", this.stopDrag);
//   },

  async created() {
    this.loadingMeta = true;
	try {
		this.imageData = await this.$firebase.getStorage(
			"gs://at-dev-test",
			"DS-2DE4425IW-DE20201127AAWRF16199234/test3"
		);
	} catch (error) {
		this.imageData = undefined
	}
    
    this.loadingMeta = false;
	this.createDragStyle()
	
  },

	computed: {
		dragStyling() {
			return this.dragStyle
		}
	},


  methods: {
	  createDragStyle() {
		  if (document.getElementById('rightImage') != null ) {
			  var boundsImage = document.getElementById('rightImage').getBoundingClientRect();
			  this.dragStyle =  `top: ${boundsImage.top}px; max-height: ${boundsImage.height}px`
		  }
	  },
    startDrag() {
      this.clickedMover = true;
    },
    stopDrag() {
      this.clickedMover = false;
    },
	cropImage(x) {
		var rightImage = document.getElementById('rightImage');
		var bounds = rightImage.getBoundingClientRect()
		rightImage.style.clip = `rect(0px, ${bounds.width}px, ${bounds.height}px, ${x - 256}px)`;
		

	}, 

    resize(e) {
      if (this.clickedMover) {

        var resize_el = document.getElementById("resize");
        var parent = resize_el.parentNode;
		var boundingSize = resize_el.getBoundingClientRect()
		
		console.log(e.x)
		if (boundingSize.x > 270 || e.x > 270) {
			var dx = this.sliderPos - e.x;
        	this.sliderPos = e.x;
        

		// console.log(boundingSize)
		this.cropImage(boundingSize.x)
		
			parent.style.width =
          parseInt(getComputedStyle(parent, "").width) + dx + "px";
		}
			
		
        
      }
    },
    toggleFullscreen() {
      this.$refs["fullscreen"].toggle(); // recommended
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
	  this.createDragStyle()
	  this.cropImage()
    },

    changedFirstImage(imageObj) {
      var imageDataHandle = imageObj;
      this.images.left = imageDataHandle.url;
    },

    changedSecondImage(imageObj) {
      var imageDataHandle = imageObj;
      this.images.right = imageDataHandle.url;
    },

    async setFirstImage(name) {
      try {
        this.images.left = await this.$firebase.getStorageImage(
          "gs://at-dev-test",
          "DS-2DE4425IW-DE20201127AAWRF16199234/test3",
          name
        );
      } catch (error) {
        console.log("ERROR OCCURED SETTING FIRST IMAGE");
      }
    },

    async setSecondImage(name) {
      try {
        this.images.right = await this.$firebase.getStorageImage(
          "gs://at-dev-test",
          "DS-2DE4425IW-DE20201127AAWRF16199234/test3",
          name
        );
      } catch (error) {
        console.log("ERROR OCCURED SETTING SECOND IMAGE");
      }
    },
  },
};
</script>


<style scoped>
#right_panel {
  position: absolute;
  width: 96px;
  padding-left: 4px;
  height: 100%;
  right: 0;
  background-color: transparent;
}

#resize {
  background-color: white;;
  position: absolute;
  left: 0;
  width: 7px;
  height: 100%;
  cursor: col-resize;
  z-index: 500;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.nodrag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.imagePosition {
	position: absolute; 
	top: 0px; 
	left: 0px;
	  top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%;  /* position the left edge of the element at the middle of the parent */
     transform: translate(-50%, -50%);
}
</style>