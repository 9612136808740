<template>
  <div class="calendar-column" :style="edgeStyle">
    <div style="height: 40px">
      <v-row class="ma-0 pt-2" justify="center">{{ weekday }}</v-row>
    </div>
    <v-hover v-slot="{ hover }" :disabled="!admin">
      <div class="calendar-area-time">
        <v-overlay absolute color="white" :opacity="0.4" :value="hover">
          <v-btn
            v-on:click="openEditDialog"
            :ripple="false"
            small
            rounded
            depressed
            class="primary"
            >Bearbeiten</v-btn
          >
        </v-overlay>
        <div
          v-for="(slot, i) in time"
          :key="'slot' + i"
          :style="timeSlotStyle(slot)"
        ></div>
        <div v-for="i in 24" :key="i" class="calendar-hour-row" />
      </div>
    </v-hover>
    <v-dialog v-model="changeTime" width="450" persistent>
	  <CalendarChangeDialog 
	  	:isWeekday="isWeekday"
	  	:weekday="weekday" 
		  :timeblocks="time" 
		  v-on:save="saveAlarmTimesEvent"
		  v-on:close="changeTime = false"/>
    </v-dialog>
  </div>
</template>

<script>
import CalendarChangeDialog from '@/components/Calendar/CalendarChangeDialog.vue'
export default {
  props: [
    "weekday",
    "first",
    "last",
    "isWeekday",
    "timeblocks",
    "dayIdentifier",
    "admin",
  ],
  components: {
	  CalendarChangeDialog
  },

  data() {
    return {

      time: [],
	  
      changeTime: false,

      defaultStyle: `
			background-color: var(--v-primary-base);
			position: absolute; 
			width: 100%; 
			opacity: .9; 
			border-radius: 10px;
			`,
    };
  },

  watch: {
    timeblocks() {
      this.time = this.timeblocks;
	  
    },
  },
  created() {
    this.time = this.timeblocks;
  },

  methods: {
	  saveAlarmTimesEvent(timeData) {
		  
		  this.$emit("change", {
        day: this.dayIdentifier,
        data: timeData.time,
        allWeekday:
          (this.isWeekday && timeData.checkbox.week) || timeData.checkbox.wholeWeek
            ? true
            : false,
        allWeekend:
          (!this.isWeekday && timeData.checkbox.week) || timeData.checkbox.wholeWeek
            ? true
            : false,
      });
	  },
	  
    createBorderRadius(timeObj) {
      return `	border-top-right-radius: ${
        timeObj.start == 0 && timeObj.end != 24 ? "0" : "10"
      }px!important;
					border-top-left-radius: ${timeObj.start == 0 && timeObj.end != 24 ? "0" : "10"}px!important;
					border-bottom-right-radius: ${
            (timeObj.end >= 24 || timeObj.end < timeObj.start) && timeObj.start != 0 ? "0" : "10"
          }px!important;
					border-bottom-left-radius:  ${
            (timeObj.end >= 24 || timeObj.end < timeObj.start ) && timeObj.start != 0 ?  "0" : "10"
          }px!important;
				`;
    },
    timeSlotStyle(timeObj) {
      var end = timeObj.end;
      var start = timeObj.start;

      if (end < start || end > 24) {
        end = 24 - start;
      } else {
        end = end - start;
      }

      var style = `
			top: ${parseInt(start * 25)}px; 
			height: ${parseInt(end * 25)}px;
			`;
      return style + this.defaultStyle + this.createBorderRadius(timeObj);
    },

	
    openEditDialog() {
      this.changeTime = true;
    },
  },
  computed: {
	  
    edgeStyle() {
      if (this.first) {
        return "border-top-left-radius: 10px;";
      } else if (this.last) {
        return "border-top-right-radius: 10px; border-left: 1px solid rgb(221, 221, 221); ";
      } else {
        return "border-left: 1px solid rgb(221, 221, 221);";
      }
    },
  },
};
</script>
<style scoped>
.overlay-info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  background-color: red;
}
.calendar-column {
  position: relative;
  height: 643px;
  width: calc(calc(100% - 100px) / 7);
  background-color: white;
}
.calendar-area-time {
  position: relative;
  border-top: 2px solid black;
  border-bottom: 1px solid black;
}
.calendar-set-time {
  position: absolute;
  top: 0px;
  background-color: var(--v-primary-base);
  height: 150px;
  width: 100%;
  opacity: 0.8;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.calendar-set-time-bottom {
  position: absolute;
  bottom: 0px;
  background-color: var(--v-primary-base);
  height: 100px;
  width: 100%;
  opacity: 0.8;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.calendar-hour-row {
  width: 100%;
  height: 25px;
  background-color: transparent;
  border-bottom: 1px solid rgba(199, 199, 199, 0.4);
}

</style>