<template>
  <div>
    <AddScrollerTemplate title="Kameras hinzufügen" :items="projectCameras">
      <template v-slot:add-action>
        <v-fade-transition>
            <v-btn
              v-show="projectCameras.length > 0"
              v-on:click="addCameraField = !addCameraField"
              :class="{ active: addCameraField }"
              small
              depressed
              :disabled="addCameraLoading"
              rounded
              color="primary"
              :ripple="false"
              ><v-icon class="mr-2">mdi-plus</v-icon>Kamera hinzufügen</v-btn
            >
          </v-fade-transition>
      </template>

      <template v-slot:expand-area>
         <v-expand-transition>
          <v-card
            width="100%"
            v-show="addCameraField"
          >
            <v-card-text>
              <span class="pl-3"
                >Geben Sie bitte alle notwendigen Daten für die Kamera
                ein.</span
              >

              <v-row class="ma-0 mt-3">
                <v-text-field
                  v-model="camera.ip"
                  filled
                  hide-details
                  rounded
                  dense
                  placeholder="IP-Adresse*"
                ></v-text-field>
                <v-text-field
                  v-model="camera.port"
                  class="ml-2"
                  filled
                  hide-details
                  rounded
                  dense
                  placeholder="Port*"
                  style="max-width: 100px"
                ></v-text-field>
              </v-row>
              <v-row class="ma-0 mt-3">
                <v-text-field
                  v-model="camera.username"
                  filled
                  hide-details
                  rounded
                  dense
                  placeholder="Username*"
                ></v-text-field>
                <v-text-field
                  v-model="camera.password"
                  class="ml-2"
                  filled
                  hide-details
                  rounded
                  dense
                  placeholder="Passwort*"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  name="input-10-1"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
			  <div class="mr-2">
			  <v-btn
                :disabled="addCameraLoading"
                v-on:click="addCameraField = false"
                rounded
				text
				small
                depressed
                :ripple="false"
                color="primary"
                ><span class="ml-2 mr-2">Schließen</span></v-btn
              >
              <v-btn
                :loading="addCameraLoading"
                v-on:click="addCamera"
                :disabled="!hasRequiredInformation"
                rounded
				small
                depressed
                :ripple="false"
                color="primary"
                ><span class="ml-2 mr-2">Hinzufügen</span></v-btn
              >
			  </div>
            </v-card-actions>
          </v-card>
        </v-expand-transition>
      </template>

      <template v-slot:alternative>
        <v-btn
              v-on:click="addCameraField = !addCameraField"
              :class="{ active: addCameraField }"
              small
              depressed
              :disabled="addCameraLoading"
              rounded
              outlined
              color="primary"
              :ripple="false"
              >Kamera hinzufügen
            </v-btn>
			
      </template>

      <template v-slot="{ item }">
         <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.document.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.id }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
      </template>
      <template v-slot:actions>
        <v-spacer />
        <v-btn
          v-on:click="$emit('close', true)"
          rounded
          depressed
          :ripple="false"
          text
        >
          <span class="ml-2 mr-2"> Abbrechen</span>
        </v-btn>
        <v-btn
          v-on:click="$emit('create', true)"
          depressed
          rounded
          :ripple="false"
          :disabled="addCameraField"
          color="primary"
        >
          <span class="ml-2 mr-2"> Weiter</span>
        </v-btn>
      </template>
    </AddScrollerTemplate>
  </div>
</template>

<script>

import AddScrollerTemplate from "./AddScrollerTemplate";
export default {
	props: ['projectIdentifier'],
	components: {
		AddScrollerTemplate
	},
  data() {
    return {
      projectCameras: [],
      addCameraField: false,
      addCameraLoading: false,
      showPassword: false,
      camera: {
        ip: "",
        port: undefined,
        username: "",
        password: "",
      },
    };
  },

  computed: {
    hasRequiredInformation() {
      if (
        this.camera.ip.length == 0 ||
        !this.camera.port ||
        this.camera.port.length == 0 ||
        this.camera.username.length == 0 ||
        this.camera.password.length == 0
      ) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    async addCamera() {
      if (!this.projectIdentifier) {
        this.$emit(
          "error",
          "Oh, Ein unerwarteter Fehler ist beim Hinzufügen der Kamera aufgetreten"
        );
        return;
      }

      this.addCameraLoading = true;
      await this.$api.create(this.projectIdentifier);

      try {
        var cameraObjectResp = await this.$api.request().addCamera({
          ip: this.camera.ip,
          port: parseInt(this.camera.port),
          username: this.camera.username,
          password: this.camera.password,
        });

		console.log(cameraObjectResp)
        if (!cameraObjectResp || cameraObjectResp.error) {
          this.$emit(
            "error",
            "Ein unerwarteter Fehler ist beim Hinzufügen der Kamera aufgetreten"
          );
          this.addCameraLoading = false;
          return;
        }
        console.log(cameraObjectResp);
      } catch (error) {
        this.$emit(
          "error",
          "Ein unerwarteter Fehler ist beim Hinzufügen der Kamera aufgetreten"
        );
        this.addCameraLoading = false;
        return;
      }

        this.addCameraLoading = false;
        this.addCameraField = false;
        this.projectCameras.push(cameraObjectResp);

        this.camera.ip = "";
        this.camera.port = undefined;
        this.camera.username = "";
        this.camera.password = "";
    },
  },
};
</script>


<style scoped>
.v-btn.active .v-icon {
  transform: rotate(-45deg);
}
</style>