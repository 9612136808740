import { render, staticRenderFns } from "./project.vue?vue&type=template&id=76994fc8&scoped=true&"
import script from "./project.vue?vue&type=script&lang=js&"
export * from "./project.vue?vue&type=script&lang=js&"
import style0 from "./project.vue?vue&type=style&index=0&id=76994fc8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../baucam/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76994fc8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../baucam/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})
