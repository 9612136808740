<template>
  <div class="ma-4">
    <InformationToast
      :show="informationMessage.show"
      :message="informationMessage.content"
      :fullscreen="false"
      :error="informationMessage.error"
      :reload="informationMessage.reload"
      :critical="informationMessage.critical"
    />
    <PageHeading
      title="Sicherung"
      subtitle="Übersicht über den Alarmzeiten. Damit diese Zeiten aktiv werden, müssen Sie diese aktiv speichern."
    >
    </PageHeading>
    <div v-if="hasCameras" style="position: relative; height: calc(100vh - 150px)">
      <v-row class="ma-0">
        <div
          style="
            position: absolute;
            width: 250px;
            height: 100%;
            background-color: white;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          "
        >
          <CameraSidebar
            v-on:loading="loadingCameraEvent"
            v-on:selected="cameraSelectedEvent"
            v-on:noCameras="missingCamerasEvent"
			v-on:critical="criticalErrorEvent"
          />
        </div>

        <div
          style="
            position: absolute;
            left: 250px;
            background-color: white;
            width: calc(100% - 250px);
            height: 100%;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          "
          :style="
            !loadingCameraData
              ? 'border-left: 1px solid rgba(200, 200, 200, .5);'
              : ''
          "
        >
          <div
            v-if="
              (!selectedCamera || selectedCamera.serialNumber == undefined) &&
              !loadingCameraData
            "
            style="height: 100%"
          >
            <v-col align="center" class="center-element">
              <span class="font-weight-light">
                Wählen Sie eine Kamera aus.</span
              >
            </v-col>
          </div>

          <div v-else-if="loadingCameraData">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              style="
                position: absolute;
                top: calc(50% - 25px);
                left: calc(50% - 150px);
                translate: transform(-50%, -50%);
              "
            ></v-progress-circular>
          </div>
         
          <div v-else>
            <ListFiles
              :loading="loadingCameraData"
              :camera="selectedCamera"
              v-on:error="errorEvent"
            />
          </div>
        </div>
      </v-row>
    </div>
	 <CameraNotSetup v-else/>
  </div>
</template>

<script>
import InformationToast from "../../../../packages/frontend/components/InformationToast";
import CameraNotSetup from "../../../../packages/frontend/components/helper/CameraNotSetup";
import PageHeading from "../../../../packages/frontend/components/TextStyles/PageHeading.vue";
import CameraSidebar from "@/components/Files/CameraSidebar.vue";
import ListFiles from "@/components/Files/ListFiles.vue";

export default {
  components: {
    PageHeading,
    ListFiles,
    CameraSidebar,
    InformationToast,
    CameraNotSetup,
  },

  data() {
    return {
      hasCameras: true,
      informationMessage: {
        content: "",
        show: false,
        error: false,
        reload: false,
        critical: false,
      },
      loadingCameraData: false,
      selectedCamera: {
        serialNumber: undefined,
      },
    };
  },

  async created() {
    // await this.retrieveCameraInformation();
  },

  methods: {
	  criticalErrorEvent(errorContent) {
		  this.showToast(errorContent, true, true, true)
	  },
    missingCamerasEvent() {
      this.loadingCameraData = false;
      this.hasCameras = false;
    },
	
    loadingCameraEvent(loadingState) {
      this.loadingCameraData = loadingState;
      this.hasCameras = true;
    },

    cameraSelectedEvent(cameraData) {
      this.selectedCamera = { ...cameraData };
    },

    errorEvent(errorMessage) {
      this.showToast(errorMessage, true);
    },

    /**
     * With this function an information or error toast can be displayed
     * @param message Toast content message
     * @param error (optional, default: false) Boolean whether it is an error toast
     * @param reloadSuggestion (optional, default: false) Boolean whether a reload suggestion shall be made
     * @param critical (optional, default: false) Boolean whether error is critical (disable menu)
     */
    showToast(
      message,
      error = false,
      reloadSuggestion = false,
      critical = false
    ) {
      this.informationMessage.show = !this.informationMessage.show;
      this.informationMessage.content = message;
      this.informationMessage.error = error;
      this.informationMessage.reload = reloadSuggestion;
      this.informationMessage.critical = critical;
    },
  },
};
</script>

<style scoped>
.list-item-style {
  border-bottom: 1px solid rgba(216, 216, 216, 0.555);
}
.center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>