<template>
    <div>
        Nutzer einstellungen
        <br>
        <v-text-field v-model="mail" label="Mail-Adresse"/>
        <v-btn v-on:click="register()" block depressed :ripple="false">Nutzer einladen</v-btn>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mail: ''
        }
    }, 
    methods: {
        
        async register() {
            // console.log(this.$route.params.id)
            let project = {}
            project[this.$route.params.id] = 'r'
         
            let url = await this.$api.request().inviteUser({mail: this.mail, right: 'r'})
            console.log(url)
        },
    }
}
</script>
