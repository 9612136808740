import { render, staticRenderFns } from "./AddScrollerTemplate.vue?vue&type=template&id=2b177210&"
import script from "./AddScrollerTemplate.vue?vue&type=script&lang=js&"
export * from "./AddScrollerTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../baucam/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../baucam/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VCard,VCardActions,VCardText,VCol,VDivider,VRow,VSpacer,VVirtualScroll})
