import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: {
		  customProperties: true,
		},
		themes: {
			light: {
			primary: '#cc2029',
			secondary: '#FFFFFF',
			accent: '#ABABAB', 
			error: '#ABABAB',
		  },
		}
		
		// ...
	  },
});
