<template>
    <div>
        VERIFY EMAIL!
    </div>
</template>

<script>
export default {
    
    created() {
        console.log(this.$route.query)
    }
}
</script>