<template>
  <div
  v-if="first != undefined && second != undefined"
    style="
      width: 500px;
      background-color: white;
      opacity: 0.8;
      border-radius: 10px;
    "
    class="ml-5"
  >
  <v-btn icon small :ripple="false" style="position: absolute; top: 5px; right: 5px" v-on:click="$emit('close', true)"><v-icon small>mdi-close</v-icon></v-btn>
    <v-btn icon small :ripple="false" style="position: absolute; top: 5px; right: 30px" v-on:click="$emit('center', true)"><v-icon small>mdi-image-filter-center-focus</v-icon></v-btn>

    <v-col
      justify="center"
      align="center"
      class="mt-2"
      style="padding: 2px !important"
    >
      <v-row class="ma-0" align="center" justify="center">
        <v-menu v-model="selectionStatus" top :close-on-click="true" offset-y>
          <template v-slot:activator="{ on, attrs }" >
            <v-btn :min-width="110" :class="{active: selectionStatus}" small  depressed :ripple="false" color="white black--text" style="padding-right: 5px!important" dark v-bind="attrs" v-on="on">
            
			  <b>{{selectedImage}}</b>
			  <v-spacer/>
			  <v-icon>mdi-chevron-up</v-icon>

            </v-btn>
          </template>

          <v-list>
            <v-list-item dense :ripple="false" v-on:click="selectedImage = beforeTitle">
              <v-list-item-title>{{beforeTitle}}</v-list-item-title>
            </v-list-item>
            <v-list-item dense :ripple="false" v-on:click="selectedImage = afterTitle">
              <v-list-item-title>{{afterTitle}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
		<v-divider class="mt-1 mb-1 mr-2 ml-2" vertical></v-divider>
        <b class="mr-1">{{ formatDate }}</b> ({{ imageHandleSelection.time }}:00 Uhr)
        <div style="height: 20px; width: 30px">
          <v-row align="center" class="ma-0">
            <v-fade-transition>
              <v-progress-circular
                v-show="loading"
                class="ml-3"
                size="20"
                width="2"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-fade-transition>
          </v-row>
        </div>
      </v-row>
      <v-row class="ma-0" align="center" justify="center">
        <v-btn
          small
          class="black--text ml-2 mr-2 mt-1 mb-1"
          depressed
          :ripple="false"
          :disabled="isOldestDate"
          v-on:click="toggleBackwardsDay"
          ><v-icon small class="mr-1">mdi-minus</v-icon> 1 Tag</v-btn
        >
        <v-btn
          small
          class="black--text mr-4 mt-1 mb-1"
          depressed
          :ripple="false"
          :disabled="isOldestTime"
          v-on:click="toggleBackwardsHour"
          ><v-icon small class="mr-1">mdi-minus</v-icon> 1 Stunde</v-btn
        >
        <v-divider class="mt-2 mb-2" vertical></v-divider>
		
        <v-btn
          small
          class="black--text ml-4 mr-2 mt-1 mb-1"
          depressed
          :ripple="false"
          :disabled="isLatestTime"
          v-on:click="toggleForwardHour"
          ><v-icon small class="mr-1">mdi-plus</v-icon> 1 Stunde</v-btn
        >
        <v-btn
          small
          class="black--text mr-2 mt-2 mb-1"
          depressed
          :ripple="false"
          :disabled="isLatestDate"
          v-on:click="toggleForwardDay"
          ><v-icon small class="mr-1">mdi-plus</v-icon> 1 Tag</v-btn
        >
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {
  props: ["first", "second", "loading", "beforeTitle", "afterTitle"],
	data() {
		return {
			selectedImage: this.beforeTitle,
			selectionStatus: false,
			imageHandleSelection: this.first

		}
	},

	watch: {
		first() {
			if (this.selectedImage == this.beforeTitle) {
				this.imageHandleSelection = this.first
			}
		},
		second() {
			if (this.selectedImage == this.afterTitle) {
				this.imageHandleSelection = this.second
			}
		},
		selectedImage() {

			if (this.selectedImage == this.beforeTitle) {
				this.imageHandleSelection = this.first
			} else {
				this.imageHandleSelection = this.second
			}
		}
	},
  computed: {
    isOldestTime() {
		return this.isOldestDate && this.imageHandleSelection.time == this.$storage.minDateTime();
    },
    isOldestDate() {
      return Date.parse(this.$storage.minDate) == Date.parse(this.imageHandleSelection.date);
    },

    isLatestTime() {
      return this.isLatestDate && this.imageHandleSelection.time == this.$storage.maxDateTime();
    },
    isLatestDate() {
		return Date.parse(this.$storage.maxDate) == Date.parse(this.imageHandleSelection.date);
    },
    formatDate() {
      var imageDate = new Date(this.imageHandleSelection.date);
      const options = {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      return imageDate.toLocaleDateString("de-DE", options);
    },
  },
  methods: {
    toggleForwardDay() {
	if (Date.parse(this.imageHandleSelection.date) < Date.parse(this.$storage.maxDate)) {
		var newDate = this.$storage.getNextDayImage(
			this.imageHandleSelection.time,
			this.imageHandleSelection.date
		);
		this.$emit("dateChange", { date: newDate, type: (this.selectedImage == this.beforeTitle ? 'first' : 'second') });
		}
    },


    toggleBackwardsDay() {
		if (Date.parse(this.imageHandleSelection.date) > Date.parse(this.$storage.minDate)) {
			var newDate = this.$storage.getPrevDayImage(
				this.imageHandleSelection.time,
				this.imageHandleSelection.date
			);

			this.$emit("dateChange", { date: newDate, type: (this.selectedImage == this.beforeTitle ? 'first' : 'second') });
		}
    },

    toggleForwardHour() {
			var newDate = this.$storage.getNextHourImage(
				this.imageHandleSelection.time,
				this.imageHandleSelection.date
			);

			this.$emit("dateChange", { date: newDate, type: (this.selectedImage == this.beforeTitle ? 'first' : 'second') });
		
      
    },

    toggleBackwardsHour() {
		var newDate = this.$storage.getPrevHourImage(
				this.imageHandleSelection.time,
				this.imageHandleSelection.date
			);

      this.$emit("dateChange", { date: newDate, type: (this.selectedImage == this.beforeTitle ? 'first' : 'second') });
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-btn.active .v-icon {
    transform: rotate(-180deg);
  }
</style>